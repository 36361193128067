.shadow-1,
.shadow-default,
.shadow-lt {
  box-shadow: none;
}

.shadow-2,
.shadow-active,
.shadow-md,
.shadow-selected {
    box-shadow: none;
}

.shadow-3,
.shadow-hover,
.shadow-lg {
  box-shadow: 0 6px 6px 0 rgba(42, 41, 62, 0.26),
    0 10px 20px 0 rgba(42, 41, 62, 0.19);
}

.shadow-4,
.shadow-plus {
  box-shadow: 0 10px 10px 0 rgba(42, 41, 62, 0.26),
    0 14px 28px 0 rgba(42, 41, 62, 0.25);
}

.shadow-5,
.shadow-extreme {
  box-shadow: 0 15px 12px 0 rgba(42, 41, 62, 0.22),
    0 19px 38px 0 rgba(42, 41, 62, 0.3);
}

.shadow-6 {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.shadow-7,
.shadow-border {
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
}

.svg-shadow-default {
  filter: drop-shadow(0 0.8px 0.3px rgba(42, 41, 62, 0.2))
    drop-shadow(0 1px 1.4px rgba(42, 41, 62, 0.1));
}

.svg-shadow-hover {
  filter: drop-shadow(0 1.2px 1.3px rgba(42, 41, 62, 0.2))
    drop-shadow(0 2.6px 3.4px rgba(42, 41, 62, 0.16));
}

.svg-shadow-disabled {
  filter: drop-shadow(0 0.4px 0.1px rgba(42, 41, 62, 0.15))
    drop-shadow(0 1px 0.5px rgba(42, 41, 62, 0.07));
}

.svg-shadow-active {
  @extend .svg-shadow-default;
}

.svg-shadow-extreme {
  filter: drop-shadow(0 1.2px 1.3px rgba(42, 41, 62, 0.2))
    drop-shadow(0 2.6px 3.4px rgba(42, 41, 62, 0.16));
}

.svg-wrapper__shadow {
  @extend .shadow-default;
  border-radius: 50%;
}

.svg-wrapper__shadow-extreme {
  @extend .shadow-active;
  border-radius: 50%;
}

.svg-wrapper__shadow-border {
  @extend .shadow-border;
  border-radius: 50%;
}

