.tracker-diet {
  .tracker-body__grid {
    @include bp(small-up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .form-list__checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item,
    .selected-item {
      display: flex;
      width: 48%;
      @include bp(small-up) {
        margin-bottom: 0.125rem;
      }
    }
  }

  .form-checkbox-wrapper {
    .form-input-checkbox:checked + .option-text {
      color: $orange;
      font-weight: $proxima-semibold;
    }
  }

  .selected-item-list {
    // margin-bottom: 1rem;
  }

  .selected-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @include bp(small-down) {
      border: 1px solid $orange;
      border-radius: 2px;
      padding: 0.125rem;
    }
    @include bp(small-up) {
      padding-bottom: 0.75rem;
    }

    .button {
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .select-items__wrapper {
    display: flex;
    justify-content: space-around;

    .stretch {
      flex: 1;
    }

    .form-input-field {
      margin-right: 12px;

      &:focus {
        border-color: $orange;
      }
    }

    .react-autosuggest__container {
      width: 100%;

      .registration-treatment-field {
        width: 100%;
      }
    }

    position: relative;
    .react-autosuggest__suggestions-container {
      background-color: white;
      box-shadow: 0px 2px 12px rgba($color: #000000, $alpha: 0.2);
      border-radius: 4px;
      position: absolute;
      top: 100%;
      width: 100%;
      box-sizing: border-box;
      z-index: 100;
      overflow: hidden;

      .react-autosuggest__suggestion--highlighted {
        div {
          background-color: rgba($color: #c7d0ec, $alpha: 1);
        }
      }

      div {
        padding: 8px;
        cursor: pointer;
        &:hover,
        &:focus {
          background-color: rgba($color: #c7d0ec, $alpha: 1);
        }
      }
    }

    .button {
      margin-left: 16px;
      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  .diet-water-wrapper {
    @include bp(small-up) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include bp(small-down) {
      display: flex;
      flex-direction: column;
      margin: auto;
    }

    &__read {
      border-top: 1px solid $gray3;
      margin: auto;
      padding-top: 1rem;

      @include bp(small-up) {
        display: flex;
        flex-direction: row;

        .diet-water__glasses {
          grid-template-columns: repeat(4, 1fr);
          margin-right: 1rem;
        }
      }

      .diet-water__glasses-empty {
        display: flex;
        align-items: center;

        .water-glass-item__empty {
          @include bp(small-down) {
            margin: 0.25rem auto;
          }
          @include bp(small-up) {
            margin-right: 1rem;
          }

          .svg-water-glass__water {
            fill: lighten($blue-light, 37%);
          }
        }
      }
    }
  }

  .diet-water__glasses {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    padding-bottom: 0.5rem;
  }

  .diet-water__count {
    display: flex;
    flex-direction: column;
    @include bp(small-down) {
      text-align: center;
    }
  }

  .water-glass-svg {
    width: 2rem;
    height: 2rem;

    .svg-water-glass__glass {
      stroke: $gray3;
      fill: lighten($blue-light, 37%);
    }

    .svg-water-glass__water {
      fill: $blue-light;
    }
  }

  /////Readview
  .diet-read-list {
    @include bp(small-up) {
      columns: 2;
    }
  }

  .tracker-body__scroll {
    overflow: auto;

    .tracker-body-form {
      padding-top: 0.5rem;
    }

    .form__border-bottom {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
