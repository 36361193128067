.card-tracker {
    border: 1px solid $gray100;
    background: $whiteNew;
    border-radius: 0px;
    background-color: $whiteNew;
    width: 500px;
    height: 380px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-tracker-header {
    background-color: $secondary100;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
        width: "50%";
        align-items: center;
        padding-left: 10px;
    }

    &__right {
        width: "50%";
        align-items: center;
        padding-right: 10px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.card-title {
    color: #fff;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 10px;
}

.card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__right {
        justify-content: flex-end;
        z-index: 100;
    }
}