.trends-summary {
  header {
    padding: 2px 16px 0;

    .title {
      margin: 0;
    }
  }

  .no-trends-summary-data {
    padding: 17px 18px;

    .no-trends-link {
      color: $cobalt;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .trends-summary-data {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    padding: 14px;
    @include bp('large-down') {
      grid-template-columns: repeat(2, 1fr);
    }
    @include bp('small-down') {
      display: block;
    }

    .icon-heading {
      align-items: center;
      display: flex;

      svg {
        margin-right: 6px;
      }
    }

    h3.subtext {
      margin: 32px 0 12px 0;
    }

    .third-col {
      @include bp('medium-down') {
        grid-column: 3 / 3;
        grid-row: 1;
      }
    }
  }

  .tracker-notes {
    margin: 12px 0 20px 0;
    padding: 12px;

    h3.subtext {
      margin: 0;
    }
  }

  .tracker-infection {
    margin: 12px 0 20px 0;
    padding: 12px;

    h3 {
      font-size: 12px;
      margin: 0;
      font-weight: 400;
      color: $gray15;
      margin-bottom: 6px;
    }

    p {
      padding-left: 8px;
      color: $gray15;
    }
  }

  .headers {
    @include bp('medium-down') {
      margin: 0.25rem 0;
    }
  }

  .trends-summary-item-list,
  .trends-summary-triggers-list {
    list-style: none;
    margin: 12px 0;
    padding: 0;

    li {
      align-items: center;
      display: flex;
    }

    .image {
      border-radius: 50% 50%;
      display: block;
      height: 34px;
      margin: 0 6px 0 0;
      overflow: hidden;
      width: 34px;

      svg {
        height: 1.5rem;
        margin: 0.4rem auto auto;
        width: 1.5rem;
      }
    }
  }

  .trends-summary-diet {
    // border-bottom: 1px solid $gray2;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.125rem;
    padding-bottom: 1.125rem;

    .headers {
      margin: 0;
    }
  }

  .trends-summary-treatment {
    grid-column: 3 / 3;
    @include bp('large-down') {
      grid-column: 2 / 2;
      grid-row: 2;
    }
  }

  .trends-treatment-list {
    list-style: none;
    margin: 0;
    padding: 12px 8px;

    @include bp('small-down') {
      padding: 12px 5px;
    }

    .trends-treatment-list-item {
      .trends-treatment-list-item-title {
        align-items: center;
        color: $gray8;
        display: flex;

        .subtext {
          margin: 0;
        }

        svg {
          margin-right: 4px;

          g {
            stroke: $gray8;
          }
        }
      }

      .trends-treatment-list-item-values {
        color: $blue;
        margin: 8px 0 14px;

        .trends-treatment-list-item-value {
          align-items: center;
          display: flex;
          text-transform: capitalize;
          margin-left: 12px;
        }
      }
    }
  }

  .tracker-skin {
    .skin-areas-wrapper {
      margin-bottom: 3.5rem;
      justify-content: space-around;
    }

    .svg-skin__body-part {
      cursor: default;
    }

    .picture-header {
      color: $gray8;
      font-size: 13px;
    }

    .affected-area-image {
      padding: 6px 0;
    }
    margin-bottom: 2.5rem;
  }
  /**
   * reusable list of progress list items
   * ------------------------------------------------------------------------ */
  .trends-summary-datatable {
    list-style: none;
    margin: 0;
    // padding: 0 8px;
    padding: 0 0 0 0;

    .datatable-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &:first-child {
        padding-top: 0.25rem;
      }

      .label {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        font-size: 12px;

        .icon-tracker {
          height: 34px;
          margin-right: 6px;
          width: 34px;
        }
      }

      .value {
        flex: 0 1 auto;

        ol,
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  // Specific middle column override
  .trends-summary-triggers {
    grid-column: 2 / 3;
    grid-row: 2;

    .headers {
      margin: 0;
    }
    @include bp('large-down') {
      border-bottom: 1px solid $gray2;
      grid-column: 2 / 2;
      grid-row: 1;
      margin-bottom: 18px;
    }
    @include bp('small-down') {
      border-bottom: 1px $gray2 solid;
      margin-bottom: 18px;
    }
  }

  .trends-summary-trackers {
    margin-bottom: 18px;

    .trends-summary-datatable {
      @include bp('small-down') {
        margin-bottom: 18px;
      }
      @include bp('large-down') {
        grid-column: 1 / 2;
        grid-row: 2;
      }
    }

    .headers {
      margin: 0;
    }

    .datatable-item {
      // border-bottom: 1px solid $gray2;
      margin-bottom: 0;
      padding: 10px 0;

      &:last-child {
        @include bp('large-down') {
          border-bottom: none;
        }
      }

      .dragscale-body {
        // width: 90px;
      }
    }
  }

  h3 {
    margin-top: 2rem;
  }

  .trends-summary-text {
    color: $black100 !important;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    line-height: normal;

    &__16p {
      font-size: 16px;
      font-weight: 600;
    }

    &__16p400 {
      font-size: 16px;
      font-weight: 400;
    }

    &__16p600 {
      color: $black100 !important;
      font-size: 16px;
      font-weight: 600;
    }

    &__14p600 {
      color: $black100 !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  hr {
    height: 1px;
    width: 97%;
    border-width: 0;
    color: $black25;
    background: $black25;
  }

  .hr100 {
    width: 100% !important;
  }

  .trends-dots {
    height: 16px;
    width: 16px;
    background-color: $primary10;
    border-radius: 50%;
    display: inline-block;
    color: var(--Neutral-Black-70, #575a5d);
    text-align: center;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
