//while trackers load...
//All trackers will have this gradient background
.tracker__loading {
  background-color: $gray1;
  background: linear-gradient(
    304deg,
    rgba(255, 255, 255, 1) 9%,
    rgba(247, 247, 254, 1) 36%,
    rgba(242, 242, 244, 1) 62%,
    rgba(255, 255, 255, 1) 84%
  );
  transition: background-color 0.2s ease 0.1s;
  opacity: 0.4;
}

//gotta hide this text. We need this text, however, to force height on weather tracker
.loading-paragraph__hidden {
  opacity: 0;
}

//weather tracker needs some custom heights
.tracker.tracker__loading.tracker-WeatherTracker,
.tracker__loading.tracker-weather__loading {
  @include bp(small-down) {
    min-height: 309.52px;
  }
  @include bp(smaller-and-large-medium-only) {
    min-height: 212.2px;
  }
  @include bp(large) {
    height: 135.5px;
  }
}

//Diet, Skin and Trigger need to be double column size
.tracker.tracker__loading.tracker-DietTracker,
.tracker.tracker__loading.tracker-SkinTracker,
.tracker.tracker__loading.tracker-TriggerTracker {
  grid-column: span 2;
}
//Animations - Fade-in for loading trackers
.tracker {
  &.tracker-loaded {
    @keyframes fade-in {
      from {
        opacity: 0.4;
      }

      to {
        opacity: 1;
      }
    }
    opacity: 0.4;
    animation: fade-in ease 0.3s forwards;
    -webkit-animation: fade-in ease 0.3s forwards;
  }
}
