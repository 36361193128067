// Onboarding Card
.onboarding-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white;
    background-image: url("../../assets/images/WavesBackground.png");
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: top 2% right 3%;
    padding: 12px;
    border-radius: 8px;
    width: 580px;
    color: $cobalt10;
    display: block;
    top: 5vh;
    left: 35vw;
    margin-bottom: 100px;

    .onboarding-info-container {
        margin-top: 15px;
        height: 123px;
    }

    // Buttons top container (change between laptop and phone)
    .button-top-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 50px;
        z-index: 5;
    }

    .button-options {
        cursor: pointer;
        width: 63px;
        height: 32px;
        flex-shrink: 0;
        background: var(--gray, #f7f7fe);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;

        &-web {
            border-radius: 4px;
            border: 1px solid var(--background, #d8dadd);
            border-right: 0.1px solid transparent;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &-mobile {
            border-radius: 4px;
            border: 1px solid var(--background, #d8dadd);
            border-left: 0.1px solid transparent;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        &-web:hover {
            background-color: #00a1d91a;
            z-index: 1;
        }
        &-web-selected {
            background-color: #00a1d91a;
        }
        &-mobile:hover {
            background-color: #00a1d91a !important;
            z-index: 1;
        }
        &-mobile-selected {
            background-color: #00a1d91a;
        }
    }

    //Title

    .onboarding-titles {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
    }

    //Info

    .onboarding-info {
        color: var(--Gray02, #5c5b6b);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    // Steppers dots

    .stepper-points {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .container-dots {
        display: flex;
    }

    .circle1,
    .circle2 {
        width: 8px;
        height: 8px;
        margin: 5px;
        border-radius: 50%;
    }

    .circle1 {
        background-color: #092a6d;
    }

    .circle2 {
        background-color: #c0cde5;
    }

    //Button bottom actions

    .onboarding-buttons-actions-carrousel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        font-size: 14px;

        &-margin {
            margin-top: 70px;
        }
    }

    .onboarding-buttons-actions-finish {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 70px;
        font-size: 14px;
    }

    .button-actions {
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.933px;
        text-transform: uppercase;
        cursor: pointer;

        &-nobg {
            color: var(--Bg-sections, #2a292c);
            background-color: transparent;
            border: none;
            margin-right: 50px;
        }

        &-bg {
            border-radius: 23px;
            border: 1px solid #1a397c;
            background: #1a397c;
            color: #fff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            width: 200px;
            height: 45px;
            flex-shrink: 0;
        }
    }

    .mobile-gif {
        height: 65%;
        width: 65%;
        margin-top: 10px;
    }
}
