.header {
  /* Desktop_main-navigation: */
  background: $primary100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3.75rem;
  position: relative;
  z-index: 3;

  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .site-logo-wrapper {
    padding-left: 1rem;
    @include bp(medium-down) {
      .logo-nea__horizontal {
        height: 2.8rem;
      }
    }
  }
  //** New styles
  .full-name-user {
    color: var(--Neutral-White, #fff);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .full-name-user {
    color: var(--Neutral-White, #fff);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nav-titles {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  //** End New styles
  .has-survey {
    content: "";
    position: absolute;
    top: 2px;
    right: 8px;
    font-family: monospace;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    display: block;
    padding: 1px 4px;
    background-color: rgb(209, 75, 75);
    border-bottom: 1px solid darkred;
    border-top: 1px solid rgb(236, 118, 118);
    color: white;
    margin-left: 6px;
    border-radius: 3px;
  }

  @include bp(large) {
    .profile-options {
      .has-survey {
        display: none;
      }
    }
  }

  .active-link,
  .dropdown-link,
  .menu-link {
    margin: 0 4px;
    position: relative;
  }

  #beta-button {
    animation: shine 50s linear infinite;
    background: linear-gradient(
      to right top,
      $green-light 13%,
      $green 35%,
      $green-active 59%,
      $green 74%,
      $green-light 90%
    );
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 800% auto;
    color: $tangerine;
    font-weight: $proxima-semibold;
    text-decoration: underline;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    transition: 0.2s ease;

    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: $orange-dark;
    }
  }

  .desktop-nav-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    @include bp(large) {
      width: 34%;
    }
    @include bp(large-down) {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-around;
      max-width: 45rem;
      @include bp(larger) {
        margin-right: 7em;
        max-width: 50rem;
      }

      li {
        .menu-link {
          border-radius: 4px;
          color: $gray10;
          padding: 0.7rem 1.25rem;

          &:hover {
            color: $cobalt;
          }
        }

        .active-link {
          background: $cobalt;
          color: $white;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-bottom: 3px solid $tertiary100;
        }

      }
    }
  }

  .button-border {
    width: auto;
    min-width: 124px;
  }

  &.header-light {
    background: $white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

    .desktop-nav-wrapper {
      justify-content: flex-end;
    }

    .logo-nea__horizontal {
      height: 2.2rem;

      @include bp(small-down) {
        height: 1.8rem;
      }
    }

    .button-border {
      border-color: $secondary100;
      color: $secondary100;

      &.button-solid {
        background: $secondary100;
        color: $white;
      }
    }
  }
}

.profile-visible {
  .profile-dropdown {
    display: flex;
    flex-direction: column;

    @include bp(small-down) {
      &:after {
        background-color: $white;
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.9;
        position: fixed;
        top: 61px;
        z-index: -2;
      }
    }


    .section-title {
      color: var(--Neutral-Black-100, #0E1217);
              
      /* H4/Bold */
      font-family: Lexend;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;  
      text-transform: none;
      padding: 15px 10px;
    }
  }
}

.profile-options {
  align-items: center;
  color: $gray10;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 1rem;
  @include bp(small-down) {
    width: 4rem;
  }

  &:focus,
  &:hover {
    svg {
      #menu-hrz-1 {
        fill: $cobalt80;
        transition: fill 0.2s ease-out;
      }

      #menu-hrz-2 {
        fill: $cobalt40;
        transition: fill 0.2s ease-in;
      }

      #menu-hrz-3 {
        fill: $cobalt20;
        transition: fill 0.1s ease-in;
      }
    }
  }

  .nav-login__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    //Login button needs a bit of space away from sign up button
    .button-large {
      margin-right: 0.75rem;
      text-transform: capitalize;
      font-weight: 600;
    }
    //the Sign Up button needs adjusting on larger screens
    .button-regular {
      padding: 0.625rem 2rem 0;
      white-space: nowrap;
      font-weight: 700;
      font-size: 1rem;
      text-transform: capitalize;
    }
    @include bp(large) {
      & + .profile-visible {
        display: none;
      }
    }
    @include bp(large-down) {
      display: none;
    }

    & + .profile-visible {
      .button {
        display: block;
        padding: 0.675rem 4.5rem;
      }
    }
  }
}

.profile-visible {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  .profile-desktop {
    padding: 0 0 0 0.675rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-size: 1rem;
      @include bp(small-down) {
        display: none;
      }
    }

    span {
      font-size: 1rem;
      margin-right: 8px;
    }

    .svg__hamburger {
      width: 2rem;
      height: 2rem;
      fill: $cobalt;
      transition: all 0.3s ease;
    }
    
  }

  .profile-dropdown {
    z-index: 2;
    max-height: calc(100vh - 50px);
    overflow: auto;
    background-color: #ffffff;
    position: absolute; 
    right: -15px;
    top: 42px;
    width: 30vw;
    min-height: 100vh;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);

    .button-regular,
    .button-large {
      text-transform: capitalize;
      font-size: 1rem;
      font-weight: 600;
    }
    @include bp(small) {
      .dropdown-org-logo {
        display: none;
      }
    }

    .profile-dropdown-list {
      .mobile-profile {
        text-transform: capitalize;
        border-bottom: 1px solid $gray2;
        padding-bottom: 0.125rem;
        margin-bottom: 0.375rem;
      }
    }

    //on devices larger than phones, we hide the logo in the drop down menu
    @include bp(small) {
      .dropdown-org-logo {
        display: none;
      }
    }

    @include bp(large) {
        .mobile-profile,
        .nav-links {
          display: none;
        }
    }
    @include bp(large-down) {
      .profile-dropdown-list {
        .dropdown-settings-nav {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }

    @extend .shadow-md;
    @include bp(medium-down) {
      width: 50vw;

      .dropdown-org-logo {
        border-top: 1px solid $gray1;
        padding: 1rem 0;
        width: 100%;
      }

      img {
        width: 50%;
        margin: auto;
      }
    }


    @include bp(small-down) {
      width: 100vw;
    }
  }

  .profile-dropdown.scroll-fixed {
    position: fixed; 
    top: 0px; 
    right: 1px;

    @include bp(larger-medium-down) {
      right: 0px;
    }

    @include bp(small-down) {
      position: absolute; 
      right: -15px;
      top: 42px;
    }
  }

  .dropdown-link {
    align-items: center;
    display: flex;
    padding: 0.5rem;
    margin: 0;
    color: $black100;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;

    &:focus,
    &:hover {
      background: var(--Secondary-10, #E9F0FE);
    }

    svg {
      margin-right: 5px;
    }
  }
  .active-link {
    color: var(--Secondary-100, #1D62ED);

    span {
      color: var(--Secondary-100, #1D62ED);
    }
  }

  .profile-dropdown-list {

    .title-section{
      &:hover {
       background: $white;
      }
    }
  }

  .logout {
    font-size: 0.7rem;
    margin: 0.6rem 0 0;
    padding: 1rem 0;
    color: #777;
  }

  ul {
    display: flex;
    flex-direction: column;

    @include bp(small-down) {
      .loginButton {
        padding: 10px 20px;
        .button-border-inverted {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

//This is the logo to the far right of the header. We display: none; the image but need to keep the div "visible" for the menu backdrop on small devices
.org-logo {
  display: flex;
  align-items: center;
  margin: 0 8px;
  @include bp(small-down) {
    display: none;
  }
  width: 7.2rem;
}

.submenu-dropdown {
  .button-regular,
  .button-large {
    text-transform: capitalize;
    font-size: 1rem;
  }
  @include bp(small) {
    .dropdown-org-logo {
      display: none;
    }
  }

  .profile-dropdown-list {
    .mobile-profile {
      text-transform: capitalize;
      border-bottom: 1px solid $gray2;
      padding-bottom: 0.125rem;
      margin-bottom: 0.375rem;
    }
  }

  background-color: $white;
  border-radius: 4px;
  padding: 0.625rem 0 0;
  position: absolute;
  right: 220px;
  text-align: center;
  top: 0;
  width: 14.25rem;
  z-index: 1;
  @extend .shadow-md;

  //on devices larger than phones, we hide the logo in the drop down menu
  @include bp(small) {
    .dropdown-org-logo {
      display: none;
    }
  }
  // }
  @include bp(large) {
    .profile-dropdown {
      .mobile-profile,
      .nav-links {
        display: none;
      }
    }
  }
  @include bp(large-down) {
    .profile-dropdown-list {
      .dropdown-settings-nav {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  @include bp(medium-down) {
    right: 16px;
    top: 50px;

    .dropdown-org-logo {
      border-top: 1px solid $gray1;
      padding: 1rem 0;
      width: 100%;
    }
  }
}
