.adct-text {
    padding: 10px 18px;
    font-size: 16px;
}

.adct-summary-history {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 10px 18px;
  
    .center {
        text-align: center;
    }

    @include bp(medium) {
      flex-wrap: nowrap;
    }
  
    .summary-item {
      margin-bottom: 10px;  
      width: 49.5%;
      padding: .5rem 1rem;
      background: $gray25;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
    
      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: $black100;
        padding: 0;
        margin: 0;
      }
      &-subtitle {
        text-align: center;
        font-size: 14px !important;
        font-weight: 600 !important;
        color: $black100;
        padding: 0;
        margin: 0;
      }
      &-number {
        font-size: 24px;
        font-weight: 700;
        color: $black100;
        padding: 0;
        margin: 0 0 0.4rem;
      }
      &-severity {
        color: $black100;
        font-weight: 600;
        font-size: 18px;
  
        &::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            background: $severityMild;
            margin: 0.5rem 0.5rem 0 0;
            vertical-align: sub;
        }   
        &.Severe::before {
            background: $severitySevere;
        }
        &.Clear::before {
          background: $severityMild;
        }
        &.NA::before {
            background: none;
            width: 0px;
            height: 0px;
        }
      }
  
      &-scale {
        width: 100%;
        border-radius: 0;
      }
    }
  
    .summary-scales {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 0.2rem 0 0;
      padding: 0;
  
      &-item {
        width: 29%;
        height: 12px;
        margin: 1px;
        background: $severityMild;
  
        &.Severe {
          background: $severitySevere;
        }
      }
  
      &-item-lg-Severe {
        width: 71%;
        margin: 1px;
        background: $severitySevere;
      }
    }
  
    .summary-scales-indicator {
      width: 100%;
      position: absolute;
    }
  
    .summary-scales-indicator::before {
      position: absolute;
      bottom: 15px;
    }
  
    .summary-labels {
      display: flex;
      flex-direction: row;
      padding: 0.5rem 0;
  
      span {
        width: 3%;
        font-size: 12px;
        color: $black100;
        font-weight: 600;
        text-align: left;
        line-height: 15px;
  
        &.number-center {
          text-align: center;
          width: 5%;
        }
        &.number-right {
          text-align: right;
          width: 7.5%;
        }
  
        &.severity {
          width: 25%;
          color: $darkGray100;
          text-align: center;
  
          &.Severe-lg {
            width: 65%;
          }
        }
      }
    }
}

.adct-tutorial {
  ul {
    list-style: inside;

    li {
      margin-bottom: 0.5rem;
      font-size: 16px;
    }
  }

  .title {
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    text-align: left;
  }

  hr {
    border: 1px solid #EDF1F6;
  }

  p {
    text-align: center;
  } 

  .adct-summary-history {
    margin: 10px 0;
  }

  .adct-tutorial-img {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

  }
}
  
.card-content {
  .adct-summary-history {
    margin: 0;
  }
}