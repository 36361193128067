/* src/Sidebar.css */
.sidebar {
  z-index: 2;
  background-color: #ffffff;
  position: absolute;
  right: -15px;
  top: 60px;
  height: calc(100vh - 60px);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  overflow: auto;
}

.sidebar-flare {
  z-index: 100;
  background-color: #ffffff;
  position: fixed;
  right: -15px;
  top: 0px;
  width: 500px;
  min-width: 320px;
  height: calc(100vh);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  overflow: auto;
}

.sidebar-flare.open,
.sidebar.open {
  transform: translateX(0%);
}

.sidebar-flare.scroll-fixed,
.sidebar.scroll-fixed {
  position: fixed;
  top: 0px;
  right: 1px;
  height: 100vh;

  @include bp(larger-medium-down) {
    right: 0px;
  }

  @include bp(small-down) {
    position: absolute;
    right: -15px;
    top: 42px;
  }
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: -50px;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.sidebar-header {
  h4 {
    padding: 15px;
    margin: 0;
  }

  .sidebar-close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 15px;
  }
}

.sidebar-nav {
  padding: 0 15px;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-nav ul li {
  margin: 20px 0;
}

.sidebar-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.sidebar-nav ul li a:hover {
  text-decoration: underline;
}
