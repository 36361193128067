.checkbox {
  &-list {
    &__2columns {
      @include bp(small-up) {
        // columns: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }
    }
  }
}
