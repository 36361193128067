@import '../globals/colors';

.trendschart {
  position: relative;
  padding-bottom: 24px;
  min-height: 500px;
}

.pagebutton {
  position: absolute;
  top: 14px;
  z-index: 1;

  .button-action__transparent,
  .button-action__circle,
  .button-action__square {
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
  }

  &-back {
    left: 10px;
  }

  &-forward {
    right: 10px;
  }
}

.linechart {
  position: relative;
}

.linechart__svg .datapoint.selected {
  fill: $white !important;
  stroke-width: 2px;
}

.dot-grid,
.linechart__svg {
  .gridlines {
    stroke: $gray2;

    .major {
      stroke-width: 2px;
    }

    .minor {
      stroke-width: 0.5px;
    }
  }

  .line {
    stroke-width: 2px;
  }

  .numberdot {
    circle {
      fill: $gray;
      stroke: $gray;
    }

    text {
      fill: $gray4;
      font-size: 0.65rem;
      text-anchor: middle;
    }
  }

  .days {
    circle {
      fill: $gray;
    }

    text {
      font-size: 0.65rem;
      text-anchor: middle;
      fill: $black100;
    }

    .numberdot {
      cursor: pointer;

      &:hover {
        circle {
          fill: $secondary100;
        }

        text {
          fill: $whiteNew;
        }
      }

      &.selected {
        circle {
          fill: $secondary100;
        }

        text {
          fill: $whiteNew;
        }
      }
    }

    .day {
      border-radius: 5px;
      fill: $white;

      &:active {
        fill: $gray;
      }

      &.selected {
        fill: $gray;
      }
    }
  }
}
