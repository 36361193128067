.help-main-title {
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10% !important;
  margin-bottom: 5% !important;

  @include bp(small-down) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
    margin-top: 10px;

    span {
      line-height: 1.5;
    }
  }

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }
}

.help-title {
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }
}

.help-paragraph {
  color: $black100;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.help-bg-white {
  background-color: $whiteNew;
}
.help-bg-primary100 {
  background-color: $primary100;
}

.help-bg-secondary10 {
  background-color: $secondary10 !important;
}

.help-list {
  color: $secondary100;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
}

ul.help-list-dot {
  list-style-type: disc;
}

.content-help-wrapper {
  width: 80%;
  z-index: 1;

  &__right-section {
    @include bp(large) {
      width: 50%;
      margin-right: 6rem;
    }
    @include bp(largest) {
      width: 50%;
      margin-right: 6rem;
    }
  }

  &__left-section {
    @include bp(large) {
      width: 50%;
      margin-left: 6rem;
    }
    @include bp(largest) {
      width: 50%;
      margin-left: 6rem;
    }
  }
}

.help-bottom-margin {
  &__1rem {
    margin-bottom: 1rem;
  }

  &__2rem {
    margin-bottom: 2rem;
  }

  &__3rem {
    margin-bottom: 3rem;
  }

  &__5rem {
    margin-bottom: 5rem;
  }

  &__8rem {
    margin-bottom: 8rem;
  }

  &__10rem {
    margin-bottom: 10rem;
  }
}

//Styles for help images

.images-wrapper {
  height: calc(100vw);
  position: relative;
  top: 40px;

  @include bp(small-down) {
    height: auto;
    margin-bottom: 60px;
  }
  @include bp(large) {
    height: unset;
    width: 50%;
    max-width: 550px;
    margin: auto;
  }
}

.image__faqs {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(-50px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(-10px, -20px);
    &.visible {
      transform: scale(1) translate(-10px, -20px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(-85px, -10px);
    &.visible {
      transform: scale(1.2) translate(-85px, -10px);
    }
  }
}

.image__created {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(25px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(-10px, -20px);
    &.visible {
      transform: scale(1) translate(-10px, -20px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(-50px, -25px);
    &.visible {
      transform: scale(1.2) translate(-50px, -25px);
    }
  }
}

.image__caregivers {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(0.9) translate(0px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(0.9) translate(-10px, -30px);
    &.visible {
      transform: scale(0.9) translate(-10px, -30px);
    }
  }
  @include bp(largest) {
    transform: scale(1.1) translate(-10px, -25px);
    &.visible {
      transform: scale(1.1) translate(-10px, -25px);
    }
  }
}

.image__track-symptoms {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(80px, -20px);
    &.visible {
      transform: scale(1.2) translate(80px, -20px);
    }
  }
}

.image__poscorad {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(0.9) translate(0px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(0.9) translate(-10px, -30px);
    &.visible {
      transform: scale(0.9) translate(-10px, -30px);
    }
  }
  @include bp(largest) {
    transform: scale(1.1) translate(-10px, -25px);
    &.visible {
      transform: scale(1.1) translate(-10px, -25px);
    }
  }
}

.image__healthcare {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.1) translate(80px, -20px);
    &.visible {
      transform: scale(1.1) translate(80px, -20px);
    }
  }
}

.image__provider {
  display: inline-block;
  transform: scale(2) translate(90px, 0px);
  z-index: -1;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(2) translate(80px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(2.1) translate(100px, -10px);
    &.visible {
      transform: scale(2.1) translate(100px, -10px);
    }
  }
  @include bp(largest) {
    transform: scale(2) translate(150px, -5px);
    &.visible {
      transform: scale(2) translate(150px, -5px);
    }
  }
}

.image__contributing {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(50px, -30px);
    &.visible {
      transform: scale(1.2) translate(50px, -30px);
    }
  }
}

.image__compatibility {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(0.9) translate(0px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(0.9) translate(-10px, -40px);
    &.visible {
      transform: scale(0.9) translate(-10px, -40px);
    }
  }
  @include bp(largest) {
    transform: scale(1.1) translate(20px, -25px);
    &.visible {
      transform: scale(1.1) translate(20px, -25px);
    }
  }
}

.image__login-info {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(80px, -20px);
    &.visible {
      transform: scale(1.2) translate(80px, -20px);
    }
  }
}

.image__forgot-password {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(0.9) translate(0px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(0.9) translate(-10px, -40px);
    &.visible {
      transform: scale(0.9) translate(-10px, -40px);
    }
  }
  @include bp(largest) {
    transform: scale(1.1) translate(20px, -25px);
    &.visible {
      transform: scale(1.1) translate(20px, -25px);
    }
  }
}

.image__cookies {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(50px, -20px);
    &.visible {
      transform: scale(1.2) translate(50px, -20px);
    }
  }
}

.image__feedback {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(0.9) translate(0px, 0px);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(0.9) translate(-10px, -40px);
    &.visible {
      transform: scale(0.9) translate(-10px, -40px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(50px, -25px);
    &.visible {
      transform: scale(1.2) translate(50px, -25px);
    }
  }
}

#about-skin-tracker-section {
  @include bp(small-down) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

#when-you-tracking {
  @include bp(small-down) {
    padding-top: 0rem;
  }
}

#section-page-about-advisors {
  @include bp(small-down) {
    padding-top: 0;
  }
}

#about-surveys-section {
  @include bp(small-down) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#shared-decicion-making-section {
  @include bp(small-down) {
    padding-top: 2rem;
    padding-bottom: 0;
  }
}
