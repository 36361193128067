.accordion-item {
    @include bp(larger-medium-down) {
        display: none;
    }
    float: left;

    &__margin {
        margin-top: -10px;
    }
}

.accordion-title-p {
    color: $black100;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-title,
.accordion-content {
    padding: 0 1rem 0 0.5rem;
}

.accordion-content {
}

@media screen and (max-width: 700px) {
    body {
        font-size: 18px;
    }

    .accordion {
        width: 90%;
    }
}
