.progress-bar-wrapper {
  display: flex;
  margin: 0 auto;
  padding-bottom: 0.75rem;
}

.progress-bar-item {
  display: flex;
  flex-direction: row;
  margin: 0 0.25rem;

  //hide the green badge
  &__active,
  &__pending {
    .badge__complete {
      display: none;
    }
  }

  &__completed {
    span {
      z-index: 1;
      position: relative;
    }

    .badge__complete {
      height: 0.675rem;
      position: absolute;
      right: 0.875rem;
      top: 0;
      width: 0.675rem;
      z-index: 1;
    }

    .progress-counter {
      z-index: -1;
    }
  }

  .progress-dot {
    &__background,
    &__inner {
      fill: $gray2;
    }
  }

  &__active {
    .progress-dot__inner {
      fill: $cobalt;
    }
  }

  .progress-counter {
    //tracker theme specific styles can be found in _theme.scss
    z-index: 4;
  }

  &__active {
    .progress-counter {
    }
  }

  //Vertical line behind a row of counter icons
  .progress-backline {
  }
}

//The progress dot used in skin tracker UL. These are default styles, but will otherwise use styles in the theme.scss, since it comes later in the stylesheet
.process {
  &__complete,
  &__current,
  &__pending {
    .progress-dot__background {
      fill: $gray2;
    }
  }

  &__pending {
    .progress-dot__inner {
      fill: $gray2;
    }
  }

  &__current {
    .progress-dot__inner {
      fill: $white;
    }
  }

  &__complete {
    .progress-dot__inner {
      fill: $skin;
    }
  }
}

.counter-backline {
  position: relative;
}
//That line in the background that goes between numbered progress steps
.progress-bar-item:not(:first-of-type) {
  .counter-backline::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 9px;
    top: 9px;
    right: 19px;
    background-color: lighten($skin, 30%);
    z-index: 0;
  }
}

.progress-bar-item__completed {
  & + .progress-bar-item {
    .counter-backline::before {
      background-color: lighten($skin, 10%);
    }
  }
}
