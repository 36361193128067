.form {
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__border {
    &-bottom {
      border-bottom: 1px solid $gray2;
      padding-bottom: 1.125rem;
      margin-bottom: 1.125rem;
    }

    &-top {
      border-top: 1px solid $gray2;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-item-right {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;
    justify-content: flex-end;
  }

  &-item-space {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0.75rem;
    justify-content: space-between;
  }

  &-input {
    color: $black;
    caret-color: $gray10;
    font-size: 0.75rem;

    &-label {
      color: $black;
      font-size: 0.82rem;
      padding-bottom: 0.2rem;
      @extend .text__semibold;

      .red-asterisk {
        color: red;
        margin-left: 4px;
      }
    }

    &-label-settings {
      color: $black100;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .red-asterisk {
        color: red;
        margin-left: 4px;
      }
    }

    &-label-login {
      color: $black100;
      font-size: 0.82rem;
      padding-bottom: 0.2rem;
      font-family: Lexend;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;

      .red-asterisk {
        color: red;
        margin-left: 2px;
      }

      &-margin {
        margin-top: 4px;
      }
    }

    &-checkbox {
      margin-right: 0.5rem;
      cursor: pointer;
      accent-color: $tertiary100;
    }

    &-field {
      padding-left: 0.5rem;
      border-radius: 8px;
      border: 1px solid $black25;
      line-height: 1.75rem;
      transition: border 0.2s ease;
      height: 40px;
      flex-shrink: 0;
      font-family: Lexend;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      color: $black100;

      &:disabled {
        border: 1px solid $gray3;
      }

      &:focus {
        border: 1px solid $cobalt;
        outline: none;
      }

      &::placeholder {
        color: $gray8;
        font-size: 0.625rem;
        font-style: italic;
        font-size: 0.625rem;
      }

      &__error {
        border-color: $red;

        &:focus,
        &:hover {
          border-color: $red !important;
        }
      }
    }
  }

  textarea {
    line-height: 1rem;
    resize: vertical;
  }

  &-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    // align-items   : center;
    p {
      margin-left: 0.5rem;
    }
  }

  .form input:checked {
    background-color: #2196f3;
  }

  .password {
    &__visible {
      align-items: center;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      display: flex;
      flex-direction: row;
      font-style: italic;
      justify-content: space-between;

      &:focus,
      &:hover {
        text-decoration: underline;
        text-decoration-color: $cobalt;
        transition: text-decoration 0.2s ease;
      }

      .svg-eye {
        height: 1.25rem;
        width: 1.25rem;
      }

      &__hide {
        &::before {
          content: 'show';
          color: $gray8;
          font-size: 0.625rem;
        }

        .svg-eye {
          &__eye-shape {
            fill: $gray8;
          }

          &__cross-line {
            stroke: $gray8;
          }
        }
      }

      &__show {
        &::before {
          content: 'hide';
          color: $black;
          font-size: 0.625rem;
        }

        .svg-eye {
          &__eye-shape {
            fill: $black;
          }
        }
      }
    }
  }

  &-section {
    &__padding-bottom {
      padding-bottom: 1rem;
    }
  }
}

.validation {
  padding: 0.72rem 0.625rem;

  &-item {
    color: #6b7280;
    display: flex;
    padding: 0 0 0 0;
    text-align: left;
    transition: all 0.2s ease;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-transform: capitalize;

    .icon-validation-circle {
      stroke: $gray7;
    }

    .icon-validation-check {
      stroke: $white;
    }

    .validation-icon-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0.25rem;

      svg {
        width: 0.8rem;
      }
    }

    &__passing {
      color: $feedbackSuccess;

      .icon-validation-check,
      .icon-validation-circle {
        stroke: $feedbackSuccess;
      }

      .icon-validation-check {
        transition: stroke 0.3s ease-in;
      }
    }

    &__uppercase {
      text-transform: uppercase;
    }
  }
}

.label-wrapper {
  &__code,
  &__password {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.form-item-select {
  //No funny "&"s for nesting, because ReactSelect won't compile the styles correctly

  .form-select__menu {
    z-index: 999;
  }

  .form-select__control {
    background-color: $white;
    border-color: $gray7;
    border-radius: 2px;
    height: 1.875rem;
    min-height: 1.75rem;
  }

  .form-select__control:hover {
    border-color: $cobalt;
    border-width: 1px;
    box-shadow: none;
  }

  .form-select__placeholder {
    font-size: 0.625rem;
    margin: 0;
    text-transform: lowercase;
  }

  .form-select__indicator {
    padding: 0.375rem;
  }

  .form-select__indicator-separator {
    display: none;
  }

  .form-select__menu-list {
    padding: 0.625rem;
    @extend .shadow-active;
  }

  .form-select__option {
    hyphens: auto;
    text-transform: none;
    word-break: break-word;
    line-height: calc(1.2em + 4px);
    vertical-align: middle;
    .option-content {
      svg {
        display: inline;
        width: 1.3em;
        height: 1.3em;
        vertical-align: middle;
        margin-left: 3px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
    @extend .paragraph;
    @extend .paragraph__black;
  }

  .form-select__option--is-focused {
    background-color: $gray1;
  }

  .form-select__option--is-selected {
    background-color: $gray2;
  }

  .form-select__single-value {
    color: $black;
    font-size: 0.675rem;
    font-weight: $proxima-semibold;
    margin: 0;
  }

  // Dropdown in Trends header, for example
  &__small {
    .form-select__single-value {
      text-transform: uppercase;
    }

    .form-select__indicator-separator {
      display: none;
    }

    .form-select__control {
      border-radius: 4px 4px;
      @extend .shadow-default;

      &:hover {
        box-shadow: 0 1px 2px 0 rgba(42, 41, 62, 0.2),
          0 1px 3px 0 rgba(42, 41, 62, 0.1);
      }

      &--is-focused {
        background-color: $cobalt10;

        .form-select__single-value {
          color: $cobalt;
        }

        .form-select__indicators {
          svg {
            path {
              fill: $cobalt;
            }
          }
        }
      }
    }
  }

  &__trends {
    .form-select__single-value {
      text-transform: capitalize;
      color: $black100;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .form-select__value-container {
      padding: 5px;
    }

    .form-select__indicator-separator {
      display: none;
    }

    .form-select__control {
      width: 129px;
      height: 32px !important;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid $gray100;
      // @extend .shadow-default;

      .form-select__indicator {
        padding: 0 5px;
        color: gray;
      }

      &:hover {
        border: 1px solid $black70;
      }

      &--is-focused {
        background-color: $cobalt10;

        .form-select__single-value {
          color: $cobalt;
        }

        .form-select__indicators {
          svg {
            path {
              fill: $cobalt;
            }
          }
        }
      }
    }
  }
}

.field__required {
  color: $cobalt;
  font-weight: $proxima-semibold;
  font-size: 1.25rem;
  line-height: 0;
  top: 2px;
}

.react-datepicker {
  border: none;
  @extend .shadow-active;
}

.react-datepicker__header {
  background-color: $gray1;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  border-bottom-color: $gray1;
  top: 1px;

  &::before {
    border: none;
    @extend .shadow-extreme;
  }
}

$border-color: $cobalt;
$border-color-active: $cobalt-active;

.react-datepicker-time__header,
.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-list-item {
  color: $black;
  white-space: nowrap;
  @extend .font-family;

  &:hover {
    background-color: $gray2;
  }
}

.react-datepicker__day--disabled {
  color: $gray3;

  &:hover {
    background-color: $white;
  }
}

.react-datepicker-time__header,
.react-datepicker__current-month {
  font-weight: $proxima-semibold;
}

.react-datepicker__navigation {
  &--previous {
    border-right-color: $border-color;

    &:hover {
      border-right-color: $border-color-active;
    }
  }

  &--next {
    border-left-color: $border-color;

    &:hover {
      border-left-color: $border-color-active;
    }
  }
}

.react-datepicker__time-container {
  width: 7.5rem;

  .react-datepicker__time-list-item {
    padding: 0.375rem !important;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100%;
  margin: 0;
}

//Selected Date and Selected time will have a cobalt background.
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $cobalt;
  color: $white;
  font-weight: $proxima-semibold;

  &:hover {
    background-color: $cobalt-active;
  }
}

//Sortable List (Drag and Drop) styles
.sortable-container {
  background-color: $cobalt10;
  margin-top: 1rem;
  padding: 0.5rem;

  .extra-info {
    margin: 0;
    text-align: center;
  }

  .sortable-list-container {
    margin: 0;
  }

  .sortable-list-wrapper {
    margin: 0;
    counter-reset: sortable;

    .sortable-list-item {
      background-color: $white;
      border-radius: 3px;
      display: block;
      margin: 0.75rem 0;
      padding: 0.5rem;
      user-select: none;
      width: 100%;

      &:hover {
        @extend .shadow-active;
        transition: box-shadow 0.2s ease-in;
      }

      &::before {
        counter-increment: sortable;
        content: counter(sortable);
      }

      .sortable-list__label {
        &::before {
          content: '. ';
        }
      }
    }
  }
}

.sortable-list-item-helper {
  background-color: $cobalt;
  border-radius: 3px;
  color: $white;
  list-style-type: none;
  padding-left: 1.25rem;
  width: 100%;
  padding-top: 0.5rem;
  @extend .shadow-extreme;
}

fieldset {
  border: 0;
}

.form-item-radio {
  /* The container */
  .radio-container {
    display: block;
    font-weight: 300;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-family: Lexend;
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
    margin-right: 10px;

    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .radio-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $black70;
      border-radius: 50%;
    }

    /* On mouse-over, add background color */
    &:hover input ~ .radio-checkmark {
      border-color: $tertiary100;
    }

    /* When the radio button is checked, add background */
    input:checked ~ .radio-checkmark {
      border-color: $tertiary100;
    }

    input:checked ~ span {
      font-weight: 500;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radio-checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .radio-checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-checkmark:after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $tertiary100;
    }
  }
}
