.button {
  //all buttons should have a pointer, and no gross outlines
  cursor: pointer;
  outline: none;
  background: transparent;

  //These shadows are for most buttons, but probably not the circle ones
  @mixin button__shadows() {
    @extend .shadow-default;

    // &:focus,
    &:hover {
      @extend .shadow-hover;
    }

    &:active {
      @extend .shadow-active;
    }
  }

  &:focus-visible {
    outline: #007aff solid 4px;
  }

  &-large {
    color: $cobalt;
    //font is the same as .headers
    @extend .headers;
    font-weight: $proxima-semibold;
    border: none;
    margin: 0 auto;
    padding: 0rem 1.75rem;
    transition: all 0.1s ease-in;
    text-align: center;
    text-transform: uppercase;
    width: auto;

    &:hover {
      background-color: $cobalt10;
    }

    &:active {
      background-color: $cobalt20;
    }
  }

  &-small {
    @extend .subtext;
    margin: 0;
    width: 80px;
    height: 27px;
    flex-shrink: 0;
    text-align: center;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 0.1rem;

    &__selected {
      color: $secondary100;
      border: 1px solid $secondary100;
      border-radius: 4px;
      color: $secondary100;
      //@include button__shadows();
    }

    &__unselected {
      border: 1px solid $black70;
      border-radius: 4px;
      color: $black70;
    }
  }

  &-border {
    color: $whiteNew;
    border: 1px solid $whiteNew;
    border-radius: 4px;
    margin: 10px 10px;
    padding: 0.4rem 1.75rem;
    transition: all 0.1s ease-in;
    text-align: center;
    width: 124px;
    height: 32px;
    text-transform: uppercase;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  &-border-inverted {
    color: $secondary100;
    border: 1px solid $secondary100;
    border-radius: 4px;
    padding: 0.4rem 1.75rem;
    transition: all 0.1s ease-in;
    text-align: center;
    width: 124px;
    height: 32px;
    text-transform: uppercase;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  //.button-action buttons might have plus, minus, or arrows within them.
  &-action {
    background-color: $white;
    border: none;
    padding: 0;

    .svg-stroke_fill {
      stroke: $gray8;
    }

    //place this shadow class for odd shape buttons (circles) on
    //the appropriate svg, g, circle or path tag in the svg
    .svg-button__shadow {
      @extend .svg-shadow-default;
      transition: all 0.2s ease-in;

      &:focus,
      &:hover {
        @extend .svg-shadow-hover;
      }

      &:active {
        @extend .svg-shadow-active;
      }

      &:active {
        @extend .svg-shadow-disabled;
      }
    }

    //backgrounds for square and circle svg buttons
    &__transparent,
    &__circle,
    &__square {
      .svg-button__background {
        fill: $white;
        transition: fill 0.2s ease-in;

        &:active {
          fill: $gray1;
        }
      }
    }

    //The button-action__negative class is the overlay buttons in the UI guide
    &__negative {
      .svg-button__background {
        fill: $gray3;
        transition: fill 0.2s ease-in;
      }

      &:hover,
      f:focus {
        .svg-button__background {
          fill: $gray4;
        }
      }

      &:active {
        fill: $gray5;
      }

      &:disabled {
        background-color: $white;
        border: 1px solid $gray3;
      }
    }

    &__transparent {
    }

    &__circle {
      border-radius: 50%;
      padding: 0.25em;
      background-color: $gray19;

      &:active {
        background-color: $gray3;
      }
    }

    &__square {
      // @include button__shadows();
      border-radius: 4px;
      // padding: 0.25em;

      &:active {
        background-color: $gray1;
      }

      //forward/back buttons on scale trackers
      .svg__arrow {
        height: 0.5rem;
      }
    }

    &__transparent {
      //nothing yet until we need one.
    }

    //The Add circle button with a "+"
    &__add {
      .svg-action__add-lines {
        stroke: $gray8;
        stroke-width: 2;
      }
    }

    //The Minus circle with a "-"
    &__minus {
      .svg-action__horizontal {
        stroke: $gray8;
        stroke-width: 2;
      }
    }

    //The Delete circle button with an "X"
    &__delete {
      svg {
        .svg-action__delete-lines {
          stroke: $gray8;
          stroke-width: 3;
        }

        &:disabled {
          .svg-action__delete-lines {
            stroke: $gray3;
          }
        }
      }
    }

    &__delete-appointment {
      .svg-button__background {
        fill: $gray2;
        transition: fill 0.2s ease-in;

        &:active {
          fill: $gray1;
        }
      }
      svg {
        .svg-action__delete-lines {
          stroke: $red;
          stroke-width: 2;
        }

        &:disabled {
          .svg-action__delete-lines {
            stroke: $gray3;
          }
        }
      }
    }
  }

  &-icon {
    //this class is for buttons that have icons in them, like the "morning", "day", "evening", "bedtime" icons in the trigger tracker
    border: none;
  }

  &-regular {
    //pill shaped buttons, like "submit" and "cancel", called "Regular" in the UI kit
    border: none;
    color: $cobalt;
    border-radius: 500px;
    font-size: 0.75rem;
    font-weight: $proxima-semibold;
    margin: 0 auto;
    padding: 0rem 1.75rem;
    transition: all 0.1s ease-in;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    height: 32px;

    //the "Back and Next" Regular buttons
    &__back,
    &__icon-first,
    &__icon-last,
    &__next {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__next {
      .icon-full-arrow {
        height: 1.25rem;
        padding-left: 0.25rem;
        width: 1.25rem;

        .svg-stroke {
          stroke: $white;
        }
      }
    }

    &__back {
      .icon-full-arrow {
        height: 1.25rem;
        padding-right: 0.25rem;
        width: 1.25rem;

        .svg-stroke {
          stroke: $black;
        }
      }
    }

    &__icon-first {
      font-size: 1rem;

      svg {
        height: 1rem;
        padding-right: 0.25rem;
        width: 1rem;
      }

      &.fill__white {
        svg {
          fill: $white;
        }
      }
    }

    &:disabled {
      background-color: $gray2;
      color: $gray6;
    }

    @include button__shadows();
  }

  &-rectangle {
    //Rectangle shaped buttons
    border: none;
    border-radius: 8px;

    &:disabled {
      background-color: $gray2;
      color: $gray6;
    }
  }

  &-rectangle-small {
    @extend .button-rectangle;
    width: 148px;
    height: 32px;
    flex-shrink: 0;
  }

  &-rectangle-medium {
    @extend .button-rectangle;
    width: 188px;
    height: 32px;
    flex-shrink: 0;

    &:disabled {
      background-color: #1d62ed80 !important;
      color: $whiteNew !important;
    }
  }

  &-rectangle-80 {
    @extend .button-rectangle;
    height: 40px;
    width: 80%;
  }

  &-rectangle-large {
    @extend .button-rectangle;
    height: 40px;
    width: 100%;
  }

  &-rectangle-auto {
    @extend .button-rectangle;
    width: auto;
    height: 32px !important;
    display: inline-block;
  }

  &-rectangle-skin-footer {
    @extend .button-rectangle;
    width: 76px !important;
    height: 32px;
    flex-shrink: 0;
  }

  &-rectangle-100 {
    @extend .button-rectangle;
    width: 148px;
    height: 35px;
    flex-shrink: 0;
  }

  &-square {
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: $secondary100;
    flex-shrink: 0;
    color: $white;
    text-align: center;
  }

  //Some buttons look like links
  &__link {
    border: none;
    font-size: 0.675rem;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: $proxima-semibold;

    //Sometimes these links have forward or back buttons
    &-back,
    &-forward {
      align-items: center;
      display: flex;

      svg {
        height: 0.5rem;
        margin: 0 0.25rem;
      }

      //the arrow needs to come up a tiny bit
      .icon-full-arrow {
        margin-bottom: 0.1rem;
      }

      .button__inner-wrapper {
        border-radius: 2px;
        display: flex;
        align-items: baseline;
        padding: 0.5rem;
      }
    }
  }

  //.button-row is the wrapper class for a row of buttons in a component
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &-row-right {
    display: flex;
    flex-direction: row;
    justify-content: right !important;
  }

  &-row-space {
    margin-right: 10px !important;
  }

  //Color classes for your color backgrounds
  &__green {
    background-color: $green;
    color: $white;

    &:active {
      background: $green-active;
    }
  }

  &__cobalt {
    background-color: $cobalt;
    color: $white;

    &:active {
      background-color: $cobalt-active;
    }
  }

  &__white {
    background: $white;
    color: $black;

    &:active {
      background: $gray1;
    }
  }

  &__red {
    background: $red;
    color: $white;

    &:active {
      background: darken($red, 10%);
    }
  }

  &__tangerine {
    background-color: $tangerine;
    color: $white;

    &:active {
      background-color: $gold;
    }
  }

  &__cobalt-dark {
    background-color: $cobalt-dark;
    color: $white;

    &:active {
      background-color: $cobalt-dark;
    }
  }

  &__secondary100 {
    background-color: $secondary100;
    color: $white;

    &:active {
      background: $primary100;
    }
  }

  &__red-inverted {
    background: $white;
    color: $tertiary100;
    border: 1px solid $tertiary100;
  }

  &__secondary100-inverted {
    border: 1px solid $secondary100;
    border-radius: 8px;
    color: $secondary100 !important;
    text-align: center;

    &:active {
      background: $secondary100;
      color: $whiteNew !important;
    }
  }

  &__secondary100-no-border {
    color: $secondary100 !important;
    text-align: center;

    &:active {
      background: $secondary100;
      color: $whiteNew !important;
    }
  }

  //font-weights and font-sizes
  &__btn-14-600 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  &__btn-18-400 {
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  &__btn-16-700 {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  //Color classes for text colors
  &__violet-text {
    color: $cobalt;

    &    :active,
    // &:focus, // Todo: @jose - maybe specific focus styles?
    &:hover {
      color: $cobalt-active;
    }

    .button__inner-wrapper {
      &:active {
        background-color: $gray1;
      }
    }

    .svg-stroke {
      stroke: $cobalt;
    }
  }

  &__cobalt-dark-text {
    border: 1px solid $cobalt-dark;
    color: $cobalt-dark;

    &    :active,
    // &:focus, // Todo: @jose - maybe specific focus styles?
    &:hover {
      color: $cobalt-active;
    }

    .button__inner-wrapper {
      &:active {
        background-color: $gray1;
      }
    }

    .svg-stroke {
      stroke: $cobalt;
    }
  }

  &:disabled {
    pointer-events: none;
  }

  &__toggle {
    border: none;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;

    &-active {
      background: $cobalt;
      color: $white;
    }

    &-inactive {
      background: $gray4;
      color: $white;
    }

    &-active,
    &-inactive {
      border-radius: 4px;
      font-weight: $proxima-semibold;
    }

    &.button {
      &:not(:first-of-type) {
        margin-left: 0.125rem;
      }

      &:not(:last-of-type) {
        margin-right: 0.125rem;
      }
    }
  }

  //**New Styles

  &__secondary100 {
    background-color: $secondary100;
    display: inline-block;
    color: $white;
    text-transform: lowercase !important;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

    &:active {
      background-color: $cobalt-active;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.transparent-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-top: 5px;
}

.button-size {
  margin-top: 0px !important;
  svg {
    max-width: 30px !important;
  }
}
