.rt-tr {
  padding: 7px 0;
}

.rt-tr .rt-th {
  font-weight: 600;
}

.rt-td {
  align-self: center;
  text-align: center;
  align-self: stretch;
}

.rt-td.user-name {
  font-weight: 600;
}

.rt-td.visible-overflow {
  overflow: visible;
}

.toggle-button:hover {
  color: #000;
}

.menu-items {
  position: absolute;
  top: 100%;
  z-index: 1000;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px 0;
  background-color: white;
  box-shadow: 0 1px 4px 1px rgba($color: #000000, $alpha: 0.1);
}

.menu-button {
  box-sizing: border-box;
  margin: 4px;
  padding: 6px 12px;
  border: none;
  outline: none;
  color: #555;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
}

.menu-button:hover,
.menu-item:focus {
  background-color: rgba(217, 217, 255, 0.5);
}
