.site-logo-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  @include bp(small-up) {
    min-width: 160px;
  }

  &__column {
    flex-direction: column;
  }

  .logo-nea__horizontal {
    height: 2.8rem;
  }

  .logo-nea__horizontal_new {
    width: 128px;
    height: 24px;
    flex-shrink: 0;
  }

  .logo-nea__standard {
    height: 5rem;
  }

  //.st0, .st3
  .logo-fill__navy {
    fill: $navy;
  }

  //.st1
  .logo-fill__violet {
    fill: $cobalt;
  }

  //.st3
  .logo-fill__white {
    fill: $white;
  }
}
