.page-home {
  background-color: #f5f7fb;
  background-image: url(../../assets/images/newBrandImages/ECW-Background.svg);
  background-repeat: no-repeat;
  background-size: calc(1418px * 1) calc(1474px * 1);

  @include bp(largest) {
    background-position: top -80px right 0px, center left, bottom 320px right;
  }

  // Transitions
  .appear-to-left {
    animation: appearToLeft ease 1s;
  }
  .appear-to-right {
    animation: appearToRight ease 1s;
  }
  .appear-to-top {
    animation: appearToTop ease 1s;
  }
  @keyframes appearToLeft {
    0% {
      opacity: 0;
      transform: translate(300px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  @keyframes appearToRight {
    0% {
      opacity: 0;
      transform: translate(-200px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  @keyframes appearToTop {
    0% {
      opacity: 0;
      transform: translate(0, 100px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .content-section {
    background: transparent;
    font-size: 16px;
    font-weight: 400;

    .paragraph {
      font-size: 1em;
      color: $gray18;

      @include bp(small-up) {
        font-size: 1rem;
      }
      @include bp(medium) {
        font-size: 1.1rem;
      }
      @include bp(large) {
        font-size: 1.1rem;
        max-width: 80%;
      }
      @include bp(largest) {
        font-size: 1.2rem;
        max-width: 80%;
      }
    }

    .title__homepage-large {
      font-size: 2.5em;
      font-weight: 500;
      line-height: 1.25;
      margin: 1rem 0;

      @include bp(small-up) {
        font-size: 2rem;
      }
      @include bp(medium) {
        font-size: 2rem;
      }
      @include bp(large) {
        font-size: 2.5em;
      }
      @include bp(largest) {
        font-size: 3.5em;
      }
    }
  }

  /** 1. HERO SECTION */
  .home-page__section-one {
    margin-top: 20px;

    @include bp(small-down) {
      margin-top: 50px;
    }
    @include bp(large) {
      margin-top: 40px;
    }

    .content-wrapper {
      width: 100%;
      max-width: 100%;
      justify-content: space-between;
      margin: 0;
      padding-top: 1rem;

      @include bp(large) {
        display: flex;
        flex-direction: row-reverse;
      }
      .content-section {
        margin-top: 2em;
        @include bp(large) {
          margin-top: 1em;
        }

        .paragraph {
          @include bp(large) {
            max-width: 80%;
          }
        }
        .title__homepage-large {
          @include bp(large) {
            margin-top: 1em;
            margin-bottom: 0.35em;
          }
        }

        @include bp(large) {
          padding-left: 0;
          max-width: 45%;
          margin-left: 5%;
        }
        @include bp(larger) {
          padding-left: 0;
        }
      }
    }

    .content-image__hero {
      width: 100vw;
      height: 70vw;
      position: relative;

      @include bp(large) {
        width: 45vw;
        height: 34vw;
      }

      .image__eczemawise {
        position: absolute;
        max-width: 130%;

        &-desktop {
          width: 96%;
          bottom: 0;
          left: 0;

          @include bp(large) {
            width: 125%;
            bottom: -15%;
            left: -35%;
          }
        }
        &-mobile {
          width: 35%;
          bottom: -10%;
          right: 0;

          @include bp(large) {
            width: 40%;
            bottom: -130px;
            right: 10%;
          }
        }
        &-award {
          width: auto;
          height: auto;
          transition: all ease-in 0.2s;
          padding: 8px 10px;
          bottom: 64%;
          right: 25px;
          z-index: 1;

          @include bp(small-up) {
            bottom: 80%;
            right: 25px;
          }
          @include bp(large) {
            bottom: 68%;
            padding: 16px 20px;
          }

          @include bp(largest) {
            bottom: 70%;
            padding: 16px 20px;
          }

          @include bp(small-down) {
            right: 20px;
          }

          img {
            width: 120px;

            @include bp(largest) {
              width: 140px;
            }

            @include bp(small-down) {
              width: 80px;
            }
          }
        }
      }
    }
  }

  /** TRY BUTTON */
  .try-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1.5em;
    padding: 10px 0 0;

    @include bp(large) {
      margin-top: 28px;
    }

    .try-button {
      display: flex;
      flex-direction: row;
      height: auto;
      text-align: center;
      border-radius: 8px;
      background: $secondary100;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      align-items: center;
      transition: all ease-in 0.2s;
      width: 100%;
      padding: 10px;
      justify-content: center;

      @include bp(large) {
        width: auto;
        padding: 10px 72px;
      }

      img {
        height: 16px;
        display: inline;
        margin: 0 12px 0 0;
        transition: margin ease-in 0.2s;
      }
      span {
        line-height: 28px;
      }
      &:hover {
        color: $blue;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);

        img {
          margin: 0 6px;
        }
      }
    }
  }

  /** APP STORES */
  .app-stores-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 12px;

    @include bp(large) {
      justify-content: flex-start;
    }

    .store-button {
      width: 188px;
      height: auto;
      padding: 10px 24px;
      background: #000;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      transition: box-shadow ease-in 0.2s;
      &:hover {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
      }
      &:last-child {
        margin-right: 0;
      }

      @include bp(small-down) {
        width: 150px;
      }
    }
    img {
      width: 140px;
    }
  }

  /** 2. VIDEO SECTION */
  .home-page__video-section {
    flex-direction: column;
    margin-top: 120px;

    @include bp(large) {
      margin-top: 140px;
    }

    .content-wrapper {
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      @include bp(large) {
        max-width: 1000px;
      }
      @include bp(largest) {
        max-width: 1380px;
      }

      .video-section {
        width: 100%;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        border-radius: 16px;

        .video-iframe {
          width: 839px;
          height: 471px;
          margin: 0 auto;
          max-width: 100%;
          border-radius: 16px;

          @include bp(small-down) {
            width: 100%;
            height: 300px;
          }
          @include bp(large) {
            max-width: 1000px;
            max-height: 540px;
            border-radius: 16px;
          }
          @include bp(largest) {
            max-width: 1400px;
            max-height: 756px;
            border-radius: 16px;
          }
        }
      }

      .content-section {
        width: 100%;
        padding: 0 1rem;
        text-align: center;

        @include bp(small-down) {
          padding: 0 0;
        }

        &.last-section {
          margin-bottom: 3.5rem;

          .title__homepage-large {
            margin-top: 0;
          }
        }

        @include bp(large) {
          padding: 2rem 6rem 0;
        }

        .title__homepage-large {
          text-align: left;
          @include bp(large) {
            text-align: center;
          }
        }

        .paragraph {
          margin: 0 auto;
          text-align: left;
          @include bp(large) {
            text-align: center;
          }
        }
      }
    }

    &.video-section--horizontal {
      margin-top: 80px;
      border-radius: 16px;

      @include bp(small-down) {
        margin-top: 50px;
        padding-top: 0;
      }
      @include bp(large) {
        .content-wrapper {
          flex-direction: row;
          justify-content: space-between;
          max-width: 1200px;
          padding-bottom: 5rem;
          border-radius: 16px;

          .video-section {
            width: 60%;
            border-radius: 16px !important;
            .video-iframe {
              width: 100%;
              height: 400px;
              border-radius: 16px !important;
            }
          }
          .content-section {
            width: 38%;
            padding: 0 1rem;
            .title__homepage-large {
              text-align: left;
            }
            .paragraph {
              text-align: left;
              max-width: 100%;
            }
          }
        }
      }
      @include bp(largest) {
        .content-wrapper {
          max-width: 1380px;
          border-radius: 16px !important;

          .video-section {
            .video-iframe {
              height: 480px;
              border-radius: 16px !important;
            }
          }
        }
      }
    }
  }

  /** 3. TESTIMONIALS SECTION */
  .home-page__testimonials-section {
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @include bp(large) {
      margin-top: 120px;
    }

    .content-wrapper {
      max-width: 1200px;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      width: 100%;

      @include bp(large) {
        flex-direction: row;
      }
      @include bp(largest) {
        max-width: 1380px;
        flex-direction: row;
      }

      .image-section {
        width: 100%;
        justify-content: center;
        flex-direction: row;
        text-align: center;

        @include bp(large) {
          width: 50%;
        }

        img {
          width: 70%;
          height: auto;

          @include bp(large) {
            width: 70%;
          }
        }

        .image__user {
          display: inline-block;
          transform: scale(1) translate(100px, 100px);

          &.visible {
            transform: scale(0.9) translate(-210px, 0);

            @include bp(small-down) {
              transform: scale(1.5) translate(-80px, 40px);
            }
          }

          @include bp(large) {
            transform: scale(1.1) translate(-150px, 20px);
            &.visible {
              transform: scale(1.1) translate(-150px, 20px);
            }
          }
          @include bp(largest) {
            transform: scale(1.2) translate(90px, 0px);
            &.visible {
              transform: scale(1.2) translate(-290px, 0px);
            }
          }
        }
      }

      .content-section {
        width: 100%;
        max-width: 100%;
        padding: 0 1rem;
        text-align: center;
        margin-top: 25px;

        @include bp(large) {
          width: 50%;
          text-align: left;
          padding: 2rem 0 2rem;
          margin-top: 25px;
        }

        .title__homepage-large {
          text-align: left;
        }

        .paragraph {
          margin: 0 auto;
          text-align: left;

          @include bp(large) {
            max-width: 100%;
          }
        }

        .carousel-container {
          margin-top: 2em;
          width: 100%;
          display: block;

          max-width: 270px;

          @include bp(small) {
            max-width: 310px;
          }
          @media (min-width: 400px) {
            max-width: 370px;
          }
          @media (min-width: 500px) {
            max-width: 470px;
          }
          @include bp(small-up) {
            max-width: 600px;
          }
          @include bp(medium) {
            max-width: 700px;
          }
          @include bp(larger-medium) {
            max-width: 900px;
          }

          @include bp(large) {
            max-width: 100%;
          }

          .testimonial {
            display: flex;
            flex-direction: row;
            text-align: left;
            padding-top: 25px;

            &-quote {
              width: 15%;
              align-items: flex-start;
              justify-content: flex-start;
              display: flex;
              align-items: start;
              color: $tertiary100;
              font-family: Arial, Serif;
              font-size: 6em;
              margin-top: -25px;

              @include bp(large) {
                font-size: 8em;
                width: 10%;
              }

              &::before {
                content: '“';
                line-height: 1;
              }
            }

            &-content {
              width: 85%;
              @include bp(large) {
                width: 90%;
              }
            }

            &-text {
              color: $black100;
              font-weight: 600;
              line-height: 1.3;
              font-size: 1em;

              @include bp(large) {
                font-size: 1.4em;
              }
            }
            &-user {
              color: $secondary100;
              font-weight: 600;
              font-size: 1em;
              margin-bottom: 4em;
              line-height: 1.2;
              @include bp(large) {
                font-size: 1.1em;
              }
            }
          }
        }
      }
    }
  }

  /** 4. AWARDS SECTION */
  .home-page__awards-section {
    flex-direction: column;
    margin-bottom: 120px;

    @include bp(small-down) {
      margin-bottom: 20px;
    }

    @include bp(large) {
      margin-top: 50px;
    }

    .content-wrapper {
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      @include bp(large) {
        max-width: 1000px;
      }
      @include bp(largest) {
        max-width: 1380px;
      }

      .content-section {
        width: 100%;
        padding: 0 1rem;
        text-align: center;
        @include bp(large) {
          padding: 2rem 6rem 0;
        }

        .title__homepage-large {
          text-align: left;
          @include bp(large) {
            text-align: center;
          }
        }

        .paragraph {
          margin: 0 auto;
          text-align: left;
          @include bp(large) {
            text-align: center;
          }
        }

        .awards-container {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 30px 0 40px;
          order: 4;

          .award-container {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;

            @include bp(small-up) {
              width: 30%;
            }

            .text-container {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-top: 15px;

              .text_black {
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0em;
                color: #000000;
                margin-bottom: 5px;

                @include bp(small-down) {
                  font-size: 15px;
                  line-height: 16px;
                }
              }

              .text_grey {
                font-size: 18px;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
                color: #575a5d;

                @include bp(small-down) {
                  font-size: 13px;
                  line-height: 16px;
                }
              }
            }

            .img-container {
              background: linear-gradient(180deg, #092a6d 0%, #1151d3 100%);
              border-radius: 50%;
              padding: 20px;
              display: flex;
              justify-content: center;

              @include bp(small-down) {
                padding: 10px;
              }
            }
            img {
              width: 150px;
              height: 150px;

              @include bp(small-down) {
                width: 75px;
                height: 75px;
              }
            }
          }
        }
      }
    }
  }

  .content__width {
    max-width: 92%;
    margin: auto;
  }

  /** 4. FEATURES SECTION */
  .home-page__section-three {
    margin-top: 80px;

    @include bp(small-down) {
      margin-top: 0px;
    }
    @include bp(large) {
      margin-top: 0;
    }

    h2 {
      margin: 1rem 0 1.75rem;
    }

    .content-wrapper {
      @include bp(large) {
        display: flex;
        flex-direction: row-reverse;
        max-width: 1200px;
        margin: auto;
        padding-bottom: 5rem;
      }
      @include bp(largest) {
        max-width: 1380px;
      }
      .content__width {
        padding-top: 2rem;
        padding-bottom: 1rem;
        @include bp(large) {
          margin: 0;
          padding-top: 4rem;
          padding-left: 4rem;
          width: 50%;
          max-width: 600px;
        }
        @include bp(largest) {
          max-width: 640px;
        }
      }
      .cta {
        display: flex;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          width: 100%;
          &::after {
            content: url(../../assets/images/q-sky-blue.svg);
            height: 1rem;
            padding-left: 0.25rem;
          }
          @include bp(large) {
            margin-left: 0;
            width: 180px;
          }
        }
      }
      .try-button-container {
        .try-button {
          width: auto;
          padding-left: 4rem;
          padding-right: 4rem;
        }
      }
    }
  }

  .image__phones-wrapper {
    height: calc(100vw);
    position: relative;
    top: 40px;
    @include bp(large) {
      height: unset;
      width: 50%;
      max-width: 550px;
      margin: auto;
    }
  }
  .image__phones {
    display: inline-block;
    transform: scale(1.3) translate(100px, 100px);
    transition: all 0.8s ease-in-out;

    &.visible {
      transform: scale(1.3) translate(30px, 0);

      @include bp(small-down) {
        transform: scale(1) translate(30px, 0);
      }
    }

    @include bp(large) {
      transform: scale(1.5) translate(90px, 10px);
      &.visible {
        transform: scale(1.5) translate(90px, 10px);
      }
    }
    @include bp(largest) {
      transform: scale(1.7) translate(90px, 0px);
      &.visible {
        transform: scale(1.7) translate(90px, 0px);
      }
    }
  }

  .homepage__list {
    margin-top: 2rem;
    margin-bottom: 0rem;
    li {
      color: $black100;
      display: flex;
      font-weight: $proxima-bold;
      font-size: 15px;
      line-height: 1.625rem;
      padding-bottom: 1rem;
      @include bp(large) {
        font-size: 14px;
      }
      @include bp(largest) {
        font-size: 1.2rem;
      }
    }
    .list__image {
      min-width: 2rem;
      margin-right: 0.5rem;
      margin-top: 0.35rem;
      img {
        width: 1rem;
      }
    }
  }
}

//Get Started background

.get-started-bg {
  padding-top: 150px;
  background-image: url(../../assets/images/newBrandImages/ECWGetStarted.svg);
  background-repeat: no-repeat;

  @include bp(largest) {
    background-size: calc(800px * 1) calc(800px * 1);
    background-position: top 0 right 1200px, center left, bottom right;
  }
}

// Filters
.date-bar__filters {
  .filters-container {
    display: flex;
    flex-direction: row;
    margin: 0 16px 0 0;
    justify-content: space-between;
    width: 100%;

    &.bottom {
      border-bottom: none;
      border-top: 1px solid #ddd;
      margin: 24px 16px 0 16px;
      padding-top: 12px;
    }

    .recommended,
    .latest {
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      margin: 10px 8px;
      margin-bottom: 0;
      color: $black100;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $secondary100;
        color: $secondary100;
        font-weight: 700;
      }

      &.back {
        color: $black100;
        /* H4/Bold */
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10px !important;
        margin-left: -5px !important;
        text-transform: uppercase;
      }
    }

    .stretch {
      flex: 1;
      // border-left: 1px solid #ddd;
      height: 59px;
      margin-top: -25px;
    }

    .Dropdown-control,
    .filter-input {
      outline: none;
      margin: 6px 7px 0 7px;
      height: 32px;
      width: 190px;
      position: relative;
      border-radius: 8px;
      border: 1px solid #d8dadd;
      color: $black100;
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    ::placeholder {
      color: $black100;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 1; /* Firefox */
    }

    .Dropdown-control {
      height: 32px;
      width: auto;
      background-color: #fff !important;
      border: 1px solid #d8dadd;
      padding: 6px 32px 6px 12px;
    }

    .Dropdown-menu {
      min-width: 322px;
      border: 1px solid #d8dadd;
      border-radius: 8px;
      right: 0;
    }
    .Dropdown-option {
      box-sizing: border-box;
      color: $black100;
      cursor: pointer;
      display: block;
      padding: 8px 10px;
    }

    .search {
      background-image: url('../../assets/images/search.png');
      background-position: 5px 7px;
      background-repeat: no-repeat;
      padding: 12px 20px 12px 40px;
    }

    .icon-back {
      font-size: 16px !important;
      color: #000000 !important;
      margin-right: 8px;
    }

    .sidebar-button {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 42px;
      width: 250px;
      padding-left: 1.125rem;
      padding-right: 2.25rem;

      @include bp(small-down) {
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        .page-icon {
          height: 1.8rem;
          width: 1.8rem;
        }

        h1 {
          display: none;
        }
      }

      h1 {
        margin-left: 0.5rem;
      }
    }
  }
}

.home-page__articles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 900px;
  width: 100%;
  margin: auto;

  .no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;

    .no-results-title,
    .no-results-description {
      text-align: center;
      font-size: 16px;
      color: #504f60;
      margin: 16px 0;
    }

    .no-results-img {
      margin: 24px 0;
      height: 150px;
    }
    .no-results-title {
      font-size: 18px;
      letter-spacing: 0.3px;
      font-weight: 600;
    }

    .no-results-description {
    }
  }

  .article {
    padding: 12px 5%;
    border-radius: 4px;
    border: 1px solid $gray50;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .article-name {
      color: var(--primary-navy, #092a6d);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.48px;
      margin-bottom: 10px;
    }

    .description {
      padding-bottom: 18px;

      .article-name {
        color: $black100;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .article-sub-name {
        color: $black100;

        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .article-thumbnail-author {
        margin: 10px 0 0 0;
        color: $secondary100;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .article-thumbnail-author-by {
        color: $black100;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .article-info {
        color: $black100;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .article-date {
        margin-top: 10px;
        color: $black100;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    a {
      color: $cobalt;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    h1 {
      color: $cobalt;
      font-size: 32px;
      font-weight: 300;
    }

    .header-image {
      width: 100%;
      margin: 8px 0;
      border-radius: 8px;
    }

    h1 ~ h2 {
      font-weight: 500;
      // border-bottom: 1px solid $gray2;
      padding-bottom: 18px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #434255;
    }

    h2 {
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    h5,
    h6 {
      font-size: 14px;
      padding-left: 8px;
    }

    p:first-child {
      margin-top: 36px;
    }

    p:last-child {
      margin-bottom: 18px;
    }

    p:last-of-type {
      margin-bottom: 24px !important;
    }

    p {
      color: var(--neutral-neablack, #2d2926);
      font-size: 16px;
      width: 100%;
      position: relative;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  .articles {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 900px;

    .load-more-button {
      outline: none;
      background: var(--primary-navy, #092a6d);
      width: 290px;
      align-self: center;
      border: 2px solid #092a6d;
      color: #fff;
      padding: 12px 28px;
      margin-top: 28px;
      border-radius: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      cursor: pointer;

      &:hover {
        background-color: #1a397c;
      }
    }

    .article-thumbnail {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      cursor: pointer;
      background-color: white;
      border: 1px solid $gray50;
      // box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.2);
      border-radius: 4px;
      margin: 12px;

      &:hover {
        .article-thumbnail-image {
          filter: grayscale(0);
        }
        .article-thumbnail-title {
          text-decoration: underline;
        }
      }

      .article-thumbnail-image {
        flex: 3;
        filter: grayscale(0.4);
        transition: filter 0.2s ease;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .description {
        padding: 16px 24px;
        flex: 6;
        display: flex;
        flex-direction: column;

        .article-thumbnail-title {
          margin-bottom: 12px;
          color: $black100;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .article-thumbnail-excerpt {
          color: $black100;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .article-thumbnail-author {
          margin: 10px 0 0 0;
          color: $secondary100;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .article-thumbnail-author-by {
          color: $black100;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .article-thumbnail-date {
          margin-top: 10px;
          color: $black100;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.no-bg {
  background-image: none !important;
  background-repeat: no-repeat;
}
