//Color themes for active-cards
//the color map can be found on _colors.scss
@each $name, $value in $color-map {
  .tracker-theme-#{$name} {
    .filter-circle,
    .tracker {
      &-header {
        align-items: center;
        border-bottom: 1px solid $gray2;
        display: flex;
        height: 2.875rem;
        background-color: $secondary100;

        .title {
          color: $white;
        }
        // margin: 0 0 1rem;

        &-title {
          color: $value;
          margin: 0;
          padding-left: 0.5rem;
        }

        &__active {
          border-bottom: 1px solid $secondary100;

          // .tracker-icon-image {
          //   //Leaving a space here in case this is the selector I need for my next sprint issue (progress badges)
          // }

        }
      }
    }

    .tracker-notes {
      background-color: rgba($value, 0.05);

      .subtext {
        color: $value;
      }
    }

    //Icons will be white with a corresponding background color based on the theme.
    .tracker-icon-image {
      fill: $white;
    }

    .tracker-icon-background {
      fill: $value;
    }

    &.trends-summary-triggers-list,
    .trends-summary-item-list {
      color: $value;

      .icon-fill {
        fill: $value;
      }
    }

    //Line chart
    .line {
      color: $value;
      stroke: $value;
    }

    .datapoint {
      stroke: $value;
      fill: $value;
    }

    .dragscale-wrapper {
      //Styles specific to components that use a dragscale
      .dragscale-body {
        .scale {
          &-unit {
            background-color: $gray5;
          }
        }
      }
    }

    .treatment {
      //styles specific to treatment tracker that need additional theme help
      .subtext {
        color: $value;
      }
    }

    //Make select fields have themed focus and hover colors
    .form-select__control:hover {
      border-color: $value;
    }

    .progress-bar-item {
      //The progress dots (no numbers)
      .process {
        &__complete {
          .progress-dot__inner {
            fill: $value;
          }
        }
      }
      //The progress icon that has a number inside of it
      .progress-counter {
        &__circle {
          stroke: $gray4;
          fill: $white;
        }

        &__number {
          fill: $gray4;
        }

        .counter-backline::before {
          background-color: $gray4;
        }
      }

      &__active {
        .progress-counter {
          &__circle {
            stroke: $value;
            fill: $white;
          }

          &__number {
            fill: $value;
          }
        }
      }

      &__completed {
        .progress-counter {
          &__circle {
            stroke: $value;
            fill: $value;
          }

          &__number {
            fill: $white;
          }
        }
      }

      &__active,
      &__completed {
        .counter-backline::before {
          background-color: $value;
        }
      }
    }

    //tracker-list-image is the image used for each checkbox item on Trigger and Treatment trackers
    //The background remains blue because the lightened dark-teal looks terrible
    .tracker-list-image {
      background-color: lighten($blue-light, 40%);

      svg {
        stroke: $value;
        g,
        circle {
          fill: $value;
        }
      }
    }

    .tracker-footer {
      //override the green button to be the same as the tracker color. May 2019
      .button__green {
        background-color: $value;
        &:disabled {
          background-color: $gray1;
          color: $gray5;
        }
      }
    }
  }
}
