.tracker-skin {
  position: relative;
  //This tracker's layout uses grid. The layout is determined
  //by whether it is active or inactive (read/write states)
  @include bp(small-down) {
    min-height: 34.125rem;
  }

  .tracker-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    @include bp(small-down) {
      display: flex;
    }
  }

  //These are the named areas for the grid:
  //progress,content-half, graphic-half, notes, and divider

  //Questionaire/survey
  .skin-progress {
    // grid-area: progress;
  }

  //The section with content
  .skin-tracker__half-content {
    grid-area: content-half;
  }

  //the section with the body SVG and scale trackers
  .skin-tracker__half-graphic {
    grid-area: graphic-half;
  }

  //The area of the readview that has user entered notes
  .tracker-notes {
    grid-area: notes;
  }
  ////End of grid area naming///////

  .skin-areas-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 11rem;
    @include bp(small-down) {
      height: 12.6rem;
    }

    .svg-skin-body {
      height: 100%;
      margin-bottom: 0.625rem;
      width: 100%;

      g {
        stroke-width: 1;
      }

      .svg-skin__body-part {
        fill: $white;
        stroke: $secondary100;
        stroke-width: 2;

        //use svg-skin__body-part__selected for selected body parts
        &-selected {
          fill: $secondary50;
        }
      }
    }
  }

  .half-content-child {
    padding: 0 0.5rem 1.125rem 0;
    margin-bottom: 0.5rem;
    @include bp(small-down) {
      margin-top: 1.5rem;
    }

    &__row {
      display: flex;
      flex-direction: row;
    }
  }
}

.infections-svg {
  height: 1.7rem;
  width: 1.875rem;

  path {
    fill: $skin;
  }
}

.react-viewer {
  img.react-viewer-image {
    margin: 0;
    border-radius: 6px;
  }

  .react-viewer-canvas {
  }

  .react-viewer-toolbar {
    height: 36px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .react-viewer-btn {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .react-viewer-close {
    margin: 8px;
  }

  .react-viewer-close > .react-viewer-icon {
    position: static;
    font-size: 18px;
    color: #eee;
  }

  .react-viewer-icon,
  .material-icons {
    display: inline;
    font-size: 18px;
    vertical-align: middle;
    opacity: 1;
    -webkit-user-select: none;
  }

  .material-icons {
    color: rgb(236, 102, 102);
  }
}

//styles specific to the write state
.tracker-skin.card__active {
  .tracker-body {
    grid-template-columns: unset;
    @include bp(small-down) {
      margin-bottom: 2rem;
    }

    //Grid layout to screens larger than a phone
    .skin-content__grid {
      //this is the progress bar inside the process content area, not the numbered ones at the top
      .progress-bar-wrapper {
        padding-top: 1.125rem;

        .counter-backline {
          display: none;
        }
        @include bp(small-up) {
          display: none;
        }
      }
    }

    //This is the progress bar at the top of the tracker, with numbers inside
    .skin-progress {
      margin: 0 auto;

      .progress-bar-wrapper {
        margin: auto;
        padding: 0;
      }
    }

    .skin-tracker__halves {
      @include bp(small-down) {
        display: flex;
        flex-direction: column;
      }
      display: grid;
      grid-template-columns: repeat(autofit, minmax(15.25rem, 1fr));
      grid-template-areas: 'content-half content-half graphic-half graphic-half';
    }

    //grid layout for small screens (phones)
    @include bp(small-up) {
      grid-template-rows: auto;
      overflow: auto;
    }
  }

  .skin-tracker__half-content,
  .skin-tracker__half-graphic {
    width: 15.25rem;
    @include bp(smaller-medium-only) {
      width: 13.25rem;
    }
  }

  .skin-tracker__half-content {
    .progress-bar-item {
      margin: 0.25rem auto 0;
      align-items: flex-start;
    }
  }

  .skin-tracker__half-graphic {
    @include bp(small-up) {
      padding-left: 1rem;
    }
  }

  .skin-images {
    padding: 0.675rem 1.2rem 0;

    input[type='file'] {
      display: none;
    }

    .skin-file-upload {
      float: right;
      display: block;
      padding: 7px 12px;
      font-size: 12px;
    }

    .images {
      div {
        position: relative;
        img {
          cursor: zoom-in;
          margin-top: 6px;
          border-radius: 4px;
          box-sizing: border-box;
          box-shadow: 0 1px 2px 0 rgba(42, 41, 62, 0.3),
            0 1px 3px 0 rgba(42, 41, 62, 0.2);
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .form-input-field {
    width: 100%;
  }

  .skin-tracker__yes-no {
    display: flex;
    flex-direction: column;
  }

  .skin-areas-wrapper {
    .svg-skin-body {
      //hover states for the body svg are only available in the write state
      .svg-skin__body-part,
      .svg-skin__body-part__selected {
        &:hover {
          fill: $secondary100;

          .svg-skin__back-butt-line {
            stroke: $white;
            -webkit-transform: translate(0.1rem, -0.25rem);
            transition: transform 0.2s ease-out;
          }
        }

        &:active {
          fill: lighten($secondary100, 25%);
        }
      }
    }
  }

  .skin-tracker__half-graphic.skin-areas__disabled {
    .svg-skin-body__front-group {
      .svg-skin__body-part {
        cursor: default;
        fill: $gray;
        transition: fill 0.2s ease;
      }
    }

    .svg-skin-body__back-group {
      .svg-skin__body-part {
        cursor: default;
        fill: $gray;
        transition: fill 0.2s ease;

        &:hover {
          .svg-skin__back-butt-line {
            stroke: $skin;
            -webkit-transform: translate(0.1rem, -0.25rem);
            transition: transform 0.2s ease-out;
          }
        }
      }
    }
  }

  .skin-scale-wrapper {
    background-color: lighten($skin, 70%);
    border-radius: 4px;
    padding: 0.675rem 0.675rem 0;

    .dragscale-wrapper {
      border-top: 1px solid lighten($skin, 50%);
      padding-top: 0.9rem;
      overflow-x: hidden;
      position: relative;
      top: 0;
      left: 0;
    }

    .tab-label {
      background-color: $white;
    }

    .scale {
      margin-bottom: 1rem;

      .scale-unit {
        width: 2.5rem;
      }
    }
  }

  .skin-tracker-scale-wrapper {
    background-color: lighten($skin, 70%);
    border-radius: 4px;

    .dragscale-wrapper {
      border-top: 1px solid lighten($skin, 50%);
      padding-top: 0.9rem;
      overflow-x: hidden;
      position: relative;
      top: 0;
      left: 0;
    }

    .tab-label {
      background-color: $white;
    }

    .scale {
      margin-bottom: 1rem;

      .scale-unit {
        width: 2.5rem;
      }
    }
  }
  //Skin Tracker Navigation (next, back, skip, etc) in the component footer
  //the tracker footer needs to be taller than it normally is
  .tracker-footer {
    @include bp(small-down) {
      // height: 105px;
    }
  }

  .tracker-footer-items {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 1rem;

    @include bp(small-down) {
      flex-direction: column;
      height: 100%;
      margin: 0 3rem;
    }

    .button-wrapper {
      margin: auto;
    }

    .button {
      width: 100%;
      margin: 0 8px;
    }

    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 6px 0;
    }

    .button-wrapper-skin {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 6px 0;
    }

    @include bp(small-down) {
      .button-wrapper {
        // margin: 0.75rem auto;
        order: 0;
        width: 100%;

        button {
          width: 100%;
        }
      }

      //we're saying "the button wrapper above should come first (order: 0) and the rest of these buttons should come after (order: 1)"
      & > button {
        order: 1;
      }
    }
  }

  .radio {
    label {
      @extend .paragraph;
      padding-left: 0.5rem;
    }
  }
}
//End write state

.svg-skin__body-part {
  cursor: pointer;
}

.half-content-child {
  margin-bottom: 0 !important;
}
//The half with the content and process steps. On a grid.
.skin-content__grid {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'step-image title' '. instructions' 'infection-svg infection-title' '. progress' '. location' 'affected-checkbox affected-checkbox' '. list' '. form';
}

//These are the named areas for the grid:
//step-image, title, instructions, affected-checkbox, list, yes-no, location

//the number image next to the title
.skin-tracker-image-steps {
  grid-area: step-image;
}

//The title of the step they are on
.headers {
  // grid-area: title;
}

//The instructions
.help-text {
  grid-area: instructions;
}

.progress-bar-wrapper {
  grid-area: progress;
}

//The checkbox that asks about infected areas (step1)
.skin-tracker-checkbox__affected {
  grid-area: affected-checkbox;
  // grid-column: span 1 / 3;
}

.skin-data-dropdown {
  font-size: 12px;

  position: relative;

  .Dropdown-menu {
  }

  .Dropdown-control {
    padding: 6px 8px;

    .Dropdown-arrow {
      color: red;
    }
  }

  .Dropdown-option {
    padding: 6px 8px;
  }
}

//The text about the location of the skin
.skin-tracker__location {
  grid-area: location;
}
//The list with the scale tracker
.skin-tracker__list {
  grid-area: list;
}

//The yes/no question
.skin-tracker-form {
  grid-area: form;
}

.infections-svg {
  grid-area: infection-svg;
}

.infections-title {
  grid-area: infection-title;
}
////End of grid area naming///////

.skin-tracker-image-steps {
  display: inline;
  height: 1.25rem;
  margin-top: 0.125rem;
  width: auto;
}

//this checkbox area needs to be pushed to the bottom of this content half
.skin-tracker-checkbox__affected {
  border-top: 1px solid $gray2;
  @include bp(small-up) {
    margin-top: 5rem;
    padding-top: 0.75rem;
  }

  input {
    margin-left: 0.5rem;
  }
}

.skin-tracker__location {
  @include bp(small-down) {
    text-align: center;
  }
}

.skin-tracker__list {
  @include bp(small-down) {
    display: none;
  }

  margin-top: 0.25rem;

  li {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 0.125rem;

    //The progress dot colors are set in _skin-tracker.scss
    svg {
      height: 0.5rem;
      width: 0.5rem;
    }

    // "r" is the radius of the circle element inside the svg.
    .progress-dot__background {
      r: 6;
    }

    .progress-dot__inner {
      r: 4;
    }
  }

  .process__symptom {
    margin-left: 0.25rem;
  }

  .process__current {
    .process__symptom {
      font-weight: $proxima-semibold;
    }
  }
}

.skin-scale-wrapper {
  h4 {
    text-align: center;
  }
}

.skin-tracker-scale-wrapper {
  h4 {
    text-align: center;
  }
}

//styles specific to read view
.tracker-skin.tracker__inactive {
  h4 {
    margin-top: 0.25rem;
    padding-top: 0.25rem;
  }

  .tracker-body {
    grid-template-rows: 1fr 4.125rem;
    grid-template-areas: 'graphic-half graphic-half content-half content-half' 'notes notes notes notes';
    overflow: auto;
  }

  //Readview body-back is slightly gray
  .skin-tracker__half-graphic {
    .svg-skin-body__back-group {
      .svg-skin__body-part {
        &-selected {
          fill: lighten($secondary100, 25%);
        }
      }
    }
  }

  .half-content__container {
    @include bp(small-up) {
      border-left: 1px solid $gray2;
      height: 95%;
      padding-left: 1rem;
    }
  }

  .half-content-child:first-of-type {
    border-bottom: 1px solid $gray2;
  }

  .half-content-child {
    &__row {
      padding-top: 0.75rem;
      //the infections icon needs some spacing
      .flex-child:first-of-type {
        margin: 0 0.5rem 0 0;
      }
    }
  }

  .symptom-scale-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    @include bp(small-down) {
      margin: auto;
      width: 60%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }

    .symptom-scale-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;

      //override all that height stuff.
      .dragscale-wrapper {
        padding: 0;

        .dragscale-body {
          padding: 0;
          margin: 0;

          .scale {
            margin: 0;

            .scale-unit {
              //and set the height
              height: 0.875rem;
            }
          }
        }
      }
    }

    .symptom-scale-tracker-wrapper {
      padding-bottom: 1rem;
    }
  }

  .tracker-notes {
    padding: 0.75rem;
    border-radius: 8px;
  }
}
