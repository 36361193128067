.hcp-landing {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 10px 0 10px;

  @include bp(medium-down) {
    background-color: #f5f7fb;
  }

  h1,
  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.15;
    color: $primary100;

    @include bp(medium-down) {
      font-size: 30px;
      margin: 15px 0 20px;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    color: $black100;

    @include bp(medium-down) {
      font-size: 22px;
      margin: 10px 0 10px;
    }
  }
  h1 span,
  h2 span {
    color: $secondary100;
  }
  p {
    font-size: 1rem;
    line-height: 1.3;

    @include bp(medium-down) {
      font-size: 15px;
    }
  }
  a {
    color: $secondary100;
    font-weight: 600;
  }
  button {
    width: 212px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 46px;
    background-color: $secondary100;
    color: $white;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    border: none;
    gap: 10px;
    font-family: 'Lexend', 'sans-serif';
    transition: all 0.2s ease-in;

    &:hover {
      background-color: $primary100;
    }
  }

  > div {
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    justify-content: space-between;

    @include bp(medium-down) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .meet-eczemawise {
    gap: 1rem;

    @include bp(medium-down) {
      flex-direction: column-reverse;
    }

    .meet-info {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include bp(medium-down) {
        width: 100%;
      }
    }
    .meet-hero {
      flex: 4;
      padding: 1rem;

      @include bp(medium-down) {
        padding: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .eczemawise-features {
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;

    h2 {
      text-align: center;

      @include bp(medium-down) {
        width: 70%;
      }
    }

    .features {
      width: 90%;
      max-width: 1050px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1.2rem;

      @include bp(medium-down) {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }

      .feature {
        width: 49%;
        height: 58px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        border: 1px solid $primary10;
        padding: 8px;
        box-sizing: border-box;
        gap: 10px;

        @include bp(medium-down) {
          width: 100%;
          background-color: $white;
        }

        &.benefit {
          flex-direction: column;
          height: auto;
          gap: 15px;
          min-height: 150px;
        }

        .feature-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          width: 100%;
        }

        p {
          flex: 1;
        }

        img {
          width: 42px;
        }
      }
    }

    &.benefits {
      @include bp(medium-down) {
        margin-top: 0;
        background: $white;

        h2 {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .made-easy {
    gap: 2rem;

    .made-easy-info {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .stores-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 2rem;

        .store-link {
          cursor: pointer;

          img {
            width: 127px;
          }
        }
      }
    }
    .made-easy-hero {
      flex: 8;
      position: relative;

      @include bp(medium-down) {
        margin: 10rem 0 8rem;
      }

      .made-easy-bg {
        width: 100%;
        height: auto;
      }

      .phones {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        .phone {
          position: relative;
          width: 260px;
          height: auto;
          z-index: 2;

          &.phone-left {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: rotate(-2.5deg) translate(-112%, -55%) scale(1.3);
          }
          &.phone-right {
            position: absolute;
            right: 50%;
            top: 50%;
            transform: rotate(2.5deg) translate(112%, -55%) scale(1.3);
          }
        }

        .review {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 360px;
          height: auto;
          right: 60%;
          bottom: -40px;
          padding: 15px 10px;
          box-sizing: border-box;
          background-color: $white;
          border-radius: 12px;
          z-index: 5;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
          gap: 10px;
          opacity: 0;
          transform: translate(0, -100px);
          transition: all 0.7s ease-in-out;

          @include bp(medium-down) {
            width: 380px;
            max-width: 400px;
            left: 50%;
            margin-left: -190px;
            right: unset;
            bottom: -120px;
          }

          &.review-right {
            right: unset;
            left: 50%;
            bottom: unset;
            top: -120px;
            z-index: 0;
            transform: translate(0, 150px);

            @include bp(medium-down) {
              width: 380px;
              max-width: 400px;
              left: 50%;
              margin-left: -190px;
              right: unset;
              transform: translate(0, 0);
              top: -150px;
            }
          }

          &.show {
            opacity: 1;
            transform: translate(0, 0);
          }

          .review-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 10px;

            blockquote {
              margin: 0;
              padding: 0;
              font-size: 13px;
            }
          }

          .review-user {
            width: 100%;
            font-size: 12px;
            font-weight: 600;
            text-align: right;
            color: $secondary100;
          }
        }
      }
    }
  }

  .resource-hub {
    gap: 5rem;
    background-image: url(../../assets//images/hcp-landing/resource-hub-bg.svg);
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    background-color: $secondary10;
    margin-top: 60px;

    @include bp(medium-down) {
      gap: 2rem;
      background-position: bottom right;
    }

    .resource-hub-info {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 24px;
      }

      @include bp(medium-down) {
        button {
          width: 100%;
        }
      }
    }
    .resource-hub-hero {
      flex: 4;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 30px;
      margin-top: 30px;

      @include bp(medium-down) {
        margin-top: 0;
        justify-content: flex-end;
      }

      img {
        margin: 0;

        @include bp(medium-down) {
          height: 200px;
        }

        &.hero2 {
          height: 75%;

          @include bp(medium-down) {
            height: 100px;
          }
        }
      }
    }
  }

  .how-to-use {
    gap: 1rem;
    background: #f9fbff;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 20px;

    @include bp(medium-down) {
      flex-direction: column-reverse;

      &.developed {
        padding-top: 20px;
      }
    }

    .how-to-use-info {
      flex: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 80px;

      @include bp(medium-down) {
        padding-right: 0;
        gap: 0.8rem;
      }

      .help-link {
        text-decoration: underline;
      }
    }

    .how-to-use-hero {
      flex: 5;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .download-guide {
    gap: 2rem;
    background-color: $secondary10;
    padding-top: 20px;
    padding-bottom: 12px;

    &.access-data {
      padding-left: 0;
      padding-top: 50px;
      padding-bottom: 50px;
      background: url(../../assets/images/hcp-landing/access-data-bg.svg);
      background-size: 961px 1124px;
      background-color: $white;
      background-position: center left;
      background-repeat: no-repeat;

      @include bp(medium-down) {
        padding: 3rem 20px;
        background: $white;
      }
    }

    .download-guide-hero {
      flex: 4;
      margin-left: 10%;

      img {
        width: 100%;
      }
    }

    .download-guide-info {
      flex: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 15%;

      @include bp(medium-down) {
        padding-right: 0;

        button {
          width: 100%;
        }
      }

      p {
        margin-bottom: 2rem;
      }
    }
  }

  .who-developed {
    gap: 1rem;
    background: #f9fbff;
    padding-top: 50px;
    padding-bottom: 50px;

    .who-developed-info {
      flex: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 80px;

      .help-link {
        text-decoration: underline;
      }
    }

    .who-developed-hero {
      flex: 5;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.download-guide-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.download-guide-modal {
  width: 860px;
  max-width: 96%;
  height: 580px;
  max-height: 96%;
  background-color: $white;
  margin: 0;
  padding: 0;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  position: relative;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      color: $black70;
    }
  }

  .download-form {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -45px;
    gap: 20px;
    padding: 20px 30px;
    font-family: 'Lexend', sans-serif;

    @include bp(medium-down) {
      margin-right: 0;
      padding: 10px 20px;
    }

    h3 {
      width: 100%;
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;

      label {
        width: 100%;
        font-size: 14px;

        input[type='checkbox'] {
          margin-right: 10px;
          width: 16px;
          height: 16px;
        }
        span {
          color: $feedbackAlert;
        }
        a {
          color: $secondary100;
          font-weight: 600;
        }
      }

      .input-field {
        width: 100%;
        height: 40px;
        border: 1px solid $black25;
        border-radius: 8px;
        padding: 0 10px;
        font-size: 14px;
        color: $black100;
        line-height: 40px;
        background-color: $white;
        font-family: 'Lexend', sans-serif;
        font-weight: 600;
      }
    }

    a.download {
      width: 100%;
    }
    button {
      width: 100%;
      height: 46px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      line-height: 46px;
      background-color: $secondary100;
      color: $white;
      border-radius: 8px;
      font-weight: 600;
      font-size: 16px;
      border: none;
      gap: 10px;
      font-family: 'Lexend', 'sans-serif';
      transition: all 0.2s ease-in;

      &:hover {
        background-color: $primary100;
      }

      &:disabled {
        opacity: 0.8;
        cursor: default;
        background-color: $secondary100;
      }
    }

    .error-message {
      width: 100%;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: $severitySevere;
      background: rgba(234, 67, 53, 0.1);
      gap: 10px;

      span {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  .modal-hero {
    flex: 1;
    height: 100%;
    background: url(../../assets/images/hcp-landing/download-modal-hero.svg)
      bottom center no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include bp(medium-down) {
      display: none;
    }

    .modal-hero-1 {
      margin-left: 80px;
      height: 75%;
    }

    .modal-hero-2 {
      height: 20%;
      margin-left: -120px;
    }
  }
}
