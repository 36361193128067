//Proxima Nova fonts are loaded from TypeKit. They are loaded through <Helmet> at client/Main.js
//Font weights loaded are: Thin, Regular, Semibold, Bold, and their Italic counterparts.
//Font changes will need to be made on the typekit at typekit.com

.font-family {
  //Fallback if the fonts won't load.
  font-family: 'Lexend', 'sans-serif';
  font-weight: 400;
}

body {
  @extend .font-family;
}
