.date-bar {
  &__provider-prep {
    height: 40px;
    border-bottom: 4px solid $gray100;
    box-sizing: content-box;
  }
}

.provider-prep-container {
  background-color: $white;
  min-height: 100vh;

  header {
    .header-buttons {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 2px 1rem;
      height: 100%;
      gap: 1rem;
    }
  }

  .manage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .manage-content {
      width: 90%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      padding-bottom: 1rem;

      .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        textarea.notes {
          width: 100%;
          height: 85px;
          border: 1px solid $black25;
          border-radius: 8px;
          padding: 10px;
          font-size: 14px;
          color: $black100;
          font-family: Lexend;

          &:focus {
            outline: none;
          }
        }
      }

      .manage-content-loading {
        width: 100%;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem;
        color: $gray100;
      }
    }

    .tracker-footer {
      width: 100%;
      button {
        width: 160px !important;
      }
    }
  }

  .tabs {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #d8dadd;
    margin-top: 0.5em;

    .tab {
      flex: 1;
      height: 100%;
      line-height: 48px;
      text-align: center;
      border: none;
      background-color: transparent;
      border-bottom: 3px solid transparent;
      font-weight: 500;
      cursor: pointer;

      @include bp(small-down) {
        font-size: 16px;
      }

      &.active {
        font-weight: 700;
        color: $secondary100;
        border-bottom-color: $secondary100;
      }
    }
  }

  .manage-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $gray100;
    border-radius: 4px;
    padding: 0.75rem;
    gap: 1rem;
    transition: all 0.3s ease-in;

    &.card-filters {
      overflow: visible;

      .card-content {
        .filter-list-container {
          border-bottom: none;
          margin: 0;
          padding: 0;
          gap: 1rem;

          .filter-list-remove {
            margin-left: 1rem;
          }
        }
      }
    }

    &.descriptions {
      padding-top: 0.3rem;
      padding-bottom: 0.7rem;
      gap: 0.2rem;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 0.5rem 0;

      @include bp(small-down) {
        font-size: 16px;
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      word-break: break-all;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
    a.card-external-link {
      color: $secondary100;
      &:hover {
        text-decoration: underline;
      }
    }

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      &__center {
        text-align: center;
      }

      &.header-with-tooltip {
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        h3 {
          width: auto;
        }
      }

      &.bordered {
        padding-bottom: 0.75rem;
        border-bottom: 1px solid $secondary10;
      }

      .card-header-title {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }

      .card-header-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
      }

      &.card-header-filters {
        overflow: visible;

        .card-header-buttons {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }

        .gallery-day-picker {
          width: 180px;
        }

        @include bp(small-down) {
          flex-direction: column;
          flex-wrap: wrap;

          .card-header-buttons {
            width: 100%;
            gap: 6px;
          }

          .card-header-title {
            width: 100%;
            flex: 1;
          }

          .gallery-day-picker {
            font-size: 0.85rem;
            flex: 1;
          }
        }
      }

      &.card-header-selected-photos {
        .link-button {
          padding: 0 10px;
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 16px;
      color: $black100;

      &.card-content-treatments {
        overflow: scroll;
      }

      p {
        font-size: 16px;
        margin: 0;
      }
      ol {
        margin: 0 0 0.5rem;
        padding-left: 1rem;
        font-size: 15px;

        @include bp(small-down) {
          padding-left: 1.5rem;
        }

        li {
          margin: 0.2rem 0 0.4rem;
          font-weight: 400;
        }
      }
      label {
        font-size: 16px;
        font-weight: 600;
        margin-top: 0.75rem;
      }
      &.overflow {
        overflow: visible;
      }
    }

    &.collapsible {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .card-content {
        display: none;
      }

      .card-header {
        cursor: pointer;
      }

      &.active {
        flex: 1;

        .card-header {
          .card-header-button {
            transform: rotate(180deg);
          }
        }

        .card-content {
          display: flex;
        }
      }
    }

    &.card-appointment {
      .card-header {
        border-bottom: 1px solid $gray50;
        padding-bottom: 0.5rem;

        .header-info {
          display: flex;
          flex: 1;
          flex-direction: column;
          gap: 0.5rem;

          .header-info-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 1rem;
            align-items: center;
          }
        }

        .editButton {
          display: flex;
          width: 26px;
          height: 26px;
          min-width: 26px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: $secondary100;
          background-color: $primary10;
        }
      }

      .card-content {
        a {
          display: flex;
          flex: 1;
          margin-top: 1rem;
        }
        button {
          flex: 1;
          border: none;
          height: 46px;
          border-radius: 8px;

          &.button__secondary100-inverted {
            border: 1px solid $secondary100;
            font-weight: 600;
            font-family: Lexend;
          }

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    &.overview {
      .card-content {
        overflow: hidden;
      }
    }
  }

  .appointmentPrepStep {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  .form {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.appointment-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
  gap: 0.4rem;
  border-radius: 4px;
  padding: 0 0.6rem;
  color: $white;
  background-color: $tertiary100;
  min-width: 125px;

  p {
    height: auto;
    font-size: 12px;
    line-height: 12px;
  }
}

.preparation-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: $secondary10;

  &.later {
    background: $primary10;
  }

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $secondary100;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  p {
    font-size: 14px;
    width: 90%;
    max-width: 350px;
    text-align: center;
  }
}

.progressHeader {
  display: block;
  width: 100%;
  border-bottom: 1px solid #c0cde5;
  background: white;

  &.sticky {
    position: fixed;
    top: 0;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .progressContainer {
    width: 90%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .progressTitle {
      font-size: 15px;
      font-weight: 600;
      color: black;
      line-height: 40px;
      text-transform: uppercase;

      &.create-title {
        font-size: 18px;
        text-transform: none;
        padding-left: 15px;
      }
    }

    .progressBars {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;

      .progressBar {
        flex: 1;
        height: 5px;
        background: #c6d8fb;
        border-radius: 2px;

        &.active {
          background: $secondary100;
        }
      }
    }
  }
}

.multiSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 1rem;

  .multiSelect__button {
    width: 49%;
    height: 48px;
    line-height: 48px;
    border: 1px solid #edf1f6;
    border-radius: 8px;
    color: $black;
    background: $white;
    font-size: 14px;
    padding: 0 15px;
    text-align: left;
    font-family: Lexend;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include bp(small-down) {
      width: 100%;
    }

    &.selected {
      background-color: $secondary10;
      color: $secondary100;
    }
  }
}

.footer-buttons-bar {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 4px solid $gray100;
  box-sizing: content-box;

  .buttons-container {
    width: 90%;
    height: 40px;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    @include bp(small-down) {
      gap: 0.5rem;
    }

    .button-rectangle-skin-footer {
      height: 40px;
      width: 190px !important;

      @include bp(small-down) {
        width: 160px !important;
      }
    }
  }
}

.thinkBigPicture {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  .dragscale-wrapper {
    width: 90%;
    max-width: 500px;

    .dragscale-body {
      width: 100%;

      .dragscale-tab {
        justify-content: space-between;
        margin-top: 1rem;
      }

      .scale {
        .scale-unit {
          flex: 1;
        }
        .scale-unit.button {
          @include bp(small-down) {
            width: 1.5rem !important;
          }
        }
      }
    }
  }
}

.treatments {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  @include bp(small-down) {
    height: 100%;
  }

  .treatment-options {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .search-bar {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0.5rem;
      border: 1px solid $gray100;
      border-radius: 5px;

      label {
        display: flex;
        width: 20px;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .search-icon {
        color: $black70;
      }

      .search-input {
        flex: 1;
        height: 36px;
        border: none;
        outline: none;
        padding: 0 0.5rem;
        font-size: 13px;
        line-height: 36px;
        margin: 0;
        color: $black100;
      }
    }
  }

  .treatment-list {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;

    .treatment-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      .treatment-item-title {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        font-size: 13px;
        cursor: pointer;

        p {
          color: $black100;
          font-size: 13px;

          span {
            &.subtitle {
              color: $black70 !important;
            }
          }
        }
      }

      &.active {
        .treatment-item-title {
          font-weight: 600;
        }
      }

      .treatment-add-button {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: $secondary10;
        color: $secondary100;

        &.active {
          background: $secondary100;
          color: $secondary10;
        }
      }
    }
  }

  .treatment-group {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;

    .group-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .group-header-title {
        display: flex;
        flex: 1;
        height: 24px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        h4 {
          width: auto;
        }
      }

      .group-header-button {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .treatment-list {
      display: none;
    }

    &.active {
      .group-header-button {
        transform: rotate(180deg);
      }
      .treatment-list {
        display: flex;
        overflow: scroll;
      }
    }
  }

  .treatment-details {
    flex: 1;
    height: 100%;

    .close-button {
      display: none;
    }

    @include bp(small-down) {
      display: none;

      &.open {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        .close-button {
          display: block;
        }
      }
    }

    .card {
      height: 100%;
    }

    .treatments-placeholder {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .placeholder-icon {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: $secondary100;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 32px;
          height: 32px;
          g,
          path {
            fill: $white;
          }
        }
      }
    }

    .treatment-info {
      gap: 0.3rem;
      overflow: scroll;

      label,
      h4 {
        font-size: 14px;
      }
      p {
        font-size: 13px;
      }

      hr {
        width: 100%;
        border: none;
        border-bottom: 1px solid $gray100;
      }

      .info-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
        gap: 0.5rem;

        .info-item-title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
}

.question-button {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $gray25;
  color: $secondary100;
}

.price-badge {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  height: 24px;
  border-radius: 12px;
  background: $tertiary100;
  color: $white;
  font-size: 14px;
}

.filtersOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .filtersForm {
    position: absolute;
    width: 400px;
    max-width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: $white;
    padding: 10px 15px;
  }

  .filtersTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $black25;
    padding-right: 10px;
  }

  .filtersContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;

    .separator {
      height: 0;
      width: 100%;
      margin: 10px 0;
      border-bottom: 1px solid $black25;
    }

    .skin-areas-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 16rem;
      @include bp(small-down) {
        height: 12.6rem;
      }

      .svg-skin-body {
        height: 100%;
        margin-bottom: 0.625rem;
        width: 100%;

        g {
          stroke-width: 1;
        }

        .svg-skin__body-part {
          fill: $white;
          stroke: $secondary100;
          stroke-width: 2;

          &-selected {
            fill: $secondary50;
          }

          &:hover {
            fill: $secondary50;
          }
        }
      }
    }
  }
}

.optionButton {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  cursor: pointer;

  label {
    font-size: 15px;
  }

  .radioBtn {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="11.5" stroke="%236B7280"/></svg>');
    background-position: center center;
    background-size: contain;
  }
  .checkboxBtn {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="%23BAC1CF"/></svg>');
    background-position: center center;
    background-size: contain;
  }

  &.active {
    label {
      font-weight: 600;
    }
    .radioBtn {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="11.5" stroke="%23E95D42"/><circle cx="12" cy="12" r="6" fill="%23E95D42"/></svg>');
    }
    .checkboxBtn {
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="4" fill="%23E95D42"/><path d="M15.9141 9.20581C15.8599 9.15114 15.7954 9.10774 15.7243 9.07813C15.6532 9.04851 15.5769 9.03326 15.4999 9.03326C15.4229 9.03326 15.3467 9.04851 15.2756 9.07813C15.2045 9.10774 15.14 9.15114 15.0858 9.20581L10.7399 13.5575L8.9141 11.7258C8.85779 11.6714 8.79133 11.6287 8.71849 11.6C8.64566 11.5713 8.56789 11.5572 8.48962 11.5585C8.41135 11.5599 8.33411 11.5766 8.26231 11.6078C8.19052 11.6391 8.12557 11.6841 8.07118 11.7404C8.01679 11.7967 7.97402 11.8632 7.94532 11.936C7.91662 12.0088 7.90254 12.0866 7.9039 12.1649C7.90525 12.2431 7.92201 12.3204 7.95321 12.3922C7.98442 12.464 8.02946 12.5289 8.08576 12.5833L10.3258 14.8233C10.38 14.878 10.4445 14.9214 10.5156 14.951C10.5867 14.9806 10.6629 14.9959 10.7399 14.9959C10.8169 14.9959 10.8932 14.9806 10.9643 14.951C11.0354 14.9214 11.0999 14.878 11.1541 14.8233L15.9141 10.0633C15.9733 10.0087 16.0206 9.94239 16.0529 9.8686C16.0852 9.79481 16.1019 9.71512 16.1019 9.63456C16.1019 9.554 16.0852 9.47432 16.0529 9.40053C16.0206 9.32673 15.9733 9.26044 15.9141 9.20581Z" fill="white"/></svg>');
    }
  }
}

.galleryFilterButton {
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: $black70;

  .filterCount {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $tertiary100;
    color: $white;
    text-align: center;
    line-height: 16px;
    font-size: 11px;
    font-weight: 600;
  }
}

.filterAreasAffectedContainer {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonUploadPhoto {
  width: 150px !important;
  height: 40px;

  @include bp(small-down) {
    width: auto !important;
    padding: 0 6px;
  }
}

.noEczemaGallery {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $secondary100;
    justify-content: center;
    align-items: center;
  }

  h3 {
    width: 40%;
    text-align: center;
  }
}

.galleryBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 15px;

  @include bp(small-down) {
    margin: 10px 0;
  }

  .galleryTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 15px;

    @include bp(small-down) {
      font-size: 14px;
    }

    strong {
      font-weight: 600;
    }

    .intensityDot {
      width: 10px;
      height: 10px;
      border-radius: 3px;
      background-color: #e95d42;

      &.mild {
        background-color: #09b0e8;
      }
    }
  }
}

.galleryImages {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0 30px;
  width: 100%;
  flex-wrap: wrap;

  @include bp(small-down) {
    margin: 10px 0 20px;
  }

  &.readOnly {
    margin: 0.5rem 0;
  }

  .galleryItem {
    width: 100px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include bp(small-down) {
      width: 90px;
    }

    .galleryImage {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      position: relative;
      cursor: pointer;
      background-color: $primary10;

      @include bp(small-down) {
        height: 90px;
      }

      .check {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #edf1f6;
        border: 1px solid $black100;
        top: 5px;
        left: 5px;
        color: $white;

        svg {
          display: none;
        }
      }

      .favorite {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        color: $white;

        svg {
          display: none;
        }
      }
    }

    &.checked {
      .galleryImage {
        .check {
          background-color: $tertiary100;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            display: block;
          }
        }
      }
    }

    &.favorite {
      .favorite {
        svg {
          display: block;
        }
      }
    }

    span {
      text-align: left;
      font-size: 13px;

      &.summary {
        font-size: 12px;
      }
      &.date {
        font-size: 11px;
      }
    }
  }
}

.indications {
  width: 350px;
  background-color: $primary10;
  border-radius: 5px;
  padding: 12px 10px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;

  span {
    padding-right: 8px;
    padding-left: 8px;
  }
  strong {
    margin-left: 10px;
  }
}

.overview-results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 0;

  .overview-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .result-number {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid $gray100;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 600;
        color: $primary100;
      }
    }

    p {
      flex: 1;
      text-align: left;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.link-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  color: $secondary100;
  cursor: pointer;
  padding: 0.5rem 0;
  border: none;
  background: none;
}

.provider-options {
  display: flex;
  flex-direction: column;
  margin: 0;

  .provider-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #0e1217;
    gap: 10px;
    position: relative;

    .provider-option__content {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 10px 0;
      cursor: pointer;
    }

    .provider-option__circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #6b7280;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in 0.2s;
    }

    &.checked {
      .provider-option__circle {
        border-color: #e95d42;

        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #e95d42;
        }
      }
    }
    &:hover {
      .provider-option__circle {
        border-color: #e95d42;
      }
    }

    .provider-option__title {
      font-size: 16px;
      font-weight: 600;
      word-break: break-all;
    }
    .provider-option__subtitle {
      font-size: 15px;
      font-weight: 400;
      flex: 1;
      word-break: break-all;
    }
    .provider-option__edit {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #e7eaf1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &.add,
  &.edit,
  &.add-address {
    .provider-option {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.provider-add-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  cursor: pointer;

  .provider-add-button__icon {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: #1d62ed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .provider-add-button__text {
    font-size: 15px;
    font-weight: 600;
    color: #0e1217;
  }
}

.provider-edition-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .provider-edition-spacer {
    flex: 1;
  }

  .provider-edition-button {
    font-size: 14px;
    font-weight: 600;
    color: #1d62ed;
    padding: 10px 20px;
    cursor: pointer;

    &.remove {
      color: #575a5d;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.form-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2%;
  flex-wrap: wrap;

  .form-item {
    width: 32%;
    gap: 0.3rem;

    @include bp(small-down) {
      width: 100%;
    }

    label {
      font-size: 14px;
    }

    &.checkbox {
      margin-top: -10px;
    }

    .form-select__single-value {
      font-size: 14px;
    }

    .field__required {
      color: $tertiary100;
      font-size: 14px;
    }

    input.form-input-field {
      width: 100%;
      &::placeholder {
        color: $black100;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
    }

    span.error-message {
      color: $feedbackAlert;
      font-size: 0.7em;
    }

    .form-select__control {
      width: 100%;
      border-radius: 8px;
      height: 40px;
      border-color: #d5d5d6;
    }
    .form-select__placeholder {
      font-size: 14px;
    }
  }
}

.help-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $secondary10;
  cursor: pointer;
}
.help-tooltip,
.help-tooltip-right {
  max-width: 340px;
  font-family: 'Lexend', sans-serif;
  border-radius: 10px !important;
  color: $black100;
  padding: 15px !important;
  opacity: 1 !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);

  h4,
  p {
    margin: 10px 0;
    padding: 0;
    line-height: 1.3;
    font-size: 14px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    margin: 5px 0 20px;
    border-bottom: 1px solid $primary10;
  }
  p {
    font-size: 14px;
  }
}
.help-tooltip-right::after {
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  left: -12px !important;
  top: 50%;
  margin-top: -58px !important;
  border-right-color: #fff !important;
  border-right-style: solid;
  border-right-width: 12px !important;
}

.treatment_pricing_scales {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .pricing_scale {
      width: 25%;
      line-height: 20px;
      text-align: center;
      border-radius: 10px;
      font-size: 1.1em;
      margin-right: 5px;
      color: $white;
      background: $tertiary100;
    }

    .pricing_scale_text {
      padding-left: 5px;
      font-size: 1.1em;
      color: $black100;
      font-weight: 600;
      width: 75%;
    }
  }
}
