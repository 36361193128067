//SDM colors
//So many grays
$gray: #f8f8f9; //platform background
$gray0: #E3E5EA; //platform new background
$gray1: #f2f2f4;
$gray2: #e6e6e8;
$gray3: #d9d9dd; //disabled text; disabled input field; disabled button text, +, x
$gray4: #cdcdd2;
$gray5: #c0c0c6;
$gray6: #b4b3bb;
$gray7: #a7a7b0; //normal input field
$gray8: #9b9aa4; //light text; normal
$gray9: #8e8e99;
$gray10: #82818d; //link text; selected input field; input cursor
$gray11: #757582;
$gray12: #696877;
$gray13: #5c5b6b; //button text, not clicked
$gray14: #504f60;
$gray15: #434155; //regular text; button text, clicked
$gray16: #373649;
$gray17: #4b4a56;
$gray18: #2d2926;
$gray19: #F5F5F5;
$gray20: #6F7D7D;


//Main Palette
$cobalt: #233a74; // Theme update
$cobalt-dark: #092A6D;
$cobalt-RGB: rgb(111, 108, 255);
$navy: #233a74; //Sleep Tracker
$navy-RGB: rgb(0, 20, 47); //NEA navy

//Secondary Palette
$blue: #0098db; //Treatment Tracker
$blue-dark: #00A1D9; // 
$midnight-blue: #2A293E;
$green-dark: #739600; //Stress Tracker
$magenta: #bb2575; //Trigger Tracker
$orange: #ff7900; //Diet Tracker
$tangerine: #ffb612; //Itch Tracker
$teal: #41ccaa; //Pain Tracker

//Tertiary Palette
$blue-light: #90d5ee;
$gold: #ffd459;
$green-light: #c4d539;
$teal-dark: #008486; //Trigger Tracker
$orange-dark: #e24f03;
$peach: #fd9b91;

//Utility Palette
$green: #97cc44; //Save button
$red: #e23b5b;
$yellow: #ffe757;
$cobalt-active: #304c89;
$green-active: #88b83d;
$teal: #428C74;
$steel-blue: #475f91;
$lime-green: #AFD100;
$midnight-blue: #233B74;
$skin: #61c9a8;


// New branding colors palette

//Primary
$primary100: #092A6D;
$primary50: #8394B5;
$primary10: #E7EAF1;
//Secondary
$secondary100: #1D62ED;
$secondary50: #8DB0F5;
$secondary10: #E9F0FE;
//Tertiary
$tertiary100:#E95D42;
//Neutral
$black100:#0E1217;
$black70:#575A5D;
$black50:#AAACAD;
$black25:#D5D5D6;
$whiteNew:#FFF;
//Severity
$severityMild:#FF9672;
$severityModerate:#E95D42;
$severitySevere: #B33D26;
//Trackers
$trackerItch:#FF9672;
$trackerPain:#E95D42;
$trackerStress:#B33D26;
$trackerSleep:#F8BED6;
$trackerSkin:#1D62ED;
//Feedback
$feedbackAlert:#EA4335;
$feedbackSuccess:#34A853;
//Gray?
$gray100: #C0CDE5;
$gray50: #C0CDE580;
$gray25: #C0CDE540;
$darkGray100: #1A222B;

//A handy color map, used for theming, font-colors
$color-map: (
  blue: $blue,
  blue-dark: $blue-dark,
  blue-light: $blue-light,
  midnight-blue: $midnight-blue,
  gold: $gold,
  green: $green,
  green-dark: $green-dark,
  green-light: $green-light,
  magenta: $magenta,
  navy: $navy,
  cobalt: $cobalt,
  cobalt-dark: $cobalt-dark,
  orange: $orange,
  orange-dark: $orange-dark,
  peach: $peach,
  red: $red,
  tangerine: $tangerine,
  teal: $teal,
  teal-dark: $teal-dark,
  violet: $cobalt,
  violet-active: $cobalt-active,
  yellow: $yellow,
  neutral-black: #2b2840,
  weather: #2b5ac1,
  diet: #dd5a3c,
  itch: #FF9672,
  pain: #E95D42,
  sleep: #F8BED6,
  skin: $secondary100,
  stress: #B33D26,
  treatment: #90d5ee,
  trigger: #976c9e,
  black100: $black100,
  primary100: $primary100,
  secondary100: $secondary100,
  white: $whiteNew,
);

//Typography
$black: $gray15;
$white: #ffffff;

//Shades of cobalt
$cobalt90: #304c89; //hover
$cobalt80: #465f96; //clicked
$cobalt70: #5c72a3;
$cobalt60: #7285b0;
$cobalt50: #8898bd;
$cobalt40: #9eabca;
$cobalt30: #b4bed7;
$cobalt20: #cad1e4;
$cobalt10: #f7f7fe;

// //Shades of violet
// $cobalt80: #8285f2; //selected
// $cobalt80: #a1a3f5; //hover
// $cobalt40: #c1c2f9; //clicked
// $cobalt20: #e0e0fc;
// $cobalt10: #f7f7fe;

//Alerts
$red-alert-background: #fdf5f7;
$green-alert-background: #fafcf5;

//Gradients
//The violet gradient used on registration/password reset screens
@mixin radial-gradient($from, $to) {
  background: radial-gradient(circle, $from 0%, $to 130%);
  background-color: $cobalt;
}

.violet-gradient {
  @include radial-gradient($cobalt-RGB, $navy-RGB);
}

