.survey-dashboard-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background-color: #f5f7fb;

  .survey-sub-header {
    color: #bbb;
    font-size: 14px;
    padding: 8px 24px;
  }

  .button__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .date-bar__intro {
      color: $black100;
      font-family: Lexend;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .sidebar-title {
    .date-bar__intro {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
      text-transform: capitalize;

      .material-icons {
        margin-right: 8px;
      }
    }
    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .surveys {
    width: 70%;
    margin: 0 auto;

    .no-surveys {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #504f60;
      max-width: 80%;
      margin: auto;
      text-align: center;
      border-radius: 4px;
      border: 1px solid $black25;
      height: 186px;

      .title {
        color: $black100;
        text-align: center;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .sub-title {
        color: $black100;
        text-align: center;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      svg {
        display: inline;
        fill: $gray2;
        max-width: 136px;
      }
    }

    .survey-count {
      margin: 24px;
      padding: 16px 12px;
      text-align: right;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 15px;
      font-weight: 600;
      color: #888;
    }

    .survey-card {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin: 16px 24px;
      padding: 16px 12px;
      position: relative;
      border-radius: 4px;
      border: 1px solid var(--Brand-gray-50, rgba(192, 205, 229, 0.5));
      background: var(--Brand-white, #fff);

      &.completed {
        opacity: 0.4;
        cursor: initial;
      }

      &.completed::before {
        content: "check";
        color: $feedbackSuccess;
        font-weight: 600;
      }

      h1,
      h4 {
        padding: 0;
        margin: 4px 8px;
      }

      h1 {
        color: $secondary100;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      h4 {
        color: $black100;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      h5 {
        margin: 12px 8px 0 7px;
        color: var(--Neutral-gray, #6b7280);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  iframe {
    flex: 1;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }
}
