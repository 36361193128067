.counter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.incdec-buttons {
    cursor: pointer;
    border: none;
    background: none;
    flex-shrink: 0;
    color: $white;
    text-align: center;
    width: 28px;
    height: 28px;
}

.counter-label {
    color: $black100;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
