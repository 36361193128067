.main {
  // padding-top: 88px;
  &-dashboard {
    height: 100%;
    display: grid;
    height: 100%;
    @include bp(small-up) {
      //screen sizes 768 and up. The design shows a fixed size sidebar on the left.
      // grid-template-columns: 12.5rem 1fr;
    }
    @include bp(small-down) {
      //screen sizes less 767 and down. The design shows a fixed size sidebar big enough for icons.
      // grid-template-columns: 3.125rem 1fr;
    }
  }
}
