.poscorad-history {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .center {
    text-align: center;
  }

  @include bp(medium) {
    flex-wrap: nowrap;
  }

  .poscorad-item {
    margin-bottom: 10px;  
    width: 49.5%;
    padding: .5rem 1rem;
    background: $gray25;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &-title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: $black100;
      padding: 0;
      margin: 0;
    }
    &-subtitle {
      text-align: center;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $black100;
      padding: 0;
      margin: 0;
    }
    &-number {
      font-size: 24px;
      font-weight: 700;
      color: $black100;
      padding: 0;
      margin: 0 0 0.4rem;
    }
    &-severity {
      color: $black100;
      font-weight: 600;
      font-size: 18px;

      &::before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          border-radius: 3px;
          background: $severityMild;
          margin: 0.5rem 0.5rem 0 0;
          vertical-align: sub;
      }
      &.Mild::before {
        background: $severityMild;
      }
      &.Moderate::before {
        background: $severityModerate;
      }
      &.Severe::before {
        background: $severitySevere;
      }
      &.Mild,
      &.Moderate,
      &.Severe {
        color: #2d2926;
      }
      &.NA::before {
        background: none;
        width: 0px;
        height: 0px;
      }
    }

    &-scale {
      width: 100%;
      margin-top: 0.5rem;
    }
  }

  .poscorad-scales {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0.2rem 0 0;
    padding: 0;

    &-item {
      width: 25%;
      height: 12px;
      margin: 1px;
      background: $severityMild;

      &.Moderate {
        background: $severityModerate;
      }
      &.Severe {
        background: $severitySevere;
      }
    }
  }

  .poscorad-scales-indicator {
    width: 100%;
    position: absolute;
  }

  .poscorad-scales-indicator::before {
    position: absolute;
    bottom: 15px;
  }

  .poscorad-labels {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0;

    span {
      width: 3%;
      font-size: 12px;
      color: $black100;
      font-weight: 600;
      text-align: left;
      line-height: 15px;

      &.number-center {
        text-align: center;
        width: 5%;
      }
      &.number-right {
        text-align: right;
        width: 3%;
      }

      &.severity {
        width: 20%;
        color: $darkGray100;
        text-align: center;

        &.Moderate {
          width: 20%;
        }
        &.Severe {
          width: 45%;
        }
      }
    }
  }
}
