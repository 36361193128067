.incrementor-wrapper {
  text-align: center;
  width: 200px;

  .incrementor-grid {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 8px;
  }

  .incrementor__minus {
    justify-self: right;
  }

  .incrementor__add {
    justify-self: left;
  }

  .button {
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .incrementor-value {
    border: 1px solid $gray3;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    margin: auto;
  }
}
