.trends-filters {
  .filter-instructions {
    display: block;
    padding: 14px 18px 0 16px;
    @include bp("small-down") {
      display: none;
    }
  }

  .filter-instructions-text {
    color: $black100;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    text-align: left;
  }

  .filter-toggle-list {
    list-style: none;
    margin: 0;
    padding: 12px 0 0;
  }

  .filter-item {
    align-items: center;
    color: #434255;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    padding: 4px 16px 8px 12px;

    &:last-child {
      padding-bottom: 12px;
    }

    svg {
      // margin-right: 5px;
    }

    @include bp("small-down") {
      padding: 0 0 8px;
      justify-content: center;

      span.headers {
        display: none;
      }

      svg {
        margin-right: 0;
      }
    }

    &.__disabled {
      circle {
        fill: $gray1;
      }

      .tracker-icon-image {
        fill: $gray4;
      }
    }
  }
}
