//Styles for the Weather Tracker, also known as Conditions Tracker
//
.tracker-weather__active {
  .svg-open-close__open {
    display: none;
  }
}

.tracker-weather__inactive {
  .svg-open-close__close {
    display: none;
  }
}

//All icons (except AccuWeather) have a nice navy color

.tracker-weather .icon {
  width: 1rem;
  height: 1rem;

  .svg-group {
    fill: $navy;
  }
}

.tracker-weather,
.tracker__loading.tracker-WeatherTracker {
  @include bp(large) {
    grid-column: span 3;
  }
  @include bp(larger) {
    grid-column: span 5;
  }
  grid-column: span 3;
  height: auto;
  order: -1;

  .tracker-header {
    display: flex;
    align-items: center;
    height: 2.4rem;
    justify-content: space-between;

    .header-item-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .subtext {
        padding: 0;
        margin: 0;
        padding-left: 0.14rem;

        @include bp(small-down) {
          .weather-location__label {
            display: none;
          }
        }
      }
    }

    &__buttons {
      display: flex;

      .svg-open-close {
        height: 1.25rem;
        width: 1.25rem;

        &__background {
          fill: $gray3;
        }

        &__close,
        &__open {
          stroke: $white;
        }
      }
    }
  }

  .tracker-body {
    //on small screens, flexbox is used for layout
    //on screens tablet and up, grids are used for layout
    border-top: 1px solid $gray3;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    @include bp(small-up) {
      display: grid;
      grid-template-columns: 1fr 5% 2px 5% 1fr;
      grid-template-rows: auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include bp(large) {
      display: grid;
      grid-column-gap: 0.5rem;
      grid-template-columns: 1.21fr 2px 0.93fr 0.93fr 0.93fr;
    }

    .weather-item-wrapper {
      align-items: center;
      border-bottom: 0.5px solid $gray2;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0.5rem 0;

      .weather-item {
        flex-basis: 50%;
        display: flex;
        align-items: center;

        .subtext {
          align-self: baseline;
          margin: 0;
          padding: 0.188rem 0 0 0.25rem;
        }

        &-data {
          color: $navy;
          font-weight: $proxima-semibold;
          text-transform: capitalize;

          //Font sizes for the temperature degree numbers change depending on screen size
          &__temp-hi {
            font-size: 1.875rem;
            @include bp(small-up) {
              font-size: 2rem;
            }
          }

          &__temp-lo {
            font-size: 1.125rem;
          }
        }
      }
    }

    .weather-item-wrapper__temp {
      @include bp(large-down) {
        align-items: flex-start;
        justify-content: flex-start;
      }

      @include bp(large) {
        h6 {
          font-size: 0.675rem;
          font-weight: $proxima-regular;
        }
      }

      h6 {
        margin: 0;
      }
    }

    .weather-item-wrapper__humidity {
      @include bp(small-down) {
        border-bottom: 1px solid $gray3;
      }
    }

    //the line vertical svg used to seperate sections, hidden on smaller devices
    .svg-vertical-line {
      @include bp(small-down) {
        display: none;
      }
      @include bp(large) {
        grid-column: 2;
        grid-row: 1 / 3;
      }
      background-color: $gray2;
      grid-column: 3;
      grid-row: 1 / 4;
      height: 4rem;
      margin-top: 0.5rem;
      width: 1px;
    }
    //Grid areas and names
    .weather-item-wrapper {
      &__city {
        border-bottom: none;
        @include bp(large-down) {
          display: none;
        }
        @include bp(large) {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          grid-column: 1;
          grid-row: span 2;
        }

        h3 {
          margin: 0;
        }

        .weather-item-data {
          .subtext {
            display: flex;
            flex-direction: row;

            svg {
              margin-left: 0.25rem;
            }
          }
        }

        .icon-full-arrow {
          height: 0.75rem;
          width: 0.75rem;

          .svg-stroke {
            stroke: $gray3;
          }
        }
      }

      &__temp {
        grid-column: 1;
        grid-row: 1 / 3;

        //Align Hi and Lo temp numbers
        .weather-item__even {
          align-items: baseline;
        }

        .icon {
          @include bp(small) {
            margin: 0;
            height: 1.5rem;
            width: 1.5rem;
          }
          @include bp(large) {
            margin-top: 0.5rem;
            height: 1.125rem;
            width: 1.125rem;
          }
        }

        .item-wrapper {
          align-self: center;

          h4.subtext {
            padding-left: 0;
          }
        }

        @include bp(small-down) {
          .item-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;

            .weather-item__even {
              flex-basis: 54%;
              justify-content: center;
            }

            .weather-item__odd {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
            }
          }
        }
        @include bp(small-up) {
          align-items: flex-start;
          display: flex;
          flex-direction: row;

          .weather-item__odd {
            display: flex;

            .temp__subtext {
              // margin-right: 0.375rem;
            }
          }
        }
        @include bp(large) {
          border-bottom: none;
          grid-column: 3;
          grid-row: 1 / 3;
        }
      }

      &__humidity {
        @include bp(large) {
          grid-column: 4;
          grid-row: 1;
        }
        @include bp(large-down) {
          border: none;
          grid-column: 1;
        }
        @include bp(small-down) {
          border-bottom: 1px solid $gray3;
        }
      }

      &__mold {
        grid-column: 5;
        grid-row: 1;
        @include bp(large) {
          border: none;
          grid-column: 4;
          grid-row: 2;
        }
      }

      &__grass {
        grid-column: 5;
        grid-row: 2;
        @include bp(large) {
          grid-column: 5;
          grid-row: 1;
        }
      }

      &__ragweed {
        border: none;
        grid-column: 5;
        grid-row: 3;
        @include bp(large) {
          grid-column: 5;
          grid-row: 2;
        }
      }

      &__grass,
      &__humidity,
      &__mold,
      &__ragweed {
        @include bp(large) {
          padding-left: 0.25rem;

          .weather-item-data {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }

  .weather-logo {
    text-align: left;
    width: 5.75rem;
    margin: 0.75rem;
    @include bp(large) {
      grid-row: 3;
      grid-column: 5;
      min-height: 1.25rem;
      margin: 0.75rem 0 0;
    }
  }

  .weather-footer {
    border-top: 1px solid $gray3;
    min-height: 2.125rem;
    @include bp(large) {
      display: none;
    }
  }
}
