.icon {
  &-tracker {
    width: 2.125rem;
  }

  &-time {
    //icons used in treatment tracker
    &__error {
      .icon-inner {
        stroke: $red;

        path {
          fill: $red;
        }
      }

      .icon-circle,
      .icon-inner {
        fill: $gray;
      }

      &:hover {
        .icon-circle,
        .icon-inner {
          fill: $red-alert-background;
        }
      }
    }

    &__unselected {
      .icon-inner {
        stroke: $blue-light;

        path {
          fill: $blue-light;
        }
      }

      .icon-circle,
      .icon-inner {
        fill: lighten($blue-light, 40%);
      }

      &:hover {
        .icon-circle,
        .icon-inner {
          fill: lighten($blue-light, 35%);
        }
      }

      &:active {
        .icon-circle,
        .icon-inner {
          fill: lighten($blue-light, 25%);
        }
      }
    }

    &__selected {
      .icon-inner {
        stroke: $white;

        path {
          fill: $white;
        }
      }

      .icon-circle,
      .icon-inner {
        fill: $blue-light;
      }

      &:hover {
        .icon-circle,
        .icon-inner {
          fill: darken($blue-light, 15%);
        }
      }

      &:active {
        .icon-circle,
        .icon-inner {
          fill: darken($blue-light, 25%);
        }
      }
    }
  }
}
