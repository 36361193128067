//All theme related styling can be found in _theme.scss

.tracker {
  display: grid;
  grid-template-rows: auto 1fr auto;
  box-shadow: none;
  border: 1px solid $black25;
  border-radius: 0px;

  &.tracker-shadow {
    transition:
      box-shadow 0.1s linear,
      height 2s ease;
  }

  &.tracker-no-border {
    border: none;
  }

  &:not(.tracker-weather):not(.tracker-poscorad) {
    min-height: 22.365rem;
  }
  @include bp(large) {
    height: 22.365rem;
  }
  @include bp(large-down) {
    overflow: visible;
  }

  &-body__scroll {
    &::-webkit-scrollbar {
      width: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray2;
      border-radius: 4px;
    }
  }

  .header-icons__right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    position: relative;

    .icon-settings {
      height: 1.625rem;
      margin-right: 0.25rem;
      width: 1.625rem;

      .icon-fill {
        stroke: $gray3;
        fill: $gray3;
      }
    }

    .svg-write {
      .svg-write-circle {
        fill: $gray3;
        mix-blend-mode: multiply;
      }

      .svg-write-pencil {
        fill: $white;
      }
    }

    .svg-instructions {
      margin-right: 0.25rem;

      .svg-instructions-circle {
        fill: $gray3;
        mix-blend-mode: multiply;
      }
    }
  }

  .weather-buttons {
    .icon-settings {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__active {
    .header-icons__right {
      .icon-write {
        
      }

      .icon-settings {
        .icon-fill {
          stroke: $white;
          fill: $white;
        }
      }
    }

    .tracker-list-item-content-wrapper {
      @media (max-width: 500px) {
        //custom query to better fit typical phone widths
        display: grid;
        grid-template-areas: "a b" "a c";
        width: 100vw;
        grid-template-columns: auto 1fr;
      }
    }
  }

  &__inactive {
    cursor: pointer;

    .treatment {
      //Styles specific to treatment tracker in inactive state.
      //grid declared using __grid modifier elsewhere. We do need to tell how many columns though
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.125rem;
      @include bp(small-down) {
        align-items: center;
        display: flex;
        flex-direction: column;

        .treatment__empty {
          display: none;
        }
      }

      .tracker-grid-item {
        padding-bottom: 2rem;

        &-title {
          align-items: center;
          display: flex;
          margin-bottom: 0.5rem;

          .subtext {
            margin-left: 0.5rem;
          }
        }

        .tracker-list-item-content-wrapper {
          opacity: 1;

          .tracker-list-item-description {
            display: inline-block;
            width: 10.375rem;
            padding: 1.1rem 0.5rem;
          }
        }
      }

      // .tracker-notes {
      //   @include bp(large-down) {
      //     padding-bottom  : 0;
      //     background-color: unset;
      //   }
      // }

      .tracker-notes__inactive {
        background-color: lighten($blue-light, 40%);
        @extend .card__width-2;
        margin: 0;
        padding: 0.75rem;
        @include bp(small-down) {
          width: 100%;
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 1rem 0.125rem;
    }

    &__grid {
      display: grid;
    }
  }

  &-footer {
    border-top: 1px solid $gray2;
    display: flex;
    flex-direction: column;
    // height: 4.3rem;
    justify-content: center;

    &-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include bp(medium-down) {
        display: block;
        margin: auto;

        .button {
          margin: 0 0.5265rem;
        }
      }
    }

    &__wide {
      .tracker-footer-items {
        display: block;
        margin: auto;

        .button {
          margin: 0 0.5625rem;
        }
      }
    }
  }

  &-body,
  &-footer,
  &-header {
    padding: 0.75rem;
  }

  &-body {
  }

  &-list {
    border-bottom: 1px solid $gray2;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    //Add media query to margin-auto here!!!!!
    &-item {
      border-radius: 4px;
      margin-bottom: 0.5rem;

      &__alert {
        // padding: 0 0.5rem 1.1rem;
        width: 10.375rem;
        @include bp(medium) {
          width: 15.125rem;
        }
      }

      &-content-wrapper {
        display: flex;
      }

      &-description-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .form-input-checkbox:checked + .tracker-list-item__opaque {
        //if the checkbox is checked, select the sibling element
        @extend .shadow-selected;
        opacity: 1;
      }

      &-description {
        grid-area: b;
        // display: inline-block;
        width: 10.375rem;
        padding: 0.1rem 0 0 0.5rem;
        // height: 3.125rem;
        @include bp(medium) {
          width: 15.125rem;
        }
        @media (max-width: 500px) {
          width: initial;
        }

        &:first-letter {
          text-transform: capitalize;
        }
      }

      .tracker-meta {
        grid-area: c;
        // border-left: 1px solid $gray2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 9.75rem;
        @include bp(small-down) {
          border: 0;
        }
        @media (max-width: 500px) {
          border-top: 1px solid $gray2;
          padding: 0.25rem 0 0.25rem 0.5rem;
          width: 100%;
        }
      }

      .inactive {
        display: none;
      }

      .treatment {
        &-image {
          width: 60%;
          margin: auto;
        }
      }

      &__grid {
        display: grid;
      }

      &__flex-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      &__opaque {
        opacity: 0.4;
        transition:
          box-shadow 0.2s ease,
          opacity 0.3s ease-out;
        @extend .shadow-default;
      }
    }
  }

  //tracker-list-image is the image used for each checkbox item on Trigger and Treatment trackers
  .tracker-list-settings-image {
    grid-area: a;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1.75rem;

    svg {
      height: 1.25rem !important;
      width: 1.25rem !important;
    }
  }

  .button-icon {
    svg {
      height: 1.5rem;
      width: auto;
    }
    padding: 0.5rem 0;
  }

  &-notes {
    padding-bottom: 4rem;
  }

  &-treatment {
    //styles specific to Treatment tracker
    z-index: 0;

    .tracker-body {
      overflow: auto;
    }

    .tracker-footer {
      @extend .tracker-footer__wide;
    }

    .tracker-notes {
      background-color: unset;
      @include bp(large-down) {
        padding-bottom: 0;
      }
    }

    .tracker-notes__inactive {
      .subtext {
        margin-top: 0;
      }
    }
  }

  &-progress {
    &-complete {
      .tracker-icon-wrapper {
        position: relative;

        .tracker-badge-complete {
          left: 22px;
          position: absolute;
          width: 1rem;
          z-index: 1;
          bottom: -2px;

          img {
            background: white;
            border-radius: 50%;
          }
        }

        .tracker-icon-header {
          height: 50px !important;
          width: 50px !important;
        }
      }
    }

    &-incomplete {
      .tracker-badge-complete {
        display: none;
      }
    }
  }

  //triggers view when in read state
  @include bp(large) {
    &-triggers.tracker-progress-complete {
      .tracker-body__grid {
        column-gap: 2.75rem;
        grid-template-columns: 1fr 1fr;

        .tracker-list-item__opaque {
          opacity: 1;
        }
        //gotta make the h3 span all the way across the grid
        h3 {
          grid-column: 1 / 3;
          padding-bottom: 1.125rem;
        }

        .tracker-list-item-description {
          width: unset;
        }
      }

      .tracker-body-form {
        height: unset;
        padding: 0 0.125rem 1rem;
      }
    }
  }

  &-triggers {
    .tracker-body__scroll {
      overflow: visible;
      @include bp(large) {
        overflow: auto;
      }
    }

    .tracker-body__grid {
      grid-template-columns: 1fr;

      .tracker-body__scroll {
        overflow: unset;
      }

      .tracker-list-item__flex-row {
        //unset the display flex
        display: initial;
      }
    }

    .list-content-wrapper {
      width: 100%;

      .tracker-list-item-content-wrapper {
        display: grid;
        border-radius: 4px;
        width: 100%;
        @include bp(small-down) {
          grid-template-areas: "image description" "select select";
          grid-template-columns: auto 1fr;
        }

        @include bp(small-up) {
          grid-template-areas: "image description select" "d d select";
          grid-template-columns: auto 0.75fr 1fr;

          .tracker-list-image,
          .tracker-list-item-description {
            // border-bottom: 1px solid $gray2;
            border-bottom: none;
          }
        }

        .tracker-list-image {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 3.75rem;
        }

        .tracker-list-item-description {
          grid-area: description;
          font-weight: $proxima-semibold;
          width: 100%;
          @include bp(small-up) {
            margin-left: 0;
          }
        }

        .tracker-list-item__select {
          @include bp(small-down) {
            border-top: 1px solid $gray2;
          }
          @include bp(small-up) {
            // border-left: 1px solid $gray2;
            border-left: none;
          }

          .selected-item-list {
            margin: 0 0.75rem;
            @include bp(small-up) {
              margin: 0;
            }

            .selected-item {
              border-bottom: 1px solid $gray2;
              display: flex;
              justify-content: space-between;
              padding: 0.675rem 0;
              @include bp(small-up) {
                padding: 0.95rem;
              }
            }
          }

          .select-items__wrapper {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0.675rem;
            padding: 8px 0;
            @include bp(small-up) {
              margin: 0 0.675rem;
            }

            .form-select-wrapper {
              margin-right: 0.675rem;
              // padding: 0.675rem 0;
              width: 100%;
              @include bp(small-up) {
                padding: 0.675rem 0 0.5rem;
              }
            }

            .form-select__control {
              // margin-left: 0.675rem;
            }
          }

          .button {
            height: 1rem;
            width: 1rem;
          }
        }
      }

      .tracker-list-item__opaque {
        // opacity: 1;
      }
    }

    //if the checkbox inside the wrapper is checked, select the sibling to the wrapper.
    .form-checkbox-wrapper > .form-input-checkbox:checked + .list-content-wrapper {
      @extend .shadow-selected;

      .tracker-list-item {
        .tracker-list-item__opaque {
          opacity: 1 !important;
        }
      }
    }
  }
}

.tracker-header {
  cursor: default;

  //in all states, the "?" and write icons have a pointer
  .icon-instructions,
  .icon-write {
    cursor: pointer;
  }
}

.tracker-header__instructions {
  .icon-instructions {
    //when the instructions are active, we hide the question mark
    .svg-instructions-q {
      display: none;
    }

    //when the instructions are active, the X in the icon is white
    .svg-action__delete-lines {
      stroke: $white;
    }

    // when the instuctions are active, we show a white arrow beneath the icon
    &::after {
      content: "";
      border: 10px solid $gray2;
      border-radius: 2px;
      bottom: -26px;
      display: block;
      position: absolute;
      right: 32px;
      transform: rotate(45deg);
    }
  }
  .icon-write {
    //change mouse cursor to inform user they can't click on this
    cursor: not-allowed;
  }
}

.tracker-header__active.tracker-header__instructions {
  .icon-instructions {
    &::after {
      border: 10px solid $white;
    }
  }

  //when instructions are active, we hide the tracker-footer
  & ~ .tracker-footer {
    display: none;
  }

  .icon-write {
    //if the instructions are active, then we hide the white arrow beneath the write icon
    &::after {
      display: none;
    }
  }
}

.tracker-instructions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 5;
  padding: 5%;
}

.skin-instructions {
  top: 46px;
  height: 312px;
}

.help-tracker-text {
  color: #2a293e;
  text-align: center;
  font-family: Lexend;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: $primary10;
  padding-top: 5px;
  padding-bottom: 5px;
  
  span {
    color: #434255;
    text-align: center;
  }
}

.tracker-settings-list-settings-image {
  svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}

.treatment-icons-container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.tracker-treatment-text{
  margin: 0 0 1em;
  font-size: 0.875rem;
  line-height: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.option-text{
  margin: 0 0 1em;
  font-size: 0.875rem;
  line-height: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Styles for New Triggers Tracker
.trigger-tracker{
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.trigger-card-top {
  @include bp(larger-medium-down) {
    display: none;
  }
  width: 90%;
  margin-right: 0.3em;
  margin-left: 1em;
  float: left;
}

.trigger-card-left {
  @include bp(larger-medium-down) {
    display: none;
  }
  width: 50%;
  min-height: 230px;
  margin-right: 0.3em;
  float: left;
  border-right: 1px solid $gray100;
  border-bottom: 1px solid $gray100;

  .option {
    cursor: pointer;
    color: $black50;
    display: flex;
  }

  .option.active {
    color: $black100;
    /* Textfield */
    font-weight: 700;
  }

  svg {
    margin-right: 8px;
  }
}

.trigger-card-right {
  @include bp(larger-medium-down) {
    display: none;
  }
  width: 50%;
  height: 260px;
  margin-left: -0.3em;
  float: left;
  border-bottom: 1px solid $gray100;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.375rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray2;
    border-radius: 4px;
  }
}

.trigger-card-complete {
  @include bp(larger-medium-down) {
    display: none;
  }
  width: 100%;
  min-height: 230px;
  float: left;
  border-bottom: 1px solid $gray100;


  .option {
    cursor: pointer;
    color: $black50;
    display: flex;
  }

  .option.active {
    color: $black100;
    /* Textfield */
    font-weight: 700;
  }

  svg {
    margin-right: 8px;
  }
}

.trigger-form-item {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}

//ListItemElement styles
.item-style{
  position: relative;
  display: flex;
  padding:10px; 
}

.item-style:not(:last-child){
  margin-bottom: 8px;
}

.item-style-right{
  position: relative;
  display: flex;
  padding-left: 10px; 
  margin-bottom: 15px;
}

.item-style-right:not(:last-child){
  margin-bottom: 5px;
}
