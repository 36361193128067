@import '../globals/colors';

$nodata_width: 200px;
$nodata_icon_width: 24px;

.linechart__nodata {
  position: absolute;
  width: $nodata_icon_width;
  height: $nodata_icon_width;
  margin-top: -$nodata_icon_width/2; // this centers the icon on the component's origin
  margin-left: -$nodata_icon_width/2;

  &__icon {
    position: relative;
    width: $nodata_icon_width;
    stroke: $gray8;
    background-color: transparent;
    .svg-question-mark {
      .svg-question__circle {
        stroke: $white;
        fill: $white;
      }
      .svg-question__mark {
        stroke: none;
        fill: $gray8;
      }
    }

    .button-action__transparent {
      .svg-action__delete-lines {
        stroke: $gray8;
      }
      .svg-action__add-lines {
        stroke: none;
      }
    }

    &:focus,
    &:hover {
      .svg-question-mark {
        .svg-question__circle {
        }
      }
    }
  }

  &__prompt {
    position: relative;
    right: $nodata_width - $nodata_icon_width;
    display: none;
    border-radius: 5px;
    background: $white;
    opacity: 0;

    .icon-full-arrow-forward {
      display: inline;
      width: 1.5rem;
    }
  }

  &.selected {
    width: $nodata_width;
    height: 140px;
  }
  &.selected &__icon .svg-question-mark {
    display: none;
  }
  & &__icon .button-action__transparent {
    display: none;
  }
  &.selected &__icon .button-action__transparent {
    display: block;
  }
  &.selected &__prompt {
    display: block;
    opacity: 0.96;
  }

  div {
    padding: 0.4rem;
    h4 {
      margin: 0;
    }
  }
}
