.flare-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-top: solid 1px $primary10;
  margin-right: 10px;
}

.flare-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flare-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 80%;
}

.flare-actions {
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  border-top: solid 1px $primary10;
}

.flare-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 50px;
  width: 100%;
  gap: 16px;

  .flare-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
  }
}

.flare-option {
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $secondary10;
  border: none;
}

.pointer-flare {
  cursor: pointer;
}

.flare-chart-tooltip {
  background-color: $black100 !important;
  color: $white !important;
  font-weight: 600;

  &::after {
    display: none !important;
  }

  .flare-chart-tooltip-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    z-index: 100 !important;

    div {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      span {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: $black50;
      }
    }
  }
}