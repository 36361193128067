//Use .alert and .alert-negative/.alert-positive as wrapping classes to .alert-paragraph
//If the alert needs to contain the "!" or checkmark, include the classes alert-negative__with-icon/alert-positive__with-icon class following the wrapping class

.alert {
  display: flex;
  margin: 0 auto 0.75rem;
  padding: 0.675rem;
  text-align: center;
  width: 100%;

  //.alert__negative for alerts with a light red background and red text
  // also include .alert-negative__with-icon if the icon is needed in the alert
  &-negative {
    background-color: rgba(179, 61, 38, 0.1);
    color: $feedbackAlert;
    border-radius: 8px;

    &__with-icon {
      //Icon before the first child, whether it be a paragraph, title, etc
      &:first-child {
        &:before {
          content: url("../../assets/images/error.png");
        }
      }
    }
  }
  //.alert__positive for alerts with a light green background and green text
  // also include .alert-positive__with-icon if the icon is needed in the alert
  &-positive {
    background-color: $green-alert-background;
    color: $feedbackSuccess;

    &__with-icon {
      //Icon before the first child, whether it be a paragraph, title, etc
      &:first-child {
        &:before {
          content: url("../../assets/images/complete-inverse.png");
        }
      }
    }
  }

  &-negative,
  &-positive {
    //icon positioning
    &__with-icon {
      display: flex;
      flex-direction: row;

      //Let's pull the icon a bit up so it looks centered with the first line in the paragraph
      &:before {
        margin-top: -0.25rem;
      }

      //Usually content inside the alert wrapper is centered, but because we have an icon, we want it to be flushed left, with a bit of padding against the icon.
      > * {
        text-align: left;
        padding-left: 0.5rem;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  //use the .alert-paragraph paragraph style text. The text and background will be either red or green,
  //depending on the wrapping classes of .alert-negative and .alert-positive
  &-paragraph {
    color: inherit;
  }
}

//Use .alert-space to wrap alerts when showing an alert might make the parent container jump in height
.alert-space {
  // height: 1.75rem;
  // margin-bottom: 1rem;
}

//Sometimes there is no background for alerts, or they don't require a wrapping class.
//The alerts will consist of either green or red text only
//Use .alert-paragraph__negative or .alert-paragraph__positive
.alert-paragraph {
  &__negative {
    color: $feedbackAlert !important;
  }

  &__positive {
    color: $feedbackSuccess !important;
  }

  &__negative,
  &__positive {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__select-error {
    color: $tertiary100 !important;
    font-family: Lexend;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

//Used for confirmation of appointment deletion
.action-alert {
  background-color: $gray1;
  border-radius: 4px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 35px;
  width: 275px;
  z-index: 2;
  @include bp(small-up) {
    width: 300px;
  }
  @include bp(medium) {
    left: -125px;
    top: 50px;
  }

  .action-warning {
    //override the h2 margin-left
    margin-left: 0 !important;
    width: 100%;
  }

  .tracker-footer {
    //at medium and down widths, 0 padding prevents the "stacking" of buttons
    @include bp(medium-down) {
      padding: 0;
    }
  }

  .button {
    @include bp(medium) {
      //override the provider-prep footer button margins with !important
      margin: 0 1rem !important;
    }
  }

  .button__green {
    //make the green submit button red
    background: $red;
  }
  @extend .shadow-selected;
}

//The alert that pops up when a user needs to refresh their page the next day
.alert-reset {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 110vh;
  left: 0;
  position: fixed;
  top: -50px;
  width: 100vw;
  z-index: 100;

  &__body {
    background-color: $white;
    border-radius: 4px;
    margin: auto;
    opacity: 1;
    padding: 0.75rem 3rem 2rem;
    text-align: center;
    @include bp(small-down) {
      padding: 0.7rem 1.5rem 2rem;
    }

    h2 {
      margin-bottom: 0.75rem;
    }

    h4 {
      margin-bottom: 1.25rem;
    }
  }
}
