.trends {
  height: 100%;
  background-color: $white;

  h4 {
    margin: 15px 0;
  }

  .trends-sidebar-header {
    border-right: 1px solid var(--Primary-10, #e7eaf1);
  }

  .trends-header {
    background-color: $white;
    align-items: center;
    display: flex;
    border-bottom: 4px solid #e7eaf1;

    @extend .shadow-default;

    button {
      height: 32px;
    }

    .appt-trends-header-title {
      align-items: center;
      color: $cobalt;
      display: flex;
      flex-shrink: 0;
      font-size: 14px;
      height: 52px;
      margin: 0;
      padding: 0 16px;
      text-align: center;
      text-transform: uppercase;
      width: 200px;

      @include bp('small-down') {
        justify-content: center;
        padding: 0;
        width: 40px;

        .title {
          display: none;
        }
      }
    }

    .trends-header-content {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      font-size: 10px;
    }
  }
  // trends-header

  .trends-separator {
    width: 100%;
    height: 6px;
    background-color: $gray100;
    margin: 20px 0;
  }

  .trends-content {
    display: flex;
    height: auto;
    position: relative;
    flex-direction: row;
    justify-content: center;

    .trends-sidebar {
      // background-color: $gray1;
      // border-right: 1px solid $gray2;
      flex-shrink: 0;
      min-width: 152px;
      position: relative;
      width: 200px;
      margin-right: 0;
      @include bp('small-down') {
        min-width: 40px;
        width: 40px;

        .icon-tracker {
          height: 24px;
          width: 24px;
        }
      }
    }

    .trends-data {
      flex: 1 1 auto;
      max-width: 900px;
      min-height: 500px;
      padding: 8px;

      .trendschart-date-view {
        border: 2px solid $gray2;
        color: $cobalt;
        display: none;
        text-align: center;
        @include bp('large-down') {
          display: block;
        }
      }

      .trends-data-card {
        border-radius: 4px;
        border: 1px solid $gray100;
        background: $white;
      }

      .summary {
        margin: 16px 0;
      }
    }
  }

  .trends-chart-container {
    width: 100%;
    height: auto;
    padding: 16px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .navigation-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 10px;

      .navigation-button {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $gray25;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $secondary100;

        &:hover {
          background-color: $gray50;
        }

        &.disabled {
          cursor: pointer;
          color: $gray100;
          opacity: 0.6;
        }
      }

      .navigation-days {
        flex: 1;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 2px;

        .navigation-day {
          flex: 1;
          height: 40px;
          max-width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          border-radius: 4px;
          color: $black;
          cursor: pointer;
          transition: all 0.2s ease-in;
          font-size: 0.8rem;
          font-weight: 600;

          &.selected,
          &:hover {
            background-color: $secondary100;
            color: $white;
          }
        }
      }
    }

    .chart-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 410px;
      gap: 10px;

      .chart-scales {
        height: 100%;
        width: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .chart-scale {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: $gray25;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $black70;
          font-size: 0.7rem;
          font-weight: 700;
        }
      }

      .chart-content {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .chart-lines {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          z-index: 1;

          canvas {
            width: 100%;
            height: 100%;
          }
        }

        .chart-bars {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 2px;
          z-index: 2;

          .chart-bar {
            flex: 1;
            height: 100%;
            background: $black;
            opacity: 0.15;
            transition: all 0.2s ease-in;
            cursor: pointer;

            &:hover,
            &.active {
              opacity: 0.25;
            }
            &.flare {
              background-color: $severityModerate;
              &.active {
                opacity: 0.35;
              }
            }
            &.not-flare {
              background-color: $secondary100;
              &.active {
                opacity: 0.4;
              }
            }
          }
        }

        .chart-steps {
          width: 100%;
          height: 100%;
          position: relative;

          .chart-step {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: $primary10;
          }
        }
      }
    }

    .chart-buttons {
      width: 100%;
      box-sizing: border-box;
      padding: 0 40px;

      .tracker-buttons {
        margin: 0;
        padding: 0;

        .tracker-button {
          &.tracker-button-poscorad {
            flex: 2;
          }
        }
      }
    }
  }

  .trends-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    padding-bottom: 16px;
  }
}
