.card {
  background: $white;
  overflow: hidden;
  transition: all 1s linear;
  @extend .shadow-default;
  border-radius: 4px;

  &__active {
    @extend .shadow-active;

    &:hover {
      @extend .shadow-hover;
    }
  }

  &__width-2 {
    //the card will take up 2 columns on a css grid-gap
    grid-column: auto / span 2;
  }

  &__width-3 {
    //the card will take up 3 columns
    grid-column: auto / span 3;
  }
}
