* {
  -webkit-font-smoothing: antialiased;

  &,
  &:after,
  &:before {
    box-sizing: border-box;
  }
}

html {
  font-size: 100%;
  font-size: $em-base;
  height: 100%;
  overflow-y: scroll;
}

body {
  height: 100%;
  font-size: 1rem;
  line-height: 1.4;
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #f5f7fb;
}

img{
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
svg {
  margin: 0;

  path {
    width: 100%;
  }
}
