.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  cursor: pointer;
  position: absolute;

  .svg-instructions {
    height: 1rem;

    &-q {
      fill: $midnight-blue;
    }
  }
}

.tooltip-trigger-aux {
  cursor: pointer;
  position: absolute;
  top: 8.8%;
  left: 11.5%;

  .svg-instructions {
    height: 1rem;

    &-q {
      fill: $midnight-blue;
    }
  }
}

.tooltip-content {
  position: absolute;
  background-color: $cobalt-dark;
  padding: 12px;
  border-radius: 4px;
  width: 300px;
  z-index: 10001;
  color: $cobalt10;
  display: block; /* Make it always visible */

  .title {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .text {
    //styleName: Body;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: $white !important;
  }

  hr {
    border: none;
    height: 1px;
    background: $steel-blue;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    font-size: 14px;
  }

  .close-button,
  .learn-more-button {
    margin-left: 10px;
    cursor: pointer;
    text-transform: capitalize !important;
  }

  .learn-more-button {
    color: $lime-green;
  }
}

.tooltip-white-content {
  position: fixed;
  background-color: $white;
  padding: 12px;
  border-radius: 4px;
  width: 350px;
  z-index: 10001;
  color: $cobalt10;
  display: block;

  .title-white-card {
    color: $black100;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin-top: 0px;
  }

  .subtitles {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #000000;
  }

  .text-white-card {
    margin-top: 10px;
    margin-bottom: 15px;
    color: $black100;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  hr {
    border: none;
    background: #EDF1F6;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    font-size: 14px;
  }

  .actions2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
  }

  .close-button,
  .action-buttons {
    color: $secondary100;
    text-align: right;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize !important;
  }

  .margin-buttons {
    margin-right: 5px;
  }

  .stepper-points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container-dots {
    display: flex;
  }

  .circle1,
  .circle2 {
    width: 8px;
    height: 8px;
    margin: 5px;
    border-radius: 50%;
  }

  .circle1 {
    background-color: #092a6d;
  }

  .circle2 {
    background-color: #c0cde5;
  }
}

.tooltip-content-indicator {
  width: 100%;
  position: absolute;
}

.tooltip-content-indicator::before {
  content: "";
  border: 7px solid $cobalt-dark;
  border-radius: 1px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.tooltip-content-indicator-white {
  width: 100%;
  position: absolute;
}

.tooltip-content-indicator-white::before {
  content: "";
  border: 7px solid $white;
  border-radius: 1px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1; 
  pointer-events: none; 
  transition: opacity 0.3s ease;
}

.overlay.visible {
  z-index: 6; 
  opacity: 1;
}

.exception-element {
  background-color: #fff !important;
  z-index: 10000;

  &-icon {
    border-radius: 5px;
    margin-left: 5px;
    height: 1.2rem;
    width: 1.55rem;
  }
}

.title-close-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #edf1f6;
}

.close-button-tutorial {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-top: -10px;
}
