
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox-span {
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid #BAC1CF;
  border-radius: 4px;
  margin-right: 10px;
  transition: background-color 0.2s ease;
}

.custom-checkbox-input:checked ~ .custom-checkbox-span {
  background-color: $tertiary100;
  border-color: $tertiary100;
}

.custom-checkbox-span:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-input:checked ~ .custom-checkbox-span:after {
  display: block;
}

.custom-checkbox-span:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}