/* Override css variables Toastify */

:root { 
  --toastify-color-light: #00603b;
  --toastify-text-color-light: #fff;
  --toastify-toast-min-width: 100px;
  --toastify-toast-width: auto;
  --toastify-toast-min-height: 10px;
}

.Toastify__toast{
  border-radius: 40px;
}

.Toastify__toast-body{
  padding: 0;
}
.Toastify__toast-icon {
  margin-inline-end: 1px;
}