.tracker-poscorad-header {
  padding: 0.75rem 0rem 0rem 0rem;
}

.tracker-poscorad {
  $mildColor: #ff9672;
  $moderateColor: #e95d42;
  $severeColor: #b33d26;

  @include bp(large) {
    grid-column: span 3;
  }
  @include bp(larger) {
    grid-column: span 5;
  }
  grid-column: span 3;
  height: auto;

  .health-tracker__group-title {
    border: none;
  }

  .tracker-body {
    padding: 0.4rem 0;
    display: grid;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;

    .poscorad-tracker-element {
      background-color: $primary10;
      border-radius: 4px;
      float: left;
      padding: 10px;
      display: grid;
      grid-template-columns: auto 3fr;
      grid-column-gap: 0.5rem;
      grid-column: span 3;

      @include bp(large) {
        grid-column: span 2;

        &.pointer-flare {
          grid-column: span 1;
        }
      }

      .poscorad-tracker-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: span 1;
      }

      .poscorad-tracker-text {
        grid-column: span 1;

        .headers,
        p {
          font-family: Lexend;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }

        .headers {
          margin: 0.25rem 0;
          font-size: 14px;
          font-weight: 600;
        }

        p {
          font-weight: 400;
          font-size: 12px;
        }

        span {
          font-weight: 400;
          font-size: 14px;

          @include bp(small-down) {
            font-size: 12px;
          }
        }
      }

      .poscorad-tracker-text.poscorad-history {
        grid-column: span 4;
        margin: 0;
      }

      .poscorad-scales {
        margin: 5px 0 0;
      }

      .poscorad-item-scale {
        width: 100%;

        @include bp(small-down) {
          width: 100%;
        }
      }

      .poscorad-tracker-value-text {
        display: flex;
        align-items: baseline;
      }

      .poscorad-item {
        background: 0;
        padding: 0;

        &-number {
          font-size: 2rem;
          font-weight: 800;
          color: #2d2926;
          padding: 0;
          margin: 0;
        }

        &-number-info {
          font-size: 22px !important;
          font-weight: 700 !important;
          padding: 0;
          margin: 0;
        }

        &-severity-text {
          font-size: 14px;
          font-weight: 700;
          line-height: 21px;
          text-align: left;
          margin-left: 5px;
        }

        &-severity-text-info {
          font-size: 22px !important;
          font-weight: 700 !important;
          line-height: 21px;
          text-align: left;
          margin-left: 5px;
        }

        &-severity {
          color: #6b7280;
          font-weight: 600;
          font-size: 0.9rem;

          &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 3px;
            transform: translateY(-25%);
            background: #6b7280;
            margin: 0 0.5rem 0 0;
          }
          &.Mild::before {
            background: $mildColor;
          }
          &.Moderate::before {
            background: $moderateColor;
          }
          &.Severe::before {
            background: $severeColor;
          }
          &.Mild,
          &.Moderate,
          &.Severe {
            color: #2d2926;
          }
        }
      }

      .poscorad-trackers-icon {
        display: flex;
        justify-content: space-between;
        width: 13rem;

        .tracker {
          &-progress {
            &-complete,
            &-incomplete {
              display: flex;
            }
          }
        }
      }

      .poscorad-trackers-icon > div {
        position: relative;
      }

      .tracker-icon:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 50%;
        left: calc(
          100% + 0.2rem
        ); /* Adjust the horizontal spacing of the line */
        transform: translateY(50%);
        width: 160%; /* Adjust the width of the horizontal line */
        height: 1px;
        border-bottom: 1px dashed $black70; /* Dashed horizontal line */
      }

      .icon-heading-poscorad {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
