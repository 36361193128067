.tracker__active:not(.tracker-skin),
.tracker__inactive:not(.tracker-skin) {
  .tracker-body {
    padding: 0.7rem;
    position: relative;

    &:not(.skin-scale-wrapper) {
      .dragscale-wrapper {
        @include bp(medium) {
          position: absolute;
          top: 6rem;
          left: 0;
          right: 0;
        }
      }
    }
  }
}

.tracker__active:not(.tracker-skin):not(.tracker-treatment):not(.tracker-triggers):not(.tracker-diet),
.tracker__inactive:not(.tracker-skin):not(.tracker-treatment):not(.tracker-triggers):not(.tracker-diet) {
  .tracker-footer {
    border-top: none;

    .button {
      color: $secondary100;
      border-radius: 8px;
      color: #fff;
      text-align: center;

      /* H4/Bold */
      font-family: Lexend;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 100px;
      height: 35px;
      flex-shrink: 0;
      text-transform: capitalize;
    }

    .button__save {
      color: $white;
      background-color: $secondary100;
      &:disabled {
        background-color: $gray1;
        color: $gray5;
      }
    }

    .button__cancel {
      border: 1px solid $secondary100;
      color: $secondary100;
      text-align: center;
      padding: 0rem;
    }
  }
}

.tracker__active {
  .dragscale-tab {
    justify-content: space-between;
  }
}

.tracker__inactive {
  .dragscale-tab {
    justify-content: center;
  }
}

.dragscale-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .dragscale-body {
    position: relative;
    padding-top: 5.5rem;
  }

  .dragscale-tab {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .scale-button {
      align-items: center;
      display: flex;
      height: 1.5rem;
      justify-content: center;
      width: 1.5rem;

      .svg__arrow {
        height: 0.5rem;
      }

      .svg-stroke_fill {
        stroke: $cobalt;
      }

      &__lg {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 2rem;
        width: 2rem;

        .svg__arrow {
          height: 1rem;
        }

        .svg-stroke_fill {
          stroke: $cobalt;
        }
      }
    }
  }

  .tab-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    border: 1px solid var(--Brand-background, #edf1f6);
  }

  &__simple {
    .dragscale-body {
      padding-top: 0;
    }
  }
}

.scale {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  &__simple {
    height: 14px;
    margin-bottom: 0;

    .scale-unit {
      cursor: default;
      height: 8px;
    }
  }

  &-unit {
    border-right: 1px solid $white;
    height: 8px;
    //height is set according to the trackers' read/write states.
    //height can be adjusted on _trackers.scss

    &:first-of-type {
      margin-left: 0.67rem;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
    }

    &:last-of-type {
      border: none;
      margin-right: 0.67rem;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  &-unit-skin {
    border-right: 1px solid $white;
    height: 8px;
    //height is set according to the trackers' read/write states.
    //height can be adjusted on _trackers.scss

    &:first-of-type {
      margin-left: 0.67rem;
    }

    &:last-of-type {
      border: none;
      margin-right: 0.67rem;
    }
  }
}
