/**
 * The view picker can be used anywhere to select the mode of weekly/monthly
 * this is a simple container with a label, a select menu, and the date range
 * -------------------------------------------------------------------------- */
.trends-view-picker {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @include bp('large-down') {
    justify-content: space-around;
  }

  .trends-view-picker-chart-view {
    @include bp('large-down') {
      flex: 1;
    }
    padding-right: 30px;
  }

  label {
    display: inline-block;
    font-size: 10px;
    text-transform: uppercase;
    margin: 0;
  }

  .form-item-select {
    display: inline-block;
    padding: 0 8px 0 6px;
    width: 112px;
  }

  .trends-view-picker-value {
    padding-left: 16px;
    color: $primary100;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include bp('large-down') {
      display: none;
    }
  }
}
