.page-icon {
  fill: $cobalt;
  stroke: $cobalt;
  height: 1.3rem;
  width: 1.3rem;
  margin: 0 4px;
}

.sidebar-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 45px;
  padding-left: 1.125rem;
  padding-right: 2.25rem;

  @include bp(small-down) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .page-icon {
      height: 1.8rem;
      width: 1.8rem;
    }

    h1 {
      display: none;
    }
  }

  h1 {
    margin-left: 0.5rem;
  }
}

.sidebar-icons {
  border-top: 1px solid $gray2;
  @include bp(small-up) {
    padding: 0.675rem 1rem 1rem 1.125rem;
  }

  @include bp(small-down) {
    padding: 0 0.5rem;

    .sidebar-help-text {
      display: none;
    }

    .filter-item {
      .headers {
        display: none;
      }
    }
  }
}

.aside-list__icons {
  padding-top: 1rem;

  hr {
    margin-top: 3rem;
    color: $gray2;
  }

  .filter-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0.25rem 0;

    .headers {
      padding-left: 0.25rem;
    }
  }
}

.page-icon-nav {
  @include bp(large) {
    display: none;
  }
  margin-right: 5px;
}

.main-dashboard__sidebar {
  border-right: 1px solid $primary10;
}
