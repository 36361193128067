.date-bar {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 50px;
  padding: 0 0.375rem 0 0;
  border-bottom: 4px solid $primary10;
  @extend .shadow-default;

  .button__wrapper {
    padding-left: 1rem;
  }

  .date-bar__intro {
    @include bp(small-down) {
      display: none;
    }
  }

  .date-bar__date {
    margin: 0 0 0 0.5rem;
    @include bp(small-up) {
      margin: 0 1.125rem;
    }
  }

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-weight: 200;
    font-size: 14px;
    color: #533acc;
  }

  time {
    display: inline-block;
    min-width: 7.5rem;
    text-transform: capitalize;
  }

  .scale-button {
    align-items: center;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem;
  }

  .buttons__forward {
    display: flex;
    justify-content: space-between;
    width: 3rem;
  }

  .sidebar-margins {
    margin-left: -18px;
  }

  .container-date-info {
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding-left: 10px;
  }
}
