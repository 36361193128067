.page-gallery { 

    .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        text-align: left;
        margin: 10px 0px;
    }

    .gallery-day-picker {
        margin-right: 10px;
    }

    .custom-date-picker {
      position: absolute;
      z-index: 1;
      top: 105px;
    }

    .gallery-day-picker .Dropdown-control {
        width: 250px;
        background-color: #fff !important;
        border: 1px solid #d8dadd;
        padding: 7px 8px 6px 35px;
        background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgB3ZPNDYJAEIXfot4tgQ7UTqQC8EaQA2JMvDkF+MNBzmoHdGIL0gEVsM4CIYgaF735EtjJ25mPYWCBlrwVjf0lTXX9ftsQOQ5VmGj56jYPSW06fA2hKwNRvKWF8EJymHJi68xrKgG7Il+a+U1fSpgc2ELA6huAyZuI9zRTCfyeI17S446oCWj73LXN1PHTDDjBetXxO78G+OHjEz9JtgFsbPCFagDPQHSoUzMomjDwo/4BwJPIVBCsydQtchu5wg3I7Bm4oss5KJUNckyKT6cg/E87upWyLE6iiG53pKVHtMvtSR0AAAAASUVORK5CYII=);
        background-position: 10px;
        background-repeat: no-repeat;
        border-radius: 8px;
    }

    .button__wrapper {
        flex: 1 1;
        display: flex;

        .stretch {
            flex: 1;
        }

        .filter-container {
            cursor: pointer;
            position: relative;

            .icon-button {
                color: #6B7280;
                margin: 5px 10px 0 auto;
            }

            .filter-number {
                position: absolute;
                left: 10px;
                bottom: 4px;
                background: $tertiary100;
                border-radius: 50%;
                padding: 1px 5px;
                font-size: 10px;
                color: $whiteNew;
            }
        }
    }

    .card-title{
        border-bottom: solid 1px $primary10;
        margin-bottom: 15px;
        padding: 15px 0px;
        display: flex;

        h4 {
            margin: 0;
            flex: 1;
        }

        span {
            cursor: pointer;
            color: $secondary100;
            text-align: right;
            /* H4/Bold */
            font-family: Lexend;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .card-buttons {
            max-height: 1px;

            .select-menu {
                display: flex;
                align-items: center;

                span {
                    margin-left: 10px;
                }
            }
        }

        .icon-button {
            color: #6B7280;
            margin-left: 15px;
            cursor: pointer;
        }

        .icon-blue {
            color: #1D62ED;
        }
    }

    .card-left {
        @include bp(larger-medium-down) {
          display: none;
        }
        width: 49.5%;
        margin-right: 0.3em;
        background-color: white;
        float: left;
        padding: 0px 15px;

    }

    .card-right {
        @include bp(larger-medium-down) {
          width: 100%;
        }
        width: 50%;
        background-color: white;
        float: left;
        padding: 0px 15px;
        position: relative;
    }

    .gallery {
        height: 75vh;
        overflow-x: auto;

        .details {
            height: 100%;

            h4 {
                margin-bottom: 5px;

                &:not(:first-child) {
                    margin-top: 2rem;
                }
            }

            .details-link {                
                cursor: pointer;
                color: $secondary100;
            }

            .image-details {
                background-color: #2A292C;
                height: 50%;
    
                img {
                    height: 100%;
                }
            }
        }

        &-day-picker {
            min-width: 250px;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 8px;
        }
    }

    .poscorad-history {
        display: flex;
        margin: 15px 0px;
        justify-content: start;
        font-family: Lexend;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .poscorad-item-severity::before {
            margin-right: 5px;
        }

        .poscorad-item-severity-text {
            margin-right: 5px;
        }

        h4 {
            font-family: Lexend;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    hr {
        color: $primary10;
    }

    .image-container {
        display: flex;
        flex-direction: row;
    }

    .default-details {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .default-img-container {
            border-style: none;
            height: 50px;
            background: $secondary100;
            padding: 10px;
            border-radius: 50%;
            width: 50px;
            display: flex;
            margin: auto;
        }

        span {
            text-align: center;
            /* H4/Bold */
            font-family: Lexend;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 10px 0px;
        }
    }
}

.photo-gallery { 
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 80px;
    
    .photo-container {
        cursor: pointer;
        border-radius: 4px;
        width: 80px;
        height: 80px;
        overflow: hidden;
        background: black;
        align-content: center;
        display: grid;
        position: relative;

        .photo-favorite {
            position: absolute;
            right: -12px;
            bottom: -15px;
        }

        .photo-selector {
            position: absolute;
            background: white;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            top: 5px;
            left: 5px;
            justify-content: center;
            align-items: center;
            border: 1px solid #1A222B;

            .selected-icon {
                color: white;
            }

            &-active{
                background-color: $tertiary100;
                border: 0px;
            }
        }
    }

    img {
        width: auto;
        height: auto;
    }

    h6 {
        margin-top: 0;
        font-family: Lexend;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.upload-photos {

    font-family: Lexend;

    .images-container {
        margin: 10px 0px;
        display: flex;
        overflow-y: auto;
        padding-bottom: 10px;

        .image-preview { 
            cursor: pointer;
            border-radius: 4px;
            width: 180px;
            height: 180px;
            overflow: hidden;
            background: black;
            align-content: center;
            display: grid;
            position: relative;
            margin-left: 10px;
        }
        
        img {
            border-style: none;
            width: 180px;
            height: auto;
            display: flex;
        }

        .image-input {
            cursor: pointer;
            background: #1D62ED33;
            height: 180px;
            max-height: 180px;
            width: 180px;
            min-width: 180px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .photo-delete {
            position: absolute;
            top: 10px;
            left: 10px;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #1A222B;
            color: white;
            border: medium;
            cursor: pointer;
            border: 0.5px solid #F7F7FE;
        }
    }

    .plus-sign {
        color: #1D62ED;
    }

    .date-body-part {
        grid-column: 2/3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .gallery-body-part-picker .Dropdown-control {
        background-color: #fff !important;
        border: 1px solid #d8dadd;
        border-radius: 8px;
    }

    .gallery-day-picker {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #d2d6dc;
        font-family: Lexend;
        padding: 0px 14px;
        background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADhSURBVHgB3ZPNDYJAEIXfot4tgQ7UTqQC8EaQA2JMvDkF+MNBzmoHdGIL0gEVsM4CIYgaF735EtjJ25mPYWCBlrwVjf0lTXX9ftsQOQ5VmGj56jYPSW06fA2hKwNRvKWF8EJymHJi68xrKgG7Il+a+U1fSpgc2ELA6huAyZuI9zRTCfyeI17S446oCWj73LXN1PHTDDjBetXxO78G+OHjEz9JtgFsbPCFagDPQHSoUzMomjDwo/4BwJPIVBCsydQtchu5wg3I7Bm4oss5KJUNckyKT6cg/E87upWyLE6iiG53pKVHtMvtSR0AAAAASUVORK5CYII=);
        background-position: 95%;
        background-repeat: no-repeat;
    }
}

.delete-photo {
    text-align: center;

    .icon-delete-container {
        border-style: none;
        height: 80px;
        background: $secondary100;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 80px;
        display: flex;
        margin: 30px auto;
    }

    .description {
        text-align: center;
    }
}

.controls-container {
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .sorting-controls {
        .radio-container {
            padding-left: 0;
        }

        .radio-checkmark {
            left: calc(100% - 30px);
        }
    }

    .controls-title {
        margin-top: 0px;
        border-top: 1px solid #EDF1F6;
        margin-right: 15px;
    }

    .card__active:hover {
        box-shadow: none;
    }

    .tracker-skin {
        margin-bottom: 50px;
    }

    .skin-areas-wrapper {
        height: 15rem;
    }      

    .custom-checkbox-span {
        position: absolute;
        right: 25px;
    }
}

.filter-list-container {
    display: flex;
    font-size: 14px;
    position: relative;
    margin-bottom: 10px;  
    padding-bottom: 10px;
    align-items: center;
    border-bottom: solid 1px $primary10;
    overflow: auto;

    .filter-list-title {
        white-space: nowrap;
        display: inline-flex;
    }

    
    .filter-list-buttons {

        .filter-list-item {
            display: inline-flex;
            position: relative;
            background: $tertiary100;
            border-radius: 4px;
            padding: 1px 10px;
            color: aliceblue;
            align-items: center;
            white-space: nowrap;
            margin: 3px;
        }
    
        .tag-delete {
            cursor: pointer;
            margin-left: 10px;
        }
    
        .filter-list-remove {
            white-space: nowrap;
            color: $secondary100;
            cursor: pointer;
        }
    }
    

}