  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal {
    background: white;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    max-height: 90%;
  }
  .modal.l {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .modal.ml {
    width: 700px;
    height: 700px;
  }

  .modal.m {
    width: 630px;
    height: 600px;
  }

  .modal.s {
    width: 350px;
    height: 400px;
  }

  .modal-header, .modal-footer {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e8;
  }

  .modal-footer {
    padding-top: 5px;
    border-top: 1px solid #e6e6e8;

    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }

  .modal-content {
    padding: 20px 0;
    flex-grow: 1;
    overflow: auto;
  }

  .close-button {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }