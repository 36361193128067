.page-settings {
  background-color: #f5f7fb;
  .headers {
    //Lose the inherited margin from typography
    margin: 0;
  }

  .section-margin {
    margin-top: -20px;
  }

  hr {
    height: 1px;
    width: 97%;
    border-width: 0;
    background: $secondary10;
  }

  .card-container {
    display: flex;
    justify-content: center;
    padding: 5px;
  }

  .card-left {
    @include bp(larger-medium-down) {
      display: none;
    }
    width: 20%;
    min-height: 80vh;
    margin-right: 0.3em;
    background-color: white;
    float: left;

    .option {
      cursor: pointer;
      padding: 15px 5px;
      margin: auto 5px;
      color: var(--Neutral-neablack, #2D2926);
      /* Body */
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      display: flex;
    }

    .option.active {
      color: $secondary100;

      /* Textfield */
      font-weight: 700;
      letter-spacing: -0.32px;
    }
  }

  .card-right {
    @include bp(larger-medium-down) {
      width: 100%;
    }
    width: 80%;
    min-height: 80vh;
    background-color: white;
    float: left;
    padding: 0px 15px;

    hr {
      width: 100%;
    }

    .title {
      color: #000;
      width: 100%;
      /* Textfield */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 1rem;
    }

    .description {
      color: #000;

      /* Body */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 600px;
    }

    .form {
      margin-top: 10px;
      overflow-y: auto;
      max-height: 60vh;
    }

    .form-item {
      width: 50%;
      margin-right: 10px;
      position: relative;
      margin-top: 1rem;


      @include bp(larger-medium-down) {
        width: 100%;
      }
    }

    .form-item-row {
      align-items: end;

      @include bp(small-down) {
        display: block;
      }
    }

    input:disabled {
      color: $black70; 
      font-family: Lexend;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .card-footer {
      position: sticky;
      top: 100%;
      border-top: solid 4px #f5f7fb;
      padding: 5px;
      margin: 0px -20px;
      min-width: 80%;
      justify-content: end;


      .button{
        margin-right: 10px;
      }

      @include bp(larger-medium-down) {
        min-width: 100%;
      }
    }

    .warning {
      color: var(--Secondary-grapefruit, #E95D42);
      display: inline-flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-weight: 600;
      border-radius: 4px;
      background: rgba(233, 93, 66, 0.10);
    }

    .image-text {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }
    .image-text img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .image-text p {
      color: #000;
      width: 100%;
      /* Textfield */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    .password-input-wrapper {
      position: relative;
    }

    .password-toggle-button {
      color: #6B7280;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-15%);
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .help-text {
      margin-bottom: 1rem;
    }

    .react-autosuggest__suggestions-container {
      width: 90% !important;
    }

    .registration-treatment-checklist {
      .form-item {
        width: 100%;
      }

      .registration-treatment-item-wrapper {
        margin-bottom: 1rem;
      }

      .form-item-row {
        @include bp(larger-medium-down) {
          display: flex;
        }
      }
    }

    .login-registration {
      @include bp("small-down") {
        padding: 0;
      }
    }


    .multi-option{
      display: flex;
      align-content: space-between;
      margin-top: 1rem;

      .options{
        width: 30%;
      }
    }
  }

  .section__password {
    display: flex;
    flex-direction: column;

    .form-pass-validation {
      padding-bottom: 10px;
      width: 45%;
      font-family: Lexend;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &__passing {
        color: $feedbackSuccess;
        margin-left: 2px;
      }
      
      @include bp(larger-medium-down) {
        width: 100%;
      }
    }

    //These instructions are for mobile sizes only.
    @include bp(larger-medium) {
      .password-instructions__mobile {
        display: none;
      }
    }
  }

  .section__delete {
    .alert {
      //normally the element above this one would have margin-bottom, but in
      //in this case, the <p/> has a :last-of-type rule removing it
      margin-top: 1rem;

      .form-item {
        //give this a bit of margin-top to center the text in the alert area
        margin-top: 0.75rem;
      }
    }
  }

  // My treatments styles
  .settings-treatment-name {
    color: #2A293E;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
    margin-bottom: 10px;
  }
}
