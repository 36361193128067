.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.main-dashboard__content {
  .health-tracker-main {
    // max-width: calc(100% - 1rem);
    transition: background-color 0.8s ease 0.1s;
    background-color: #f5f7fb;

    //when users go back to past dates to edit
    &__past {
      // background-color: $gray5;
      transition: background-color 0.3s ease 0.2s;
    }
  }
}

.health-tracker {
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.25rem;
  }

  &__grid {
    border-top: 4px solid $primary10;
    display: grid;
    grid-gap: 1.125rem;
    padding: 1.25rem 1rem 1.25rem;
    background: #fff;

    &.no-padding {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      grid-gap: 0rem 1.125rem;
    }
  }

  &__grid2 {
    display: grid;
    margin-top: 1.125rem;
    grid-gap: 1.125rem;
    padding: 1.25rem 1rem 1.25rem;
    background: #fff;

    &.no-padding {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      grid-gap: 0rem 1.125rem;
    }
  }

  &__grid-poscorad {
    display: grid;
    margin-top: 1.125rem;
    grid-gap: 1.125rem;
    padding: 1.25rem 1rem 1.25rem;
    background: #fff;

    &.no-padding {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      grid-gap: 0rem 1.125rem;
    }
  }

  &__weather-container {
    margin-top: 1.125rem;
    padding: 0.25rem 0;
    background: #fff;
  }

  &__group-title {
    color: $gray18;
    font-weight: 600;
    font-size: 1.1em;
    grid-column: 1 / 5;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $black25;
    padding-bottom: 5px;

    &.no-border-bottom {
      padding: 5px 0px;
      border-bottom: none;
    }
  }

  @include bp(large) {
    //screens 1024-1200 and up have 3 columns for the tracker cards.
    &__grid {
      grid-template-columns: repeat(4, 15.75rem);
    }
    &__group-title {
      grid-column: 1 / 5;
    }
  }
  @include bp(largest) {
    //screens 1024-1200 and up have 3 columns for the tracker cards.
    &__grid {
      grid-template-columns: repeat(5, 15.75rem);
    }
    &__group-title {
      grid-column: 1 / 6;
    }
  }
  @include bp(large-down) {
    //screens smaller than 1024
    &__grid {
      grid-template-columns: repeat(3, 15.75rem);
      margin: 0 1rem 0;
    }
    &__group-title {
      grid-column: 1 / 4;
    }
  }
  @include bp(medium-down) {
    //screens between 450 and 768, called Mid-size tablet in the designs
    &__grid {
      display: flex;
      flex-direction: column;
      width: 100%;

      .card {
        margin-bottom: 1.125rem;
      }
    }
  }
}

.login {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 10px auto;

  .login-logo {
    width: 80%;
  }

  .subtitle {
    //styleName: body2;
    text-align: left;
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  &-mt {
    margin: 25% auto;

    @include bp('larger-medium-down') {
      margin: 10px;
    }
  }

  &-title {
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  &-form {
    width: 23rem;
    margin: 15px 0 15px 0;

    @include bp('larger-medium-down') {
      margin: 0;
      width: 100%;
    }

    .form-input-checkbox {
      min-width: 15px;
    }

    .form-input-label {
      font-weight: initial;
    }

    .form-input-field {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #d2d6dc;
      font-family: Lexend;
    }

    .form-select__control {
      height: 40px;
      border-radius: 8px;
      border: 1px solid #d2d6dc;
      margin-right: 0.675rem;
      width: 100%;
    }

    .multi-option {
      font-family: Arial;
      font-size: 14px;
      display: -webkit-inline-box;

      .option:first-child {
        margin-right: 25%;
      }
      .option {
        input {
          margin-right: 5px;
          accent-color: $severityModerate;
        }
      }
    }

    .form-pass-validation {
      font-family: Lexend;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $black100;

      &-item {
        margin-left: 2px;
      }

      &__passing {
        color: $feedbackSuccess;
        margin-left: 2px;
      }
    }
  }

  &-links > * {
    font-size: 14px;
    margin-bottom: 1.75rem;
    display: block;
  }

  &-logo {
    margin: auto;
    padding-bottom: 1.125rem;
  }

  &-paragraph {
    margin: auto;
    width: 95%;
    padding-bottom: 1.125rem;
  }

  &-paragraph-forgot-password {
    margin: auto;
    width: 95%;
    padding-bottom: 1.125rem;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: $black100;
  }

  @include bp('small-down') {
    padding: 0;
  }
}

.login-card {
  @extend .login;
  @extend .shadow-6;
  align-items: center;
  background-color: $white;
  margin: 1.75rem auto 2.375rem;
  padding: 1rem 10px;
  text-align: center;
  width: 25rem;
}
.page {
  height: 100%;

  &-login {
    display: flex;

    background-color: #f5f7fb;
    background-image: url(../../assets/images/home/Fill-5.svg);
    background-position: right;
    background-repeat: no-repeat;

    @include bp('larger-medium-down') {
      background-position-x: right;
      background-size: 70%;
      background-position-y: top;
    }

    @include bp('larger-medium-down') {
      .content-wrapper {
        flex-direction: column;
      }

      .image-section {
        order: -1;
        max-height: 25%;
      }
    }

    .content-wrapper {
      max-width: 1200px;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      width: 100%;

      @include bp('small-up') {
        width: 80%;
      }

      @include bp('medium') {
        width: 50%;
      }

      @include bp('larger-medium') {
        width: 100%;
        flex-direction: row;
      }

      .image-section {
        width: 100%;
        justify-content: center;
        flex-direction: row;
        text-align: center;

        @include bp('larger-medium') {
          width: 50%;
          text-align: left;
          padding: 2rem 0 2rem;
        }

        img {
          height: auto;
          transform: scaleX(-1);

          @include bp(small-up) {
            width: 100%;
          }

          @include bp('larger-medium-down') {
            max-height: 25%;
          }
        }
      }

      .content-section {
        width: 100%;
        max-width: 100%;
        padding: 0 1rem;
        text-align: center;

        @include bp('larger-medium') {
          width: 50%;
        }
      }
    }
  }

  &__violet {
    @extend .violet-gradient;
  }
}

.tooltip_popup_thing {
  div {
    background-color: white;
    display: none;
  }
}

.tooltip_popup_thing:focus,
.tooltip_popup_thing:hover {
  div {
    display: block;
    position: absolute;
  }
}

.appointment {
  @include bp(medium-down) {
    display: flex;
    flex-direction: column;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
}

.state-zip {
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.appt-notes {
  grid-column: 3/4;
  grid-row: 2/4;
}

.radio-wrapper__grid {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.trigger-list-override {
  display: flex;
  flex-direction: column;
}

.content-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:nth-child(even) {
    background-color: $white;
  }

  &__informational {
    padding: 3rem 0 4rem 0;
  }

  &__large {
    padding: 8rem 0;
    @include bp(medium-down) {
      &:first-of-type {
        padding-top: 2rem;
      }
    }
    @include bp(medium) {
      &:first-of-type {
        padding-top: 4rem;
      }
    }
  }
}

//Flex classes for content pages
.content {
  &__row {
    display: flex;
    flex-direction: row;
    @include bp(small-down) {
      flex-direction: column;
    }

    & > div {
      // flex-basis: 0;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__center {
    align-items: center;
    justify-content: space-between;
  }

  &__space-between {
    align-items: center;
    justify-content: center;
  }
}

.content-text-wrapper {
  display: flex;
  flex-direction: column;

  &__left {
    align-self: flex-start;
    @include bp(medium) {
      padding-right: 2.25rem;
    }
  }

  &__right {
    align-self: flex-start;
    @include bp(medium) {
      padding-left: 2.25rem;
    }
  }

  .title {
    margin-bottom: 0.5rem;
  }
}

.content-section__inner {
  max-width: 66%;
  @include bp(small-down) {
    max-width: 85%;
  }
  @include bp(largest) {
    max-width: 1000px;
  }
}

.content-help-section__inner {
  max-width: 100%;
  @include bp(small-down) {
    max-width: 85%;
  }
  @include bp(largest) {
    max-width: 1000px;
  }
}

.content-section__slim {
}

.content-section__inner {
  width: 100%;

  .content-text-wrapper {
    width: 100%;
  }
}

.list {
  padding-left: 1.5rem;

  li {
    text-decoration: none;
  }
}

ol.list {
  padding-left: 1.875rem;
}

.web-margin {
  margin-top: -45px;
}
//More styles specific to content pages

.content-body {
  opacity: 0;
  transition: opacity 0.7s ease-in;

  min-height: 100%;

  .headers {
    margin-left: 0;
  }

  .list {
    li {
      h4.paragraph {
        margin-bottom: 0.25rem;
        margin-top: 1rem;
      }
    }
  }

  .profile-list__wrapper {
    justify-content: space-around;

    .profile-item {
      width: 12rem;
      padding: 12px;
    }
  }

  .profile-list__wrapper-trackers {
    margin-top: 2rem;

    //the wrapper for the icons
    .content__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;

      [class^='tracker-theme-'] {
        // display     : block;
        margin-right: 0.5rem;
      }

      @include bp(medium-down) {
        flex-direction: row;
      }
    }

    //the title of the tracker needs a bit of space beneath
    .headers {
      margin: 0 0 0 0.5rem;
    }

    //the trackers are displayed differently, depending on media width
    display: grid;
    @include bp(small-down) {
      display: flex;
      flex-direction: column;
    }
    @include bp(medium-only) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem 1rem;
    }

    @include bp(large) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      justify-content: space-between;
    }

    .profile-item:not(:last-of-type) {
      @include bp(medium-down) {
        border-bottom: 1px solid $gray2;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    }

    .profile-item:not(:nth-of-type(-n + 2)) {
      @include bp(medium) {
        border-top: 1px solid $gray2;
        padding-top: 1.5rem;
      }
    }

    .profile-item {
      .paragraph:first-of-type {
        padding-top: 1rem;
      }
    }
  }

  .section-advisors {
    .content__row {
      justify-content: space-between;

      @include bp(medium-down) {
        flex-direction: column;

        div:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .list {
    margin: 1rem 0;
    &.images {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 3rem 0 4rem 0;
      padding-left: 0;
      .stretch {
        flex: 1;
      }
      li {
        img {
          max-height: 56px;
          margin: 0 12px;
        }
      }
    }
  }
}

.fade {
  transition: opacity 0.05s;

  &-in {
    opacity: 1;
  }
}

.admin_statistics {
  max-width: 80%;
  margin: 50px auto;

  .user-search {
    background-color: #fff;
    border: 1px solid #aaa;
    padding: 6px 8px;
    margin: 12px;
    border-radius: 3px;
    outline: none;
  }

  .admin_nav {
    font-size: 24px;
    border-bottom: 1px solid $gray2;
    margin-bottom: 28px;
    padding: 0 0 6px 0;

    .admin_nav_link {
      padding: 12px 0;
      margin: 8px 16px;
      color: #888;

      &:first-child {
        margin-left: 0;
      }
    }

    .admin_nav_link.active {
      font-weight: 600;
      color: #555;
    }
  }

  h3 {
    margin-bottom: 4px;
  }

  .treatment_groups {
    padding: 8px;
    margin-bottom: 16px;
    .treatment_group {
      opacity: 0.4;
      margin: 4px;
      background-color: rgb(157, 211, 228);
      padding: 12px 16px;
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
    .treatment_group.active {
      opacity: 1;
    }
  }

  .treatment_actions {
    padding: 16px;
    text-align: right;
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    .stretch {
      flex: 1;
    }
    button,
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 6px;
      max-height: 35px;
    }
  }

  .treatment-tile {
    line-height: 32px;
    overflow: visible;
    padding: 12px 32px 18px 32px;
    margin: 6px 6px 12px 6px;
    transition: none;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;

    .expander {
      padding: 0;
      margin: 0;
      &.showing {
        button {
          color: #555;
          &:hover {
            color: #888;
          }
        }
      }
      // direct child (expander)
      & > button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        outline: none;
        margin: 16px 0;
        padding: 4px 8px;
        transition: color 0.3s ease, padding-right 0.3s ease;
        color: #888;
        &:hover {
          color: #555;
        }
      }
    }

    .info-table {
      flex: 1;
      margin-left: 16px;
      font-size: 14px;
      line-height: 1rem;
      table {
        border-collapse: collapse;
        th,
        td {
          white-space: nowrap;
          border: 1px solid #bbb;
          padding: 4px 6px;
          margin: 0;
          & + td {
            text-align: right;
          }
        }
      }
      .notes {
        margin-top: 12px;
        color: #666;
      }
    }

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .completed-status {
      margin-top: -14px;
      margin-left: 4px;
      padding-top: 0;
      font-size: 14px;
      color: #666;
      text-align: right;
    }

    textarea {
      resize: vertical;
      width: 100%;
    }

    .multi-option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;

      .option {
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
          margin-right: 6px;
        }

        label {
          white-space: nowrap;
        }
      }
    }

    .qualifier {
      margin-left: 6px;
    }

    li > button {
      background-color: transparent;
      border: none;
      align-self: center;
      color: #888;
    }

    .treatment-tile-dropdown,
    li > input,
    li > textarea {
      display: inline-block;
      flex: 1;
      font-size: 14px;
    }

    li > input[type='checkbox'] {
      flex: 0;
      align-self: center;
    }

    .treatment-tile-dropdown > div,
    input,
    textarea {
      border-radius: 4px;
      border: 1px solid #aaa;
      font-family: inherit;
    }

    .list-input {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 4px;

      .list-input-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .item-number {
          color: #bbb;
          display: inline-block;
          width: 12px;
        }

        input,
        textarea {
          flex: 1;
          font-size: 14px;
          margin: 4px 0 4px 12px;
        }

        .button-action__delete {
          margin-left: 8px;
          width: 16px;
          height: 16px;
        }
      }
    }

    h1 {
      padding: 8px 18px 12px 0;
      margin: 0;
      float: left;
      cursor: default;
    }

    h2 {
      padding: 8px 0 8px 0;
      margin: 6px 0;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: default;

      opacity: 0.6;
      &.active {
        opacity: 1;
      }

      .material-icons {
        margin-left: 4px;

        &.active {
          color: green;
        }

        &.inactive {
          color: red;
        }
      }
    }

    h4 {
      color: #777;
      margin: 0 12px 38px 12px;
    }

    h5 {
      text-decoration: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 6px;
      margin-left: 16px;

      input,
      textarea {
        padding: 6px;
        background-color: rgb(250, 250, 250);
      }

      // button {
      //   flex: 0;
      // }

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 180px;

        margin-right: 12px;

        .button-action__add {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
