.login-registration {
  @include bp("small-down") {
    padding: 2.378rem 3.75rem;
  }

  .icon-background-container {
    width: 90px;
    height: 90px;
    background-position: "center";
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
  }

  .icon-card-container {
    background-position: "center";
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-input-button {
    padding: 12px 4px;
    border-radius: 4px;
    border: solid 1px #e1e1e1;
    background-color: $white;
    margin: 6px 4px;
    color: rgb(51, 51, 51);
    font-size: 12px;
    outline: none;

    &:hover,
    &:focus,
    &.active {
      background-color: #7789d0;
      color: white;
    }
  }

  .icon-complete__inverse {
    min-width: 1.25rem;
  }

  .treatment-setup__icon {
    .icon {
      .tracker-icon-background {
        fill: $blue-light;
      }

      .tracker-icon-image {
        fill: $white;
      }
    }
  }
  //if there is gonna be a title next to the icon, let's give it some room.
  .treatment-setup__icon + .title {
    padding-left: 0.5rem;
  }

  .tracker-list-item__opaque {
    opacity: 0.8;
  }

  .tracker-list-item-content-wrapper {
    width: 100%;

    .tracker-list-item-description {
      width: 100%;
    }
  }

  .registration-treatment-checklist {
    // columns: initial;
    display: flex;
    flex-direction: column;

    .list-content-wrapper {
      width: 100%;
    }
  }

  .registration-treatment-checklist {
    //styles and overrides specific to the account setup screens
    .form-item {
      .form-checkbox-wrapper {
        .form-input-checkbox {
          margin-top: -1rem !important;
        }

        .registration-tracker-item {
          display: flex;

          .registration-tracker-content {
            // @extend .shadow-default;
            border-radius: 4px;
          }

          .tracker-list-item {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 0;

            &-content-wrapper {
              box-shadow: none;
              opacity: 1;
            }
          }

          .tooltip-wrapper {
            margin: 0.1rem 0.4rem 0 0;

            .tooltip-text {
              color: $whiteNew;
              font-family: Lexend;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .tooltip_popup_thing {
              position: relative;
              width: 1rem;

              .svg-question-mark {
                width: 1rem;
                height: 1rem;

                .svg-question__circle {
                  fill: $gray3;
                }

                .svg-question__mark {
                  fill: $secondary100;
                }
              }

              .tooltip-popup {
                display: none;
                opacity: 0;
                position: absolute;
              }

              &:focus,
              &:hover {
                .svg-question-mark {
                  .svg-question__circle {
                    fill: $gray4;
                  }
                }

                .tooltip-popup {
                  background-color: $black70;
                  border-radius: 4px;
                  display: block;
                  opacity: 0.96;
                  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
                  right: -1.15rem;
                  top: 1.3rem;
                  width: 15.625rem;
                  z-index: 100;
                  // @extend .shadow-active;

                  &:after {
                    content: "";
                    border: 10px solid $black70;
                    opacity: 0.96;
                    top: 0;
                    right: 1rem;
                    position: absolute;
                    transform: rotate(45deg);
                    border-radius: 1px;
                    z-index: -1;
                  }

                  span {
                    color: $white;

                    .headers {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    li:last-of-type {
      .tooltip_popup_thing {
        .tooltip-popup {
        }
      }
    }
  }

  .registration-tracker-item {
    .registration-tracker-content {
      .form-item {

      }

      .registration-treatment-item-wrapper {
        position: relative;

        .button {
          svg {
            height: 1.25rem;
            width: 1.25rem;
          }
          margin-left: 0.5rem;
        }

        .registration-treatment-item {
          border-radius: 8px;
          border: 1px solid #D5D5D6;
          line-height: 38px;
          padding-left: 0.375rem;
          width: 100%;
        }

        .registration-treatment-field {
          width: 100%;

          &:focus,
          &:hover {
            border-color: $blue-light;
          }
        }

        .react-autosuggest__container {
          width: 100%;
        }

        .react-autosuggest__suggestions-container {
          background-color: white;
          box-shadow: 0px 2px 12px rgba($color: #000000, $alpha: 0.2);
          border-radius: 4px;
          position: absolute;
          top: 100%;
          max-width: 100%;
          box-sizing: border-box;
          z-index: 100;
          overflow: hidden;

          .react-autosuggest__suggestion--highlighted {
            div {
              background-color: rgba($color: #c7d0ec, $alpha: 1);
            }
          }

          div {
            padding: 8px;
            cursor: pointer;
            &:hover,
            &:focus {
              background-color: rgba($color: #c7d0ec, $alpha: 1);
            }
          }
        }
      }
    }
  }
  //Shadow for when the checkbox is checked
  .form-input-checkbox:checked + .list-content-wrapper {
    .registration-tracker-item {
      .registration-tracker-content {
        // @extend .shadow-selected;
      }
    }
  }

  .tracker-list-image {
    background-color: lighten($blue-light, 40%);
    padding: 0.25rem 0.25rem 0;

    svg {
      min-width: 2.5rem;
      height: 2.5rem;

      g {
        fill: $blue-light;
      }
    }
  }

  //** New Styles
  .login-title-text {
    font-family: Lexend;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

    &-16p {
      font-size: 16px;
    }

    &-16p400 {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .link-login-btn {
    padding-top: 50px;
  }
}
