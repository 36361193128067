.treatment-attribute-add.button-action__add {
  width: 46px;
  height: 100%;
  display: inline-block;
  align-self: center;
}

.treatment-editor-navigation {
  margin-bottom: 16px;
  padding: 0 24px;
  display: flex;
  align-items: center;

  a.title {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 17px;
    padding: 4px 6px;
    margin: 0 3px;
    cursor: pointer;
    border-radius: 4px;
    transition: color 0.3s ease, background-color 0.3s ease;

    line-height: inherit;
    font-size: inherit;
    vertical-align: middle;
  }

  a.title.active {
    background-color: #6366ef;
    color: white;
  }
}

.admin-help {
  display: inline;
}

.admin-help.material-icons {
  margin-left: 4px;
  font-size: 18px;
  line-height: inherit;
  vertical-align: middle;
  opacity: 0.6;
  color: #6366ef;
}

.active .admin-help.material-icons {
  color: #fff;
}

.admin-tooltip {
  max-width: 40%;
  line-height: 18px;
  font-size: 18px;
  text-align: left;
}
