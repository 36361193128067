h1,
h2,
h3,
h4,
h5,
h6 {
  //Unopinionated to avoid using semantics for styling
  line-height: 1.25;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 1em;
  font-size: 0.875rem;
  line-height: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

//Font variables
//Available font-weights for Proxima Nova. To add more, edit the typekit at typekit.com
//Font family is set in _fonts.scss
$proxima-thin: 100;
$proxima-regular: 400;
$proxima-semibold: 500;
$proxima-mediumbold: 600;
$proxima-bold: 700;

//Font styles by Class for styling, as shown in UI kit.
//If the style differs from the UI kit, add the additional modifying classes
//Example: H2 designated as a header in $cobalt and italics would be as follows:
//<h2 className="headers headers__violet headers__italic">

//Title
//Use .title or .title__large
.title {
  font-size: 1.25rem;
  font-weight: $proxima-regular;

  &__large {
    font-size: 2rem;
    font-weight: $proxima-semibold;
    line-height: 1;
  }

  &__mega-large {
    font-size: 4rem;
    font-weight: $proxima-semibold;
    @include bp(small-down) {
      font-size: 3.5rem;
    }
  }

  &__24p {
    font-size: 1.5rem;
    font-weight: $proxima-bold;
  }

  &__big-number {
    color: $black100;
    text-align: center;
    font-family: Lexend;
    font-size: 80px;
    font-style: normal;
    font-weight: $proxima-mediumbold;
    line-height: normal;
  }

  &__homepage-large {
    font-size: 3rem;
    font-weight: $proxima-regular;
    line-height: 1.1;
    @include bp(medium) {
      font-size: 4rem;
    }
  }

  &__break-line {
    padding-bottom: 1.125rem;
    margin-bottom: 1.125rem;
    border-bottom: 1px solid $gray2;
  }

  &__large-bold {
    font-weight: $proxima-bold;
  }

  &__title-settings {
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: $proxima-mediumbold;
    line-height: normal;
  }

  &__title-settings16p {
    font-size: 16px;
  }

  &__title-margin {
    margin-top: -10px !important;
  }
}

//.headers has a plural type name because .header is used elsewhere for layout
.headers {
  margin: 0.25rem;
  font-size: 1rem;
  font-weight: $proxima-regular;
}

.paragraph {
  color: $black100;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &__p14 {
    font-size: 14px;
  }
}

.help-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0 0 0.25rem;
  background: rgb(242, 242, 251);
  border-radius: 4px;
  padding: 5px 0px;
}

.subtext {
  font-weight: $proxima-semibold;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 0.82rem;
  margin-bottom: 0.8rem;
}

.subtext-trackers {
  color: $black100;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.login-link {
  font-family: Lexend;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: $black100;

  &-info-text {
    font-weight: 400;
  }
}

.link {
  color: inherit;
  font-size: inherit;
  font-weight: $proxima-semibold;

  &__cobalt-dark {
    color: $cobalt-dark;

    &:active,
    &:hover {
      color: $cobalt-active;
    }

    &:active {
      background-color: $gray1;
    }
  }

  &__secondary-100 {
    color: $secondary100;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    &:active,
    &:hover {
      color: $cobalt-active;
    }

    &:active {
      background-color: $gray1;
    }
  }

  &__violet {
    color: $cobalt;

    &:active,
    &:hover {
      color: $cobalt-active;
    }

    &:active {
      background-color: $gray1;
    }
  }

  &__white {
    color: $white;

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
  }
}

//Modifiers
// add these classes to modify styles that differ from the UI kit
// Example: An H1 with "title" style, and in bold italic
//<h1 className="title title__italic title__bold">
//The .text__modifiers are convenient, as they can be used for <span>
.extra-info,
.headers,
.help-text,
.link,
.paragraph,
.subtext,
.subtext-trackers,
.text,
.title,
.card-title {
  //font-alignment
  &__centered {
    text-align: center;
  }

  &__left {
    text-align: left;
  }

  &__justify {
    text-align: justify;
  }

  &__right {
    text-align: right;
  }

  //font-styles
  &__italic {
    font-style: italic;
  }

  &__normal {
    font-style: normal;
  }

  &__oblique {
    //not available-use italic
  }

  //font-weights
  &__bold {
    font-weight: $proxima-bold;
  }

  &__mediumbold {
    font-weight: $proxima-mediumbold;
  }

  &__regular {
    font-weight: $proxima-regular;
  }

  &__semibold {
    font-weight: $proxima-semibold;
  }

  &__thin {
    font-weight: $proxima-thin;
  }

  //text-decorations
  &__line-through {
    text-decoration: line-through;
  }

  &__overline {
    text-decoration: overline;
  }

  &__underline {
    text-decoration: underline;

    &__skip-ink {
      text-decoration-skip-ink: inherit;
    }
  }

  //text-transforms
  //Use "capitalize". "capitalized" are being cleaned out
  &__capitalize,
  &__capitalized {
    text-transform: capitalize !important;
  }

  &__lowercase {
    text-transform: lowercase;
  }

  &__transform-none {
    text-transform: none;
  }

  &__uppercase {
    text-transform: uppercase;
  }

  &__capitalized-first {
    text-transform: lowercase !important;

    &:first-letter {
      text-transform: capitalize !important;
    }
  }

  //text-sizes
  &__14p600 {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
  }
  &__14p400 {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }
  //text-sizes
  &__12p600 {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    padding-right: 1rem;
  }
  &__16p400 {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    padding-right: 1rem;
  }
  &__16p600 {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    padding-right: 1rem;
  }

  &__24p700 {
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
}

//COLORS: Add these classes to add color to your text. (except link, because hover states and such)
//There are modifiers available for the each color in the $color-map (see colors.scss)
//As well as other Grays used for text

//First, the Grays, Black and White
//example: link__black
.extra-info,
.headers,
.help-text,
.paragraph,
.subtext,
.subtext-trackers,
.text,
.title {
  &__black {
    color: $gray15;
  }

  &__disabled {
    color: $gray3;
  }
  
  &_light {
    font-weight: 300 !important;
  }

  &__light-text {
    color: $gray8;
  }

  &__white {
    color: $white;
  }

  &__secondary100 {
    color: $secondary100 !important;
  }

  &__danger100 {
    color: $severityModerate !important;
  }

  &__black100 {
    color: $black100 !important;
  }

}

//Margins top and bottom
.text {
  &__mt10 {
    margin-top: 10px !important;
  }

  &__mb10 {
    margin-bottom: 10px !important;
  }
}

//Second, the color-map (colors.scss)
//example: link__teal-dark
@each $name, $value in $color-map {
  .extra-info,
  .headers,
  .help-text,
  .paragraph,
  .subtext,
  .subtext-trackers,
  .text,
  .title {
    &__#{$name} {
      color: $value;
    }
  }
}

@include bp(small-down) {
  .extra-info {
  }

  .headers {
    font-size: 1.125rem;
  }

  .help-text {
    font-size: 0.938rem;
  }

  .paragraph {
    font-size: 1.063rem;
  }

  .subtext {
    font-size: 1.063rem;
  }

  .title {
    font-size: 1.188rem;
  }
}

.content-body {
  h1 {
    line-height: 1;
    margin-top: 0;
  }

  .headers,
  .link,
  .title {
    line-height: 1.425;
  }

  .paragraph {
    line-height: 1.5;
  }
}

.trackers-dots {
  height: 16px;
  width: 16px;
  background-color: $primary10;
  border-radius: 50%;
  display: inline-block;
  color: var(--Neutral-Black-70, #575a5d);
  text-align: center;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
