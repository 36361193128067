.tracker-buttons {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  background: $white;

  @include bp(large) {
    width: calc(4 * 17.125rem); 
  }

  @include bp(largest) {
    width: calc(5 * 17.025rem); 
  }

  @include bp(large-down) {
    width: calc(3 * 17.125rem); 
  }

  @include bp(medium-down) {
    width: 100%;
    flex-direction: column;
  }
  
}

.full-width {
  width: 100% !important;
}

.tracker-button {
  display: flex;
  flex: 1;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: $primary10;
  padding: 10px 12px;
  gap: 10px;
  min-height: 86px;

  &-disabled {
    cursor: default;
  }

  @include bp(medium-down) {
    width: 100%;
  }

  .tracker-button-icon {
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
  }

  .tracker-button-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;

    &-results {
      flex: 3;

      @include bp(medium-down) {
        flex: 1;
      }
    }
  }

  .tracker-button-label {
    font-size: 0.8rem;
    font-weight: 400;
    color: $black100;
  }

  .tracker-button-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: $black100;
      margin: 0;
      padding: 0;

      &.result {
        font-size: 1.25rem;
      }
    }
  }

  .poscorad-dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #8394b5;
  }

  .poscorad-history {
    flex: 4;
    margin: 0;

    @include bp(medium-down) {
      flex: 1;
    }

    .poscorad-scales {
      align-items: center;
    }

    .poscorad-scales-item {
      margin: 0;
    }

    .poscorad-labels {
      padding: 0.2rem;
    }

    .poscorad-item-scale {
      flex: 1;
      padding: 0;
      width: 100%;
      background: transparent;

      .poscorad-scales {
        margin-top: 0;
      }
    }

    .poscorad-scales-item-separator {
      width: 1px;
      height: 16px;
      background: $white;
    }
  }
}
