.footer {
  background-color: $cobalt-dark;
  padding: 2rem 1.5rem 1rem;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @include bp(large) {
    padding: 3rem 3rem 1rem;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    @include bp(large) {
      flex-direction: row;
      align-items: flex-start;
    }

    .footer-logo-container {
      width: 40%;
      margin-bottom: 30px;
      order: 1;

      @include bp(small-up) {
        width: 30%;
      }
      @include bp(large) {
        width: 220px;
      }

      .logo-image {
        width: 90%;
      }
    }

    .footer-awards-container {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 30px 0 40px;
      order: 4;
      
      @include bp(large) {
        padding: 0;
        width: 22%;
        flex-direction: row;
        order: 3;
      }

      .footer-award-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
  
        @include bp(small-up) {
          width: 30%;
        }
          
        img {
          max-height: 150px;
        }
      }
    }

    .footer-links-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      order: 3;

      @include bp(large) {
        width: 50%;
        flex-direction: row;
        order: 2;
      }

      .footer-menu-list {
        width: 45%;

        .paragraph {
          font-size: 16px;
        }

        li {
          line-height: 1.7;
        }

        @include bp(small-down) {
          li {
            margin: 0.25rem 0;

            &:first-of-type {
              @include bp(large) {
                margin-top: 1rem;
              }
            }
          }
        }
      }
    }

    
  }

  .footer-stores-container {
    display: flex;
    flex-direction: row;
    padding: 30px 0 40px;
    justify-content: center;

    @include bp(large) {
      justify-content: flex-end;
    }

    .store-button {
      width: auto;
      height: auto;
      padding: 10px 24px;
      background: #000;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      margin: 0 12px 0 0;
      transition: box-shadow ease-in 0.2s;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.3);
      }
      img {
        width: 140px;
      }
    }
  }

  .footer-copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: column;

    .paragraph {
      font-size: 14px;
      font-weight: 600;
    }

    .footer-social-links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px 0;

      .social-link {
        margin: 0 10px;
        img {
          width: 20px;
        }
      }
    }
  }
}
